import { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreateOneTaxMutationVariables,
  FindFirstTaxQuery,
  SortOrder,
  UpdateOneTaxMutationVariables,
  useCountriesQuery,
  useCreateOneStaffLogMutation,
  useCreateOneTaxMutation,
  useFindFirstTaxQuery,
  useUpdateOneTaxMutation,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { useStaffStore } from "../../store/staff";
import { updatedDiff } from "deep-object-diff";

interface Props {
  handleCreate?: (data: CreateOneTaxMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateOneTaxMutationVariables) => Promise<any>;
  id?: string;
  data?: FindFirstTaxQuery["findFirstTax"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [amount, setAmount] = useState(data?.value || 0);

  const [countries, setCountries] = useState<{ label: string; value: string } | null>(null); // its single not an array

  const { data: countriesData, loading: countriesLoading } = useCountriesQuery({
    fetchPolicy: "network-only",
    variables: {
      orderBy: { label: SortOrder.asc },
      where: {},
    },
  });
  const [createOneTax, { loading: createOneTaxLoading }] = useCreateOneTaxMutation();
  const [updateOneTax, { loading: updateOneTaxLoading }] = useUpdateOneTaxMutation();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();
  const navigation = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!amount) {
      return toast({
        status: "error",
        title: "Error",
        description: "Amount is required",
        duration: 1000,
      });
    }

    const findedCountry = countriesData?.countries.items.find((item) => item.label === countries?.label);

    if (!countriesData?.countries || !countries || !findedCountry) {
      return toast({
        status: "error",
        title: "Error",
        description: "Country is required",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await createOneTax({
          variables: {
            data: {
              country: {
                connect: {
                  value: findedCountry.value,
                },
              },
              value: parseFloat(amount).toFixed(2),
              shortcode: "",
            },
          },
        });
        toast({
          status: "success",
          title: "Success",
          description: "Tax created for " + findedCountry.label,
          duration: 1000,
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Tax for ${findedCountry.label} by ${staff?.name}`,
                },
                tableName: "Tax",
              },
            },
          }),
        ]);

        setTimeout(() => {
          navigation("/tax");
        }, 500);

        return;
      }

      if (actionType === "UPDATE") {
        if (findedCountry.tax && data?.id !== findedCountry.tax.id) {
          return toast({
            status: "error",
            title: "Error",
            description: "Tax already exists for " + findedCountry.label,
            duration: 1000,
          });
        }
        const returned = await updateOneTax({
          variables: {
            where: {
              id,
            },
            data: {
              country: {
                connect: {
                  value: findedCountry.value,
                },
              },
              value: {
                set: parseFloat(amount).toFixed(2),
              },
              shortcode: {
                set: "",
              },
            },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id,
                  },
                },
                json: {
                  action: "UPDATE",
                  message: `Updated Tax for ${findedCountry.label} by ${staff?.name}`,
                  diffs: {
                    old: data,
                    diff: updatedDiff(data as any, returned.data?.updateOneTax as any),
                  },
                },
                tableName: "Tax",
              },
            },
          }),
        ]);
        toast({
          status: "success",
          title: "Success",
          description: "Tax updated for " + findedCountry.label,
          duration: 1000,
        });

        setTimeout(() => {
          navigation("/tax");
        }, 500);

        return;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (actionType === "UPDATE" && data) {
      setCountries(
        data.country
          ? {
              label: data.country.label,
              value: data.country.value,
            }
          : null
      );
    }
  }, [actionType, data]);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Tax
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/tax" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Countries</FormLabel>
                <ReactSelect
                  isLoading={countriesLoading}
                  isDisabled={countriesLoading}
                  isClearable
                  {...(actionType === "UPDATE" &&
                    countriesData && {
                      ...{
                        value: countries,
                      },
                    })}
                  placeholder="Countries"
                  options={
                    countriesData?.countries
                      ? countriesData.countries.count > 0
                        ? countriesData.countries.items.map((tag) => ({
                            label: tag.label,
                            value: tag.id,
                          }))
                        : []
                      : []
                  }
                  onChange={(e: any) => {
                    setCountries(e);
                  }}
                />
                <FormHelperText>Countries where the tax applies</FormHelperText>
              </FormControl>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Tax Rate</FormLabel>
              <Input value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme="green"
            type="submit"
            mt={6}
            isDisabled={createOneTaxLoading || updateOneTaxLoading}
            isLoading={createOneTaxLoading || updateOneTaxLoading}
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
