import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Select, useToast } from "@chakra-ui/react";
import PageLayout from "../../components/ui/PageLayout";
import useConfiguratorStore from "../../store/configurator";
import { useStaffStore } from "../../store/staff";
import { useCreateOneStaffLogMutation } from "../../generated/graphql";
import { updatedDiff } from "deep-object-diff";

const Update = ({ parentId }: { parentId: string }) => {
  const { findDataById, updateData } = useConfiguratorStore();
  const uData = findDataById(parentId);

  const [dataState, setDataState] = useState<any>(uData ? uData.data : null);
  const [nameState, setNameState] = useState(dataState?.name ?? "");
  const [valueState, setValueState] = useState(dataState?.value ?? "");
  const [typeState, setTypeState] = useState(dataState?.type ?? "");

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newData = {
      _id: dataState["_id"],
      name: nameState,
      value: valueState,
      type: typeState,
    };

    const result = await updateData(newData);

    if (result.success) {
      toast({
        status: "success",
        title: "Successful",
        description: result.message,
        duration: 3000,
        onCloseComplete: () => {
          navigate("/configurator");
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Configurator Item ${nameState} by ${staff?.name}`,
                diffs: {
                  old: uData?.data,
                  diff: updatedDiff(uData?.data, newData),
                }
              },
              tableName: "Configurator",
            },
          },
        }),
      ]);
    } else {
      toast({
        status: "error",
        title: "Error",
        description: result.message,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if (!uData || !uData.status) {
      navigate("/configurator");
    } else {
      setDataState(uData.data);
    }
  }, [uData, navigate]);

  return (
    <PageLayout>
      <form onSubmit={handleSubmit}>
        <Input
          value={nameState}
          onChange={(e) => setNameState(e.target.value)}
          name="name"
          placeholder="name"
          mb={5}
        />
        <Input
          value={valueState}
          onChange={(e) => setValueState(e.target.value)}
          name="value"
          placeholder="value"
          mb={5}
        />
        <Select
          value={typeState}
          onChange={(e) => setTypeState(e.target.value)}
          name="type"
          placeholder="Type"
        >
          <option value="cost">cost</option>
          <option value="vat">vat</option>
          <option value="profit">profit</option>
          <option value="price">price</option>
          <option value="profil">profil</option>
        </Select>
        <Button type="submit" colorScheme="green" mt={6}>
          Save
        </Button>
      </form>
    </PageLayout>
  );
};

export default Update;
