import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { QueryMode, SortOrder, useCountriesQuery, useCreateOneStaffLogMutation } from "../../generated/graphql";
import { FcRefresh, FcEditImage } from "react-icons/fc";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Pagination from "../../components/pagination/Pagination";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const skip = (page - 1) * recordsPerPage;
  const [searchWord, setSearchWord] = useState("");
  const [searchValue] = useDebounce(searchWord, 1000);
  
  const { loading, error, data, refetch } = useCountriesQuery({
    variables: {
      orderBy: {
        tax: {
          value: SortOrder.asc,
        }
      },
      take: recordsPerPage,
      skip,
      where: {
        ...(searchValue && {
          label: { contains: searchValue, mode: QueryMode.insensitive },
        }),
      },
    },
    fetchPolicy: "network-only",
  });
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

    
  useEffect(() => {
    if (!logLoading && searchValue !== "") {
      createLog({
        variables: {
          data: {
            staff: {
              connect: {
                id: staff?.id as string,
              },
            },
            json: {
              message: `Countries filtered by search value: ${searchValue}`,
              action: "FILTER",
            },
            tableName: "Order",
          },
        },
      })
    }
  }, [searchValue, logLoading]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const totalPages = data ? Math.ceil(data?.countries.count / recordsPerPage) : 0;

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Countries
        </Box>

        <IconButton
          icon={<FcRefresh />}
          aria-label="Refetch Query"
          onClick={async () => {
            await refetch();
            if (!logLoading) {
              await createLog({
                variables: {
                  data: {
                    staff: {
                      connect: {
                        id: staff?.id,
                      },
                    },
                    json: {
                      action: "GET",
                      message: `Refetched Country List by ${staff?.name}`,
                    },
                    tableName: "Country",
                  },
                },
              });
            }
          }}
        />
      </Flex>
      <SimpleGrid mb={3} columns={3} gap={4}>
        <FormControl>
          <FormLabel>Search</FormLabel>
          <Input
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder="Search..."
          />
        </FormControl>
      </SimpleGrid>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Title</Th>
            <Th>Code</Th>
            <Th>Shipping Price</Th>
            <Th>Tax Amount</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.countries.items.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{c.label}</Td>
                <Td>{c.value}</Td>
                <Td>{c.shippingPrice}</Td>
                <Td >{c?.tax?.value || '-'}</Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/country/update/${c.id}`}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        totalPage={totalPages}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
      />
    </PageLayout>
  );
};

export default All;
