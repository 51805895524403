import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Text,
  chakra,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import validator from "validator";

import {
  CreateCustomerMutationVariables,
  CustomerQuery,
  CustomerRole,
  UpdateCustomerMutationVariables,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { $enum } from "ts-enum-util";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreateUser?: (data: CreateCustomerMutationVariables) => Promise<any>;
  handleUpdateUser?: (data: UpdateCustomerMutationVariables) => Promise<any>;
  id?: string;
  data?: CustomerQuery["customer"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreateUser, handleUpdateUser, id, data, actionType }) => {
  const toast = useToast();
  const [name, setName] = useState(data?.name || "");
  const [surname, setSurname] = useState(data?.surname || "");
  const [email, setEmail] = useState(data?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber || "");
  const [role, setRole] = useState<CustomerRole>(data?.role ?? CustomerRole.STANDARD);
  const [dealerDiscount, setDealerDiscount] = useState(data?.dealerDiscount ?? 0);
  const [phoneVerification, setPhoneVerification] = useState(data?.phoneActivated);
  const [emailVerification, setEmailVerification] = useState(data?.emailActivated);
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const [password, setPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name) {
      return toast({
        status: "error",
        description: "Name is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!surname) {
      return toast({
        status: "error",
        description: "Surname is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!phoneNumber) {
      return toast({
        status: "error",
        description: "Phone number is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!password && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Password is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (password.length < 6 && password) {
      return toast({
        status: "error",
        description: "Password must be greater then 6 characters",
        title: "Error",
        duration: 1000,
      });
    }

    if (!email) {
      return toast({
        status: "error",
        description: "Email is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!validator.isEmail(email)) {
      return toast({
        status: "error",
        description: "Enter a valid email address",
        title: "Error",
        duration: 1000,
      });
    }

    if (actionType === "CREATE") {
      await handleCreateUser!({
        data: {
          name,
          surname,
          email,
          phoneNumber,
          password,
          role,
          dealerDiscount: Number(dealerDiscount),
          phoneActivated: phoneVerification ? true : false,
          emailActivated: emailVerification ? true : false,
        },
      });
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                message: `Customer ${name} ${surname} created by ${staff?.name}`,
                action: "CREATE",
              },
              tableName: "Customer",
            },
          },
        }),
      ]);
    }
    if (actionType === "UPDATE") {
      await handleUpdateUser!({
        where: {
          id: id,
        },
        data: {
          name: {
            set: name,
          },
          surname: {
            set: surname,
          },
          email: {
            set: email,
          },
          phoneNumber: {
            set: phoneNumber,
          },
          password: password ? { set: password } : undefined,
          emailActivated: {
            set: emailVerification,
          },
          phoneActivated: {
            set: phoneVerification,
          },
          role: { set: role },
          dealerDiscount: { set: Number(dealerDiscount) },
        },
      });
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                message: `Customer ${name} ${surname} updated by ${staff?.name}`,
                action: "UPDATE",
              },
              tableName: "Customer",
            },
          },
        }),
      ]);
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Customer
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/customer" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <chakra.form
        onSubmit={handleSubmit}
        display="flex"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        flexWrap="wrap"
      >
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Name</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
            </GridItem>
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Surname</FormLabel>
              <Input value={surname} onChange={(e) => setSurname(e.target.value)} placeholder="Surname" />
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>E-Mail</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-Mail" type={"email"} />
            </GridItem>
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Phone Number</FormLabel>
              <PhoneInput
                country="tr"
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                enableSearch={true}
                inputStyle={{ width: "100%" }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <FormLabel>Password</FormLabel>
              <Input
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              {actionType === "UPDATE" && (
                <Text fontSize={"xs"} mt={2}>
                  Leave blank if you do not want to change the password
                </Text>
              )}
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Phone Confirmation</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={phoneVerification ? "green" : "gray"}
                    onClick={() => setPhoneVerification(true)}
                  >
                    Verified
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!phoneVerification ? "red" : "gray"}
                    onClick={() => setPhoneVerification(false)}
                  >
                    Unverified
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>E-Mail Confirmation</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={emailVerification ? "green" : "gray"}
                    onClick={() => setEmailVerification(true)}
                  >
                    Verified
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!emailVerification ? "red" : "gray"}
                    onClick={() => setEmailVerification(false)}
                  >
                    Unverified
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
            <GridItem>
              <FormLabel>Role</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                {$enum(CustomerRole).map((r) => (
                  <Button
                    width={"full"}
                    onClick={() => {
                      if (r === CustomerRole.STANDARD) {
                        setRole(r);
                        setDealerDiscount(0);
                      }
                      if (r === CustomerRole.DEALER) {
                        setRole(r);
                      }
                    }}
                    colorScheme={r === role ? "green" : "gray"}
                  >
                    {r === CustomerRole.DEALER ? "Reseller" : "Customer"}
                  </Button>
                ))}
              </SimpleGrid>
            </GridItem>
            {role === CustomerRole.DEALER && (
              <GridItem>
                <FormLabel>Reseller discount %</FormLabel>
                <Input
                  value={dealerDiscount}
                  type="number"
                  min={0}
                  max={100}
                  onChange={(e) => setDealerDiscount(e.target.value as any)}
                />
              </GridItem>
            )}
          </SimpleGrid>
        </Box>

        <Box width="100%">
          <Button colorScheme={"green"} type="submit" mt={6}>
            Save
          </Button>
        </Box>
      </chakra.form>
    </PageLayout>
  );
};

export default Form;
