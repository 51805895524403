import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateStaffMutationVariables,
  StaffRoleType,
  useCreateOneStaffLogMutation,
  useCreateStaffMutation,
} from "../../generated/graphql";
import { Navigate, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { useEffect } from "react";

const Create = () => {
  const [createUser] = useCreateStaffMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleCreateUser = async (data: CreateStaffMutationVariables) => {
    if (staff?.role !== StaffRoleType.SUPERADMIN) {
      return toast({
        status: "error",
        title: "Error",
        description: "You are not authorized to create staff",
        duration: 1000,
      });
    }
    try {
      await createUser({
        variables: {
          data: data.data,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "CREATE",
                message: `Created Staff ${data.data.name} by ${staff?.name}`,
              },
              tableName: "Staff",
            },
          },
        }),
      ]);

      return toast({
        status: "success",
        title: "Successful",
        description: "Authority created",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/staff");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  if (staff?.role !== StaffRoleType.SUPERADMIN) {
    return <Navigate to={"/staff"} replace />;
  }

  return <Form handleCreateUser={handleCreateUser} actionType="CREATE" />;
};

export default Create;
