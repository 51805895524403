import { useState } from "react";
import { useDebounce } from "use-debounce";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Icon,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  useToast,
  Badge,
} from "@chakra-ui/react";
import {
  SortOrder,
  useCustomersQuery,
  useDeleteCustomerMutation,
  QueryMode,
  CustomerRole,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import {
  FcRefresh,
  FcPlus,
  FcEditImage,
  FcCellPhone,
  FcFeedback,
  FcCancel,
  FcApproval,
  FcAbout,
} from "react-icons/fc";
import dayjs from "dayjs";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import { useStaffStore } from "../../store/staff";

const All = () => {
  const [deleteUser, { loading: deleteLoading }] = useDeleteCustomerMutation();
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const skip = (page - 1) * recordsPerPage;
  const [searchWord, setSearchWord] = useState("");
  const [searchValue] = useDebounce(searchWord, 1000);
  const { loading, error, data, refetch } = useCustomersQuery({
    variables: {
      orderBy: {
        name: SortOrder.asc,
      },
      take: recordsPerPage,
      skip,
      where: {
        ...(searchValue && {
          OR: [
            { name: { contains: searchValue, mode: QueryMode.insensitive } },
            { surname: { contains: searchValue, mode: QueryMode.insensitive } },
            { email: { contains: searchValue, mode: QueryMode.insensitive } },
          ],
        }),
      },
    },
    fetchPolicy: "network-only",
  });
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const toast = useToast();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  const totalPages = data ? Math.ceil(data?.customers.count / recordsPerPage) : 0;

  const handleDelete = async (id: string) => {
    try {
      const customer = data?.customers.items.find((item) => item.id === id);
      await deleteUser({
        variables: {
          where: {
            id,
          },
        },
      });
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                message: `Customer ${customer?.name} ${customer?.surname} deleted by ${staff?.name}`,
                action: "DELETE",
              },
              tableName: "Customer",
            },
          },
        }),
      
      ])
      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Customer is deleted",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Customer List
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to="/customer/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <SimpleGrid mb={3} columns={4} gap={4}>
        <FormControl>
          <FormLabel>Search</FormLabel>
          <Input
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder="Search..."
          />
        </FormControl>
      </SimpleGrid>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Created Date</Th>
            <Th>Name</Th>
            <Th>E-Mail</Th>
            <Th>Role</Th>
            <Th textAlign={"center"}>
              <Icon as={FcCellPhone} fontSize={"16px"} mx="auto" />
            </Th>
            <Th textAlign={"center"}>
              <Icon as={FcFeedback} fontSize={"16px"} mx="auto" />
            </Th>

            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.customers.items.map((user, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{dayjs(user.createdAt).format("DD MMM YYYY")}</Td>
                <Td>{`${user.name} ${user.surname}`}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Badge>{`${
                    user.role === CustomerRole.STANDARD
                      ? user.role
                      : `${user.role} (${user.dealerDiscount}%)`
                  }`}</Badge>
                </Td>
                <Td textAlign={"center"}>
                  {user.phoneActivated ? (
                    <IconButton aria-label="Change Status" icon={<FcApproval />} />
                  ) : (
                    <IconButton aria-label="Change Status" icon={<FcCancel />} />
                  )}
                </Td>
                <Td textAlign={"center"}>
                  {user.emailActivated ? (
                    <IconButton aria-label="Change Status" icon={<FcApproval />} />
                  ) : (
                    <IconButton aria-label="Change Status" icon={<FcCancel />} />
                  )}
                </Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcAbout />}
                        as={Link}
                        to={`/customer/detail/${user.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/customer/update/${user.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={user.id}
                        loading={deleteLoading}
                        title={`${user.name} ${user.surname}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        totalPage={totalPages}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
      />
    </PageLayout>
  );
};

export default All;
