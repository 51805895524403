import {
  UpdateOneSliderMutationVariables,
  useSliderQuery,
  useUpdateOneSliderMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateMutation] = useUpdateOneSliderMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useSliderQuery({
    variables: {
      where: {
        id: id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to="/slider" replace />;
  }

  const handleUpdate = async (data: UpdateOneSliderMutationVariables) => {
    try {
      await updateMutation({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Successful",
        description: "Slider updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/slider");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return (
    <Form actionType="UPDATE" data={data?.slider} id={id} handleUpdate={handleUpdate} />
  );
};

export default Update;
