import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  SimpleGrid,
} from "@chakra-ui/react";
import { SortOrder, useCreateOneStaffLogMutation, useProductPriceConstantsQuery } from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const { data, loading, error, refetch } = useProductPriceConstantsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  const navigate = useNavigate();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();
  const toast = useToast();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  console.log("data", data);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Product Constants
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton icon={<FcRefresh />} aria-label="Refetch Query" onClick={() => refetch()} />
          <IconButton
            to={`/constant/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={async () => {
              await refetch();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "GET",
                        message: `Refetched Product Constants by ${staff?.name}`,
                      },
                      tableName: "ProductConstants",
                    },
                  },
                }),
              ]);
            }}
          />
        </Flex>
      </Flex>
      <Box my={4}>
        <SimpleGrid columns={2} gap={4}>
          <Alert status="info" size={"sm"}>
            <AlertIcon />
            <AlertTitle>purityConstant</AlertTitle>
            <AlertDescription>
              this value is in calculation for rings, that holds purity constant value
            </AlertDescription>
          </Alert>
          <Alert status="info" size={"sm"}>
            <AlertIcon />
            <AlertTitle>gravurePrice</AlertTitle>
            <AlertDescription>
              this value is in calculation for rings text, that holds price for rings text
            </AlertDescription>
          </Alert>
        </SimpleGrid>
      </Box>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th textAlign="center">Name</Th>
            <Th textAlign="center">Value</Th>
            <Th textAlign="center">Created At</Th>
            <Th textAlign="center">Updated At</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.productPriceConstants.map((constant, index) => (
            <Tr key={constant.id}>
              <Td>{index + 1}</Td>
              <Td textAlign="center">{constant.key}</Td>
              <Td textAlign="center">{constant.value}</Td>
              <Td textAlign="center">{new Date(constant.createdAt).toLocaleString()}</Td>
              <Td textAlign="center">{new Date(constant.updatedAt).toLocaleString()}</Td>
              <Td textAlign="right">
                <Flex justifyContent="flex-end" alignItems="center">
                  <IconButton
                    to={`/constant/update/${constant.key}`}
                    as={Link}
                    icon={<FcEditImage />}
                    aria-label="Update Item"
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
