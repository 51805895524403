import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Heading,
  FormHelperText,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  ModalHeader,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  ModalCloseButton,
  Text,
  Badge,
  Checkbox,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreateOneCreatableMutationVariables,
  RingSizeCreateInput,
  RingSizesQuery,
  RingSizeStandartQuery,
  SortOrder,
  UpdateOneCreatableMutationVariables,
  useCreateOneRingSizeMutation,
  useCreateOneRingSizeStandartMutation,
  useCreateOneStaffLogMutation,
  useDeleteOneRingSizeMutation,
  useGetSizeCategoriesQuery,
  useRingSizesQuery,
  useUpdateOneRingSizeMutation,
  useUpdateOneRingSizeStandartMutation,
} from "../../generated/graphql";
import { FcEditImage, FcList, FcPlus, FcRefresh } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreate?: (data: CreateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  handleUpdate?: (data: UpdateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  id?: string;
  data?: RingSizeStandartQuery["ringSizeStandart"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ id, data, actionType }) => {
  const toast = useToast();
  const [value, setValue] = useState(() => {
    return data?.name || "";
  });
  const [categoryName, setCategoryName] = useState<string>(() => {
    return data?.categoryName || "";
  });
  const [ringSize, setRingSize] = useState<Partial<RingSizesQuery["ringSizes"][number]> | null>(null);
  const {
    data: ringSizes,
    loading,
    refetch,
  } = useRingSizesQuery({
    skip: !id,
    variables: {
      where: {
        ringSizeStandart: {
          is: {
            id: {
              equals: id!,
            },
          },
        },
      },
      orderBy: {
        createdAt: SortOrder.asc,
      },
    },
  });
  const [createRingSize, { loading: createRingSizeLoading }] = useCreateOneRingSizeMutation();
  const [createRingSizeStandart, { loading: createRingSizeStandartLoading }] = useCreateOneRingSizeStandartMutation();
  const [updateOneRingSize, { loading: updateOneRingSizeLoading }] = useUpdateOneRingSizeMutation();
  const [deleteOneRingSize, { loading: deleteOneRingSizeLoading }] = useDeleteOneRingSizeMutation();
  const [updateOneRingSizeStandart, { loading: updateOneRingSizeStandartLoading }] =
    useUpdateOneRingSizeStandartMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();
  const { data: sizeCategories, loading: loadingSizeCategories } = useGetSizeCategoriesQuery();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (actionType === "CREATE") {
        if (createRingSizeStandartLoading) {
          return;
        }
        if (value.trim().length === 0) {
          return toast({
            status: "error",
            title: "Error",
            description: "Name is required",
            duration: 1000,
          });
        }
        if (categoryName.trim().length === 0) {
          return toast({
            status: "error",
            title: "Error",
            description: "Category Name is required",
            duration: 1000,
          });
        }
        const data = await createRingSizeStandart({
          variables: {
            data: {
              name: value,
              categoryName: categoryName.toUpperCase(),
            },
          },
        });
        toast({
          status: "success",
          title: "Successful",
          description: "Size standart created",
          duration: 1000,
          onCloseComplete: () => {
            return refetch();
          },
        });
        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Size Standart id: ${data.data?.createOneRingSizeStandart?.id} by ${staff?.name}`,
                },
                tableName: "RingSizeStandart",
              },
            },
          }),
        ]);
        navigation(`/ring-size-standarts/update/${data.data?.createOneRingSizeStandart?.id!}`);
      }
      if (actionType === "UPDATE") {
        try {
          if (updateOneRingSizeStandartLoading) {
            return;
          }
          if (value.trim().length === 0) {
            return toast({
              status: "error",
              title: "Error",
              description: "Name is required",
              duration: 1000,
            });
          }
          if (categoryName.trim().length === 0) {
            return toast({
              status: "error",
              title: "Error",
              description: "Category Name is required",
              duration: 1000,
            });
          }

          await updateOneRingSizeStandart({
            variables: {
              data: {
                name: {
                  set: value,
                },
                categoryName: {
                  set: categoryName.toUpperCase(),
                },
              },
              where: {
                id,
              },
            },
          });

          await Promise.allSettled([
            createLog({
              variables: {
                data: {
                  staff: {
                    connect: {
                      id: staff?.id as string,
                    },
                  },
                  json: {
                    action: "UPDATE",
                    message: `Updated Size Standart id: ${id} by ${staff?.name}`,
                  },
                  tableName: "RingSizeStandart",
                },
              },
            }),
          ]);
          toast({
            status: "success",
            title: "Success",
            description: "size standart updated",
            duration: 1000,
            onCloseComplete: () => {
              return refetch();
            },
          });
        } catch (error: any) {
          toast({
            status: "error",
            title: "Error",
            description: error?.message || "Something went wrong",
            duration: 1000,
          });
        }
      }
    } catch (error: any) {
      toast({
        status: "error",
        title: "Error",
        description: error?.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  const handleCreateRingSize = async () => {
    if (createRingSizeLoading) {
      return;
    }
    if (!ringSize) {
      return;
    }
    const { circumferenceMM, diameterMM } = ringSize;
    const replacedRingSize = {
      circumferenceMM: circumferenceMM?.replace(",", "."),
      diameterMM: diameterMM?.replace(",", "."),
      value: ringSize.value,
    };
    const isValid = Object.entries(replacedRingSize).every(([key, value]) => {
      if (key === "value") {
        return value?.trim().length !== 0;
      }
      const isNumber = !isNaN(Number(value));
      return value?.trim().length !== 0 && isNumber;
    });
    if (!isValid) {
      return toast({
        status: "error",
        title: "Error",
        description: "All fields are required and must be number(value can be string)",
        duration: 1000,
      });
    }

    try {
      if (ringSize.isDefault) {
        const findedDefault = ringSizes?.ringSizes.find((item) => item.isDefault);
        if (findedDefault) {
          await updateOneRingSize({
            variables: {
              data: {
                isDefault: {
                  set: false,
                },
              },
              where: {
                id: findedDefault.id,
              },
            },
          });
        }
      }
      await createRingSize({
        variables: {
          data: {
            circumferenceMM: replacedRingSize.circumferenceMM,
            diameterMM: replacedRingSize.diameterMM,
            value: replacedRingSize.value!,
            isDefault: ringSize.isDefault,
            ringSizeStandart: {
              connect: {
                id: id!,
              },
            },
          },
        },
      });
      toast({
        status: "success",
        title: "Success",
        description: "size added",
        duration: 1000,
        onCloseComplete: () => {
          return refetch();
        },
      });
      onClose();
      setRingSize(null);
    } catch (error: any) {
      toast({
        status: "error",
        title: "Error",
        description: error?.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  const handleUpdateRingSize = async () => {
    if (updateOneRingSizeLoading) {
      return;
    }
    if (!ringSize) {
      return;
    }
    const { circumferenceMM, diameterMM, value: grabbedValue } = ringSize;
    const replacedRingSize = {
      circumferenceMM: circumferenceMM?.replace(",", "."),
      diameterMM: diameterMM?.replace(",", "."),
      value: grabbedValue?.toString(),
    };
    const isValid = Object.entries(replacedRingSize).every(([key, value]) => {
      if (key === "value") {
        return value?.trim().length !== 0;
      }
      const isNumber = !isNaN(Number(value));
      return value?.trim().length !== 0 && isNumber;
    });
    if (!isValid) {
      return toast({
        status: "error",
        title: "Error",
        description: "All fields are required and must be number(value can be string)",
        duration: 1000,
      });
    }

    try {
      if (ringSize.isDefault) {
        const findedDefault = ringSizes?.ringSizes.find((item) => item.isDefault);
        if (findedDefault) {
          await updateOneRingSize({
            variables: {
              data: {
                isDefault: {
                  set: false,
                },
              },
              where: {
                id: findedDefault.id,
              },
            },
          });
        }
      }
      await updateOneRingSize({
        variables: {
          data: {
            circumferenceMM: {
              set: replacedRingSize.circumferenceMM,
            },
            diameterMM: {
              set: replacedRingSize.diameterMM,
            },
            value: {
              set: replacedRingSize.value,
            },
            isDefault: {
              set: ringSize.isDefault,
            },
          },
          where: {
            id: ringSize.id,
          },
        },
      });

      toast({
        status: "success",
        title: "Success",
        description: "size updated",
        duration: 1000,
        onCloseComplete: () => {
          return refetch();
        },
      });
      onClose();
      setRingSize(null);
    } catch (error: any) {
      toast({
        status: "error",
        title: "Error",
        description: error?.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  const handleRingSizeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (ringSize?.id) {
      return handleUpdateRingSize();
    }
    handleCreateRingSize();
  };

  const handleDeleteRingSize = async (id: string) => {
    if (deleteOneRingSizeLoading) {
      return;
    }
    try {
      await deleteOneRingSize({
        variables: {
          where: {
            id,
          },
        },
      });
      toast({
        status: "success",
        title: "Success",
        description: "size deleted",
        duration: 1000,
        onCloseComplete: () => {
          return refetch();
        },
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Error",
        description: "Something went wrong",
        duration: 1000,
      });
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Size Standart
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/ring-size-standarts" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={2} gap={10}>
          <Box mt={4} display="flex" flexDirection="column" gap="4">
            <Box>
              <FormControl>
                <FormLabel>Category Name</FormLabel>
                <Input
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="Category Name"
                  type="text"
                />
                <FormHelperText mt={1}>(For example RING, BAG)</FormHelperText>
                {!loadingSizeCategories && (
                  <Box display={"flex"} flexWrap="wrap" gap="3" mt={3}>
                    {Array.from(
                      new Set(sizeCategories?.ringSizeStandarts.map((standart) => standart.categoryName))
                    ).map((categoryName, i) => (
                      <Box
                        key={i}
                        as="button"
                        cursor={"pointer"}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setCategoryName(categoryName);
                        }}
                      >
                        <Badge key={i} fontSize={14} px={2} colorScheme="green">
                          {categoryName}
                        </Badge>
                      </Box>
                    ))}
                  </Box>
                )}
              </FormControl>
            </Box>

            <Box>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Standart Name (For example EU, US)"
                  type="text"
                />
                <FormHelperText mt={1}>(For example EU, US)</FormHelperText>
                <FormHelperText>Name should be unique!</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={createRingSizeLoading || updateOneRingSizeStandartLoading}
                isLoading={createRingSizeLoading || updateOneRingSizeStandartLoading}
              >
                Save
              </Button>
            </Box>
          </Box>

          <Box>
            <Flex
              mb={6}
              justifyContent={"space-between"}
              alignItems="center"
              bgColor={"gray.100"}
              padding={2}
              rounded="md"
            >
              <Box fontSize={"18px"} fontWeight="bold">
                Sizes
              </Box>
              {/**<Flex experimental_spaceX={4}>
                <IconButton to="/creatable" as={Link} icon={<FcList />} aria-label="All Items" />
              </Flex> */}
              <Flex gap={1}>
                <IconButton icon={<FcPlus />} aria-label="Add Item" onClick={onOpen} isDisabled={!id} />
                <IconButton
                  icon={<FcRefresh />}
                  aria-label="Refetch Query"
                  onClick={() => refetch()}
                  isDisabled={!id}
                />
              </Flex>
            </Flex>
            {actionType === "UPDATE" && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Diamater (MM)</Th>
                    <Th>Circumference (MM)</Th>
                    <Th>Name</Th>
                    <Th>Is Default</Th>
                    <Th textAlign="center">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ringSizes &&
                    ringSizes.ringSizes.map((item, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{item.diameterMM}</Td>
                        <Td>{item.circumferenceMM}</Td>
                        <Td>{item.value}</Td>
                        <Td>
                          <Badge colorScheme={item.isDefault ? "green" : "red"}>
                            {item.isDefault ? "Default" : "Not Default"}
                          </Badge>
                        </Td>
                        <Td>
                          <Flex justifyContent={"center"}>
                            <Box mx={2}>
                              <IconButton
                                aria-label="Edit"
                                icon={<FcEditImage />}
                                onClick={() => {
                                  setRingSize(item);
                                  onOpen();
                                }}
                              />
                            </Box>

                            <Box mx={2}>
                              <DeleteItem
                                id={item.id}
                                loading={false}
                                title={`${item.value}`}
                                handleDelete={handleDeleteRingSize}
                              />
                            </Box>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </SimpleGrid>
      </form>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setRingSize(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size={"md"}>{actionType === "CREATE" ? "Create" : "Update"} Size</Heading>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Stack as="form" gap={5} pb={8}>
              <FormControl isRequired>
                <FormLabel>Diameter (MM)</FormLabel>
                <Input
                  placeholder="Diameter (MM)"
                  type="text"
                  value={ringSize?.diameterMM || ""}
                  onChange={(e) => {
                    setRingSize({
                      ...ringSize,
                      diameterMM: e.target.value,
                    });
                  }}
                />
                <FormHelperText>Diameter (MM) can be number. For example: 52.5</FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Circumference (MM)</FormLabel>

                <Input
                  placeholder="Circumference (MM)"
                  type="text"
                  value={ringSize?.circumferenceMM || ""}
                  onChange={(e) => {
                    setRingSize({
                      ...ringSize,
                      circumferenceMM: e.target.value,
                    });
                  }}
                />
                <FormHelperText>Circumference (MM) can be number. For example: 52.5</FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Value</FormLabel>
                <Input
                  placeholder="Value"
                  type="text"
                  value={ringSize?.value || ""}
                  onChange={(e) => {
                    setRingSize({
                      ...ringSize,
                      value: e.target.value,
                    });
                  }}
                />
                <FormHelperText>Value can be string. For example: 6-7</FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Is Default?</FormLabel>
                <Checkbox
                  isChecked={ringSize?.isDefault}
                  onChange={(e) => {
                    setRingSize({
                      ...ringSize,
                      isDefault: e.target.checked,
                    });
                  }}
                />
              </FormControl>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={createRingSizeLoading}
                isLoading={createRingSizeLoading}
                onClick={handleRingSizeSubmit}
              >
                Save
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default Form;
