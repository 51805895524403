import { useState, useEffect, FC } from "react";
import All from "./All";
import Loading from "../../components/loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { StaffRoleType } from "../../generated/graphql";
import Create from "./Create";
import Update from "./Update";

const Carat: FC = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();
  const { staff } = useStaffStore();
  const navigate = useNavigate();

  useEffect(() => {
    const isAdminOrSuperAdmin = staff?.role === StaffRoleType.ADMIN || staff?.role === StaffRoleType.SUPERADMIN || staff?.role === StaffRoleType.PRODUCT;
    if (!isAdminOrSuperAdmin) {
      navigate("/");
    }
  }, [staff]);

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "create":
        setComponent(<Create />);
        break;
      case "update":
        setComponent(<Update id={pathname[3]} />);
        break;

      default:
        setComponent(<All />);
    }
  }, [location]);

  return component;
};

export default Carat;
