import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import {
  LanguageOptions,
  SortOrder,
  useCreateOneStaffLogMutation,
  useDeleteOneTaxMutation,
  useTaxesQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const [deleteMutation, { loading: deleteLoading }] = useDeleteOneTaxMutation();
  const toast = useToast();
  const { loading, error, data, refetch } = useTaxesQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id,
                },
              },
              json: {
                action: "DELETE",
                message: `Deleted Tax ${data?.taxes.find((c) => c.id === id)?.country.label} by ${staff?.name}`,
              },
              tableName: "Tax",
            },
          },
        }),
      ]);
      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Taxs
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetch();
              if (!logLoading) {
                await createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id,
                        },
                      },
                      json: {
                        action: "GET",
                        message: `Refetched Tax List by ${staff?.name}`,
                      },
                      tableName: "Tax",
                    },
                  },
                });
              }
            }}
          />
          <IconButton to="/tax/create" as={Link} icon={<FcPlus />} aria-label="Add Item" />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Country</Th>
            <Th>Amount</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.taxes.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{c.country.label}</Td>
                <Td>{c.value}</Td>
                <Td textAlign="right">
                  <Flex justifyContent={"flex-end"}>
                    <Box mx={2}>
                      <IconButton aria-label="Edit" icon={<FcEditImage />} as={Link} to={`/tax/update/${c.id}`} />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`Confirm deletion`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
