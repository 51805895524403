import {
  SimpleGrid,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  GridItem,
  FormLabel,
  Input,
  TabList,
  Box,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import getSlug from "speakingurl";
import {
  LanguageOptions,
  useCreateOneBlogCategoryMutation,
  useCreateOneStaffLogMutation,
  useUpdateDescriptionMutation,
  useUpdateOneBlogCategoryMutation,
} from "../../generated/graphql";
import { useStaffStore } from "../../store/staff";
import DescriptionForms from "../../components/description/DescriptionForm";

const editorSettings = {
  skin: "snow",
  icons: "thin",
  placeholder: "Description...",
  height: 300,
  menubar: false,
  min_height: 200,
  max_height: 500,

  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code wordcount",
  ],
  toolbar:
    "undo redo | formatselect | " +
    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
    "removeformat | code",
};

type Props = {
  actionType: "CREATE" | "UPDATE";
  descriptionsFromUpdate?: Array<any>;
  updateId?: string;
  updateStatus?: boolean;
};

const Form: FC<Props> = ({ actionType, descriptionsFromUpdate, updateId, updateStatus }) => {
  const { staff } = useStaffStore();
  const [status, setStatus] = useState<boolean>(updateStatus || false);
  const [descriptions, setDescriptions] = useState<Array<any>>(
    descriptionsFromUpdate || [
      {
        title: "",
        description: "",
        language: LanguageOptions.EN,
        slug: "",
      },
      {
        title: "",
        description: "",
        language: LanguageOptions.DE,
        slug: "",
      },
      {
        title: "",
        description: "",
        language: LanguageOptions.ES,
        slug: "",
      },
      {
        title: "",
        description: "",
        language: LanguageOptions.FR,
        slug: "",
      },
      {
        title: "",
        description: "",
        language: LanguageOptions.IT,
        slug: "",
      },
      {
        title: "",
        description: "",
        language: LanguageOptions.TR,
        slug: "",
      },
    ]
  );
  const [createOneBlogCategory, { loading: createLoading }] = useCreateOneBlogCategoryMutation();
  const [updateOneBlogCategory, { loading: updateLoading }] = useUpdateOneBlogCategoryMutation();
  const navigate = useNavigate();
  const [updateDescriptionMutation, { loading: updateDescriptionLoading }] = useUpdateDescriptionMutation();
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const toast = useToast();

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const langs = ["EN", "DE", "ES", "FR", "IT", "TR"];
      const isAllTitleFilled = langs.every((lang) => {
        return descriptions.find((desc) => desc?.language === lang)?.title;
      });
      if (!staff?.name || !isAllTitleFilled) {
        return toast({
          title: "Error",
          description: "Please fill all fields & select category & upload photo & fill all title fields",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      if (actionType === "CREATE") {
        await createOneBlogCategory({
          variables: {
            data: {
              status,
              author: staff?.name || "",
              descriptions: {
                create: descriptions.map((desc) => ({ ...desc, slug: desc.title })),
              },
            },
          },
        });
        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Blog Category ${
                    descriptions.find((d) => d.language === LanguageOptions.EN)?.title
                  } by ${staff?.name}`,
                },
                tableName: "BlogCategory",
              },
            },
          }),
        ]);
      }

      if (actionType === "UPDATE") {
        for await (const iterator of descriptions) {
          updateDescriptionMutation({
            variables: {
              where: { id: iterator.id },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                slug: { set: getSlug(iterator.title || "") },
              },
            },
          });
        }

        await updateOneBlogCategory({
          variables: {
            where: {
              id: updateId!,
            },
            data: {
              author: {
                set: staff?.name || "",
              },
              status: {
                set: status,
              },
            },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "UPDATE",
                  message: `Updated Blog Category ${
                    descriptions.find((d) => d.language === LanguageOptions.EN)?.title
                  } by ${staff?.name}`,
                },
                tableName: "BlogCategory",
              },
            },
          }),
        ]);
      }
      toast({
        title: "Success",
        description: `Proccess successfully completed.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/blog-categories");
    } catch (error) {
      toast({
        title: "Error",
        description: `Something went wrong.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      navigate("/blog-categories");
    }
  };

  return (
    <Box>
      <form onSubmit={onSubmitHandler}>
        <SimpleGrid columns={12} gap={12}>
          <GridItem
            colSpan={{
              base: 12,
              lg: 6,
            }}
          >
            <DescriptionForms descriptions={descriptions} setDescriptions={setDescriptions} />
          </GridItem>
          <GridItem
            colSpan={{
              base: 12,
              lg: 6,
            }}
          >
            <Box>
              <Box>
                <FormLabel>Status</FormLabel>
              </Box>
              <Flex gap={2} mt={3}>
                <Button flex={1} colorScheme={status ? "green" : "gray"} onClick={() => setStatus(true)}>
                  Active
                </Button>
                <Button flex={1} colorScheme={!status ? "red" : "gray"} onClick={() => setStatus(false)}>
                  Inactive
                </Button>
              </Flex>
            </Box>
          </GridItem>
        </SimpleGrid>
        <Button type="submit" disabled={createLoading || updateLoading || updateDescriptionLoading} colorScheme="green">
          Save
        </Button>
      </form>
    </Box>
  );
};

export default Form;
