import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  Image,
  useToast,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import {
  LanguageOptions,
  SortOrder,
  useCategoriesQuery,
  useCreateOneStaffLogMutation,
  useDeleteCategoryMutation,
  useDeleteOneStyleMutation,
  useStylesQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcInfo } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { IoMdReorder } from "react-icons/io";
import Description from "../../components/description/Description";
import CloudinaryImage from "../../components/CloudinaryImage";
import { useStaffStore } from "../../store/staff";

interface Props {
  parentId?: string;
}

const All: React.VFC<Props> = ({ parentId }) => {
  const [deleteStyle, { loading: deleteLoading }] = useDeleteOneStyleMutation();
  const toast = useToast();
  const { loading, error, data, refetch } = useStylesQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteStyle({
        variables: {
          where: {
            id,
          },
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "DELETE",
                message: `Deleted Style id: ${id} by ${staff?.name}`,
              },
              tableName: "Style",
            },
          },
        }),
      ]);

      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };
  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Ürün Stilleri
        </Box>
        <Flex gap={4}>
          <IconButton
            to={`/style/order`}
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton icon={<FcRefresh />} aria-label="Refetch Query" onClick={() => refetch()} />
          <IconButton
            to={`/style/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={async () => {
              await refetch();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "CREATE",
                        message: `Created Style by ${staff?.name}`,
                      },
                      tableName: "Style",
                    },
                  },
                }),
              ]);
            }}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Photo</Th>
            <Th>Title</Th>
            <Th textAlign="center">Total Product</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.styles.map((style, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td w="10%">
                  {style.photos[0] ? (
                    <CloudinaryImage width={80} publicId={style.photos[0]} />
                  ) : (
                    <Image maxW="80px" src={"/noimg.png"} alt="Product" />
                  )}
                </Td>
                <Td>
                  <Description field="style" id={style.id} descriptions={style.descriptions} refetch={refetch} />
                </Td>
                <Td textAlign="center" align="center">
                  <Badge>{style._count?.products}</Badge>
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton aria-label="Edit" icon={<FcEditImage />} as={Link} to={`/style/update/${style.id}`} />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={style.id}
                        loading={deleteLoading}
                        title={`${style?.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
