import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  Image,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import {
  LanguageOptions,
  SortOrder,
  useCategoriesQuery,
  useCreateOneStaffLogMutation,
  useDeleteCategoryMutation,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcInfo } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { IoMdReorder } from "react-icons/io";
import Description from "../../components/description/Description";
import CloudinaryImage from "../../components/CloudinaryImage";
import { useStaffStore } from "../../store/staff";
import { descriptionToText } from "../../utils/descriptionToText";

interface Props {
  parentId?: string;
}

const All: React.VFC<Props> = ({ parentId }) => {
  const [deleteCategory, { loading: deleteLoading }] = useDeleteCategoryMutation();
  const toast = useToast();
  const { loading, error, data, refetch } = useCategoriesQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
      where: {
        ...(parentId
          ? { parentCategoryId: { equals: parentId } }
          : { parentCategoryId: { equals: null } }),
      },
    },
    fetchPolicy: "network-only",
  });

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      const category = data?.categories.find((c) => c.id === id);
      await deleteCategory({
        variables: {
          where: {
            id,
          },
        },
      });

      // @ts-ignore
      const message =`Category ${descriptionToText(category?.descriptions as any, "EN")?.title} deleted by ${staff?.name} ${staff?.surname || ''}`;

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                message,
                action: "DELETE",
              },
              tableName: "Category",
            },
          },
        }),
      ])

      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Categories
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/category/order/${parentId ? parentId : ""}`}
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetch();
              if (!logLoading) {
                await createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        // @ts-ignore
                        message: `Categories refetched by ${staff?.name} ${staff?.surname || ''}`,
                        action: "GET",
                      },
                      tableName: "Category",
                    },
                  },
                });
              }
            }}
          />
          <IconButton
            to={`/category/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Photo</Th>
            <Th>Title</Th>
            <Th textAlign="center">Sub Category</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.categories.map((category, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td w="10%">
                  {category.photos[0] ? (
                    <CloudinaryImage width={80} publicId={category.photos[0]} />
                  ) : (
                    <Image maxW="80px" src={"/noimg.png"} alt="Product" />
                  )}
                </Td>
                <Td>
                  <Description
                    field="category"
                    id={category.id}
                    descriptions={category.descriptions}
                    refetch={refetch}
                  />
                </Td>
                <Td textAlign="center" align="center">
                  <Tooltip
                    hasArrow
                    label={category.subCategories.map((s) => (
                      <li>{s.descriptions[0].title}</li>
                    ))}
                    bg="red.600"
                  >
                    <IconButton aria-label="sub categories" icon={<FcInfo />} />
                  </Tooltip>
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/category/update/${category.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={category.id}
                        loading={deleteLoading}
                        title={`${
                          category.descriptions.find(
                            (d) => d.language === LanguageOptions.EN
                          )?.title
                        }`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
