import {
  Flex,
  Box,
  IconButton,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  FormHelperText,
  useDisclosure,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Text,
  Stack,
  ModalCloseButton,
  Select,
  Icon,
  Badge,
  Code,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { FcCursor, FcRefresh } from "react-icons/fc";
import { useDebounce } from "use-debounce";
import PageLayout from "../../components/ui/PageLayout";
import {
  SortOrder,
  StaffRoleType,
  useDeleteManyStaffLogMutation,
  useDeleteOneStaffLogMutation,
  useFindManyStaffQuery,
  useGetAllLogsLazyQuery,
  useGetAllLogsQuery,
} from "../../generated/graphql";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import { FcFilledFilter } from "react-icons/fc";
import { CiViewList } from "react-icons/ci";
import Pagination from "../../components/pagination/Pagination";
import ReactSelect from "react-select";
import { FiTrash2 } from "react-icons/fi";
import { useStaffStore } from "../../store/staff";
import { MdOutlineCancel } from "react-icons/md";
import { LuFileJson } from "react-icons/lu";
import DeleteItem from "../../components/delete/DeleteItem";
import DatePicker from "react-datepicker";
import * as xlsx from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";

const RECORDS_PER_PAGE = 20;

const defaultFilters = {
  staffId: "",
  searchWord: "",
  createdAt: SortOrder.desc,
  staff: {
    label: "",
    value: "",
  },
  action: "",
};

const ALLOWED_ROLES = [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN];

const exportData = (data: any, fileName: any, type: any) => {
  if (!data) return;
  const mappedData = data.map((item: any) => ({
    staff: item.staff.name,
    staffRole: item.staff.role,
    actionType: item.json.action,
    message: item.json.message,
    createdAt: new Date(item.createdAt).toLocaleString(),
    json: JSON.stringify(item.json, null, 2),
  }));

  const ws = xlsx.utils.json_to_sheet(mappedData);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFileXLSX(wb, "log.xlsx");
};

type Props = {};

const All: FC<Props> = () => {
  const [recordsPerPage, setRecordsPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [searchWord, setSearchWord] = useState<string>("");
  const [debouncedSearchWord] = useDebounce(searchWord, 1000);
  const [filters, setFilters] = useState({
    ...defaultFilters,
    searchWord: debouncedSearchWord || undefined,
  });
  const { data, loading, error, refetch } = useGetAllLogsQuery({
    variables: {
      orderBy: {
        createdAt: filters.createdAt,
      },
      take: recordsPerPage,
      skip: (page - 1) * recordsPerPage,
      where: {
        AND: [
          {
            staffId: {
              equals: filters.staff.value || undefined,
            },
          },
          {
            OR:
              typeof filters?.searchWord === "string" && filters.searchWord.length > 0
                ? [
                    {
                      json: {
                        path: ["message"],
                        string_contains: filters.searchWord,
                      },
                    },
                    {
                      json: {
                        path: ["message"],
                        string_contains: filters.searchWord.toUpperCase(),
                      },
                    },
                    {
                      json: {
                        path: ["message"],
                        string_contains: filters.searchWord.toLowerCase(),
                      },
                    },
                  ]
                : [],
          },
          {
            OR: filters.action
              ? [
                  {
                    json: {
                      path: ["action"],
                      equals: filters.action,
                    },
                  },
                ]
              : [],
          },
        ],
      },
    },
    fetchPolicy: "network-only",
  });
  const { data: staffData } = useFindManyStaffQuery();
  const [selectedJson, setSelectedJson] = useState<any>({});

  const {
    isOpen: isFilterModalOpen,
    onOpen: onFilterModalOpen,
    onClose: onFilterModalClose,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const { isOpen: isJsonModalOpen, onOpen: onJsonModalOpen, onClose: onJsonModalClose } = useDisclosure();
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [staff] = useStaffStore((store) => [store.staff]);
  const [deleteOne, { loading: loadingDeleteOne }] = useDeleteOneStaffLogMutation();
  const [deleteMany, { loading: loadingDeleteMany }] = useDeleteManyStaffLogMutation();
  const toast = useToast();
  const { isOpen: exportModalOpen, onOpen: exportModalOnOpen, onClose: exportModalOnClose } = useDisclosure();
  const [exportDates, setExportDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [getLazyLogs, { loading: lazyLogsLoading, data: lazyLogsData }] = useGetAllLogsLazyQuery();

  const actions = Array.from(new Set(data?.getAllLogs.logs.map((log) => log.json?.action) as string[])).filter(Boolean);

  useEffect(() => {
    if (debouncedSearchWord) {
      setFilters((s) => ({ ...s, searchWord: debouncedSearchWord }));
    }
  }, [debouncedSearchWord]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Logs {`${data?.getAllLogs?.count ? `-${data?.getAllLogs?.count}` : ""}`}
        </Box>
        <Flex experimental_spaceX={4}>
          {ALLOWED_ROLES.includes(staff?.role as StaffRoleType) && selectedIds.length > 0 && (
            <Tooltip label="Delete Selected Logs">
              <DeleteItem
                handleDelete={async () => {
                  if (!loadingDeleteMany) {
                    try {
                      await deleteMany({
                        variables: {
                          where: {
                            id: {
                              in: selectedIds,
                            },
                          },
                        },
                      });
                      toast({
                        status: "success",
                        title: "Success selected logs was deleted",
                      });
                      refetch();
                    } catch (error) {
                      toast({
                        status: "error",
                        title: "Something went wrong",
                      });
                    }
                  }
                }}
                loading={loadingDeleteMany}
                id="Delete Selected Logs"
                title="Delete Selected Logs"
                size="sm"
              />
            </Tooltip>
          )}
          <Tooltip label="Filters" aria-label="Filters">
            <IconButton
              variant="solid"
              icon={<FcFilledFilter />}
              aria-label="Toggle Filters"
              onClick={onFilterModalOpen}
              title={"Log Filters"}
            />
          </Tooltip>
          <Tooltip label="Refetch Data" aria-label="Refresh">
            <IconButton icon={<FcRefresh />} aria-label="Refetch Query" onClick={() => refetch()} />
          </Tooltip>
          <Tooltip label="Export Log">
            <IconButton
              aria-label="Export Log"
              icon={<RiFileExcel2Line />}
              color="green.400"
              fontSize={17}
              onClick={() => exportModalOnOpen()}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {/* <SimpleGrid mb={3} columns={2} gap={4}>
        <FormControl>
          <FormLabel>Search</FormLabel>
          <Input
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder="Search..."
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            Which User Performed The Action?
          </FormLabel>
          <Input
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder="Search..."
          />
        </FormControl>
      </SimpleGrid> */}

      <FormControl my={6} width="40%" ml="auto">
        <Input
          type="text"
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="Search..."
          borderColor="#ccc"
        />
        <FormHelperText>
          You can access filters by clicking the filter button on the top right-side of the page.
        </FormHelperText>
      </FormControl>

      <Table>
        <Thead>
          <Tr>
            {ALLOWED_ROLES.includes(staff?.role as StaffRoleType) && (
              <Th>
                <Tooltip label={selectedIds.length == 0 ? "Select All" : "Unselect All"}>
                  <IconButton
                    aria-label="Select All"
                    onClick={() => {
                      const ids = selectedIds.length > 0 ? [] : data?.getAllLogs.logs.map((log) => log.id)!;
                      setSelectedIds(ids);
                    }}
                    icon={selectedIds.length == 0 ? <FcCursor /> : <MdOutlineCancel />}
                    size="xs"
                  />
                </Tooltip>
              </Th>
            )}
            <Th>#</Th>
            <Th>Staff</Th>
            <Th>Staff Role</Th>
            <Th>Action Type</Th>
            <Th>Action</Th>
            <Th>Created At</Th>
            <Th textAlign="right">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.getAllLogs?.logs.map((log, index) => (
            <Tr key={log.id}>
              {ALLOWED_ROLES.includes(staff?.role as StaffRoleType) && (
                <Td>
                  <Checkbox
                    colorScheme="blue"
                    size="md"
                    onChange={(e) => {
                      if (selectedIds.includes(log.id)) {
                        setSelectedIds(selectedIds.filter((a) => a !== log.id));
                      } else {
                        setSelectedIds([...selectedIds, log.id]);
                      }
                    }}
                    isChecked={selectedIds.includes(log.id)}
                  />
                </Td>
              )}

              <Td>{(page - 1) * recordsPerPage + index + 1}</Td>
              <Td>{log.staff?.name}</Td>
              <Td>
                <Badge colorScheme="blue" fontSize={12}>
                  {log.staff?.role}
                </Badge>
              </Td>
              <Td>
                <Badge colorScheme="red" fontSize={12}>
                  {log.json?.action}
                </Badge>
              </Td>
              <Td>{log.json?.message}</Td>
              <Td>{new Date(log.createdAt).toLocaleString()}</Td>
              <Td textAlign="right">
                <Box display="flex" alignItems="center" gap={3} justifyContent="flex-end">
                  <Tooltip label="Show Json Data">
                    <IconButton
                      icon={<CiViewList fontSize={18} />}
                      aria-label="View"
                      onClick={() => {
                        setSelectedJson(log.json);
                        onJsonModalOpen();
                      }}
                      colorScheme="blue"
                      size={"xs"}
                    />
                  </Tooltip>
                  {ALLOWED_ROLES.includes(staff?.role as StaffRoleType) && (
                    <Tooltip label="Delete Selected Log">
                      <DeleteItem
                        handleDelete={async () => {
                          if (!loadingDeleteOne) {
                            try {
                              await deleteOne({
                                variables: {
                                  where: {
                                    id: log.id,
                                  },
                                },
                              });
                              refetch();
                              toast({
                                status: "success",
                                title: "Success log was deleted",
                              });
                            } catch (error) {
                              toast({
                                status: "error",
                                title: "Something went wrong",
                              });
                            }
                          }
                        }}
                        title="Delete Selected Logs"
                        loading={loadingDeleteOne}
                        size="sm"
                        id={log.id}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        totalPage={data?.getAllLogs?.count ? Math.ceil(data?.getAllLogs?.count / RECORDS_PER_PAGE) : 1}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        recordsPerPageText={"Records Per Page"}
        showRecordsText={"Show Records"}
      />

      <Modal size="5xl" isOpen={isFilterModalOpen} onClose={onFilterModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              <Text fontWeight="bold" color="blue.300">
                Sort By:
              </Text>
              <Stack gap={4} px={6} pt={6} pb={8} border="1px solid" borderColor="blue.300" rounded="md">
                <SimpleGrid columns={3} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Create Date</FormLabel>
                    <Select
                      value={filters.createdAt?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        // @ts-ignore
                        setFilters((s) => ({ ...s, createdAt: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Newest first</option>
                      <option value="asc">Oldest first</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </Stack>

              <Text fontWeight="bold" color="blue.300">
                Filter By:
              </Text>

              <SimpleGrid
                columns={3}
                gap={4}
                px={6}
                pt={6}
                pb={8}
                border="1px solid"
                borderColor="blue.300"
                rounded="md"
              >
                <FormControl>
                  <FormLabel color="blue.300">Search (Action Message)</FormLabel>
                  <Input
                    type="text"
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                    placeholder="Search..."
                    borderColor="#ccc"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color="blue.300">Staff</FormLabel>
                  <ReactSelect
                    placeholder="Select Staff"
                    isMulti={false}
                    options={staffData?.findManyStaff.map((staff) => ({
                      label: `${staff.name} - ${staff.email}`,
                      value: staff.id,
                    }))}
                    value={filters.staff}
                    onChange={(e) => {
                      // @ts-ignore
                      setFilters((s) => ({ ...s, staff: e }));
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color="blue.300">Action Type</FormLabel>
                  <Select
                    value={filters.action}
                    onChange={(e) => {
                      const isUndefined = e.target.value === "undefined";
                      const parsed = isUndefined ? undefined : e.target.value;
                      // @ts-ignore
                      setFilters((s) => ({ ...s, action: parsed }));
                    }}
                    borderColor="#ccc"
                  >
                    <option value="undefined">-</option>
                    {actions.map((action) => (
                      <option key={action} value={action}>
                        {action}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>
                    After selection if you want all the actions, please select the "-" option.
                  </FormHelperText>
                </FormControl>
              </SimpleGrid>
            </Stack>
          </ModalBody>

          <ModalFooter mt={4} justifyContent="flex-end">
            <Button
              colorScheme="blue"
              mr={3}
              display="flex"
              alignItems="center"
              gap={3}
              color="#fff"
              onClick={() => refetch()}
            >
              <Text>Apply Filters</Text>
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              display="flex"
              alignItems="center"
              gap={3}
              color="#fff"
              onClick={() => {
                setFilters(defaultFilters);
              }}
            >
              <Text>Reset Filters</Text>
              <Icon as={FiTrash2} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="5xl"
        isOpen={isJsonModalOpen}
        onClose={() => {
          setSelectedJson({});
          onJsonModalClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Data Changes (JSON)</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Code
              overflowX="scroll"
              whiteSpace="pre-wrap"
              fontSize={13.5}
              padding={4}
              rounded="md"
              borderColor="gray.300"
              borderWidth={1}
            >
              {JSON.stringify(selectedJson, null, 2)}
            </Code>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={exportModalOpen} onClose={exportModalOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Logs</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={10}>
            {/* create two date input get the log range */}
            <Box display="flex" gap={3}>
              <FormControl>
                <FormLabel>Start Date:</FormLabel>

                <DatePicker
                  selected={exportDates.startDate}
                  onChange={(date) => {
                    setExportDates({
                      ...exportDates,
                      startDate: new Date(date as Date),
                    });
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date:</FormLabel>
                <DatePicker
                  selected={exportDates.endDate}
                  onChange={(date) => {
                    setExportDates({
                      ...exportDates,
                      endDate: new Date(date as Date),
                    });
                  }}
                />
              </FormControl>
            </Box>

            <Box display="flex" alignItems="center" gap={3}>
              <Button
                mt={6}
                colorScheme="green"
                onClick={async () => {
                  const lazy = await getLazyLogs();
                  const logs = lazy.data?.getAllLogs.logs;
                  const stringified = logs?.filter((log) => {
                    const date = new Date(log.createdAt);
                    const startDate = new Date(exportDates.startDate);
                    const endDate = new Date(exportDates.endDate);

                    const [sDay, sMonth, sYear] = [startDate.getDate(), startDate.getMonth(), startDate.getFullYear()];
                    const [eDay, eMonth, eYear] = [endDate.getDate(), endDate.getMonth(), endDate.getFullYear()];
                    const [logDay, logMonth, logYear] = [date.getDate(), date.getMonth(), date.getFullYear()];
                    return (
                      logDay >= sDay &&
                      logDay <= eDay &&
                      logMonth >= sMonth &&
                      logMonth <= eMonth &&
                      logYear >= sYear &&
                      logYear <= eYear
                    );
                  });
                  await exportData(stringified, "logs.json", "application/json");
                }}
                isLoading={lazyLogsLoading}
              >
                Export Date Range
              </Button>
              <Button
                mt={6}
                colorScheme="blue"
                onClick={async () => {
                  const lazy = await getLazyLogs();
                  await exportData(lazy.data?.getAllLogs.logs, "logs.json", "application/json");
                }}
                isLoading={lazyLogsLoading}
              >
                Export All
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default All;
