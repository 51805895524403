import { useProductPriceConstantQuery, useRingSizeStandartQuery } from "../../generated/graphql";
import Loading from "../../components/loading/Loading";
import { Navigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const { loading, error, data } = useProductPriceConstantQuery({
    variables: {
      where: {
        key: id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to="/constant" replace />;
  }

  return <Form actionType="UPDATE" data={data?.productPriceConstant} id={id} />;
};

export default Update;
