import { Box, Select, Flex, Button, Icon, Text } from "@chakra-ui/react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

type Props = {
  totalPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRecordsPerPage: React.Dispatch<React.SetStateAction<number>>;
  recordsPerPage: number;
  recordsPerPageText?: string;
  showRecordsText?: string;
};

function Pagination({
  page,
  setPage,
  totalPage,
  recordsPerPage,
  setRecordsPerPage,
  recordsPerPageText,
  showRecordsText,
}: Props) {
  return (
    <Flex wrap="wrap" my={4} alignItems="center" justifyContent="space-between">
      <Flex
        alignItems="center"
        width={{
          base: "full",
          lg: 1 / 3,
        }}
        px={4}
        mb={{
          base: 4,
          lg: 0,
        }}
      >
        <Text fontSize="xs" color="gray.500">
          {recordsPerPageText || "per page"}
        </Text>
        <Box
          mx={3}
          fontSize="xs"
          color="gray.500"
          bgColor="white"
          rounded="base"
          border="1px solid"
          borderColor="gray.300"
        >
          <Select
            width="full"
            size="sm"
            fontSize="sm"
            color="gray.500"
            border="none"
            outline="none"
            _focus={{
              outline: "none",
            }}
            _selected={{
              outline: "none",
            }}
            value={recordsPerPage || 1}
            onChange={(e) => setRecordsPerPage(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </Select>
        </Box>
        <Text as="p" fontSize="xs" color="gray.500">
          {showRecordsText || "Show Record"}
        </Text>
      </Flex>

      <Flex
        width={{
          base: "full",
          lg: "auto",
        }}
        px={4}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="ghost"
          display="inline-flex"
          mr={3}
          alignItems="center"
          justifyContent="center"
          w={8}
          h={8}
          fontSize="xs"
          color="gray.500"
          border="1px solid"
          borderColor="gray.300"
          bgColor="white"
          _hover={{
            bgColor: "purple.500",
            color: "whiteAlpha.900",
          }}
          rounded="base"
          onClick={() => setPage((prev) => prev - 1)}
          isDisabled={page === 1}
        >
          <Icon as={HiChevronLeft} />
        </Button>
        <Select
          variant="ghost"
          display="inline-flex"
          mr={3}
          alignItems="center"
          justifyContent="center"
          w={20}
          h={8}
          fontSize="xs"
          color="gray.500"
          border="1px solid"
          borderColor="gray.300"
          rounded="base"
          onChange={(e) => setPage(Number(e.target.value))}
          value={page}
        >
          {new Array(totalPage).fill(0).map((_, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Select>

        <Button
          variant="ghost"
          display="inline-flex"
          mr={3}
          alignItems="center"
          justifyContent="center"
          w={8}
          h={8}
          fontSize="xs"
          color="gray.500"
          border="1px solid"
          borderColor="gray.300"
          bgColor="white"
          _hover={{
            bgColor: "purple.500",
            color: "whiteAlpha.900",
          }}
          rounded="base"
          onClick={() => setPage((prev) => prev + 1)}
          isDisabled={page === totalPage}
        >
          <Icon as={HiChevronRight} />
        </Button>
      </Flex>
    </Flex>
  );
}

export default Pagination;
