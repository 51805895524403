import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import validator from "validator";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreateDiscountCouponMutationVariables,
  DiscountCouponQuery,
  LanguageOptions,
  UpdateDiscountCouponMutationVariables,
  useCustomersQuery,
  useUpdateDescriptionMutation,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";
import dayjs from "dayjs";
import { DescriptionProperty } from "../../types/descriptionProperty";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";

interface Props {
  handleCreate?: (data: CreateDiscountCouponMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateDiscountCouponMutationVariables) => Promise<any>;
  id?: string;
  data?: DiscountCouponQuery["discountCoupon"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [descriptions, setDescriptions] = useState<DescriptionProperty[]>(
    getLocalizedDescription({ descriptions: data?.descriptions })
  );
  const [updateDescriptionMutation, { loading: updateDescriptionLoading }] =
    useUpdateDescriptionMutation();

  const [code, setCode] = useState(data?.code || "");
  const [amount, setAmount] = useState(data?.amount || 0);
  const [validUntil, setValidUntil] = useState(
    dayjs(data?.validUntil).format("YYYY-MM-DD") || ""
  );
  const [customerId, setCustomerId] = useState(data?.customerId);
  const [status, setStatus] = useState(data?.status && true);
  const [isPercentage, setIsPercentage] = useState(data?.isPercentage && true);

  const { data: usersData } = useCustomersQuery();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.EN)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title English is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.DE)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title German is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.ES)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Spanish is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.FR)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title French is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.IT)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Italian is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.TR)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Turkish is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!code && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Code is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.EN)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.EN
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.EN)!.title
                  ),
                  language: LanguageOptions.EN,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.DE)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.DE
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.DE)!.title
                  ),
                  language: LanguageOptions.DE,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.ES)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.ES
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.ES)!.title
                  ),
                  language: LanguageOptions.ES,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.FR)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.FR
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.FR)!.title
                  ),
                  language: LanguageOptions.FR,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.IT)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.IT
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.IT)!.title
                  ),
                  language: LanguageOptions.IT,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.TR)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.TR
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.TR)!.title
                  ),
                  language: LanguageOptions.TR,
                },
              ],
            },
            code,
            amount: parseFloat(amount).toFixed(2),
            validUntil,
            isPercentage: isPercentage ? true : false,
            status: status ? true : false,
            ...(customerId && { customer: { connect: { id: customerId } } }),
          },
        });
      }
      if (actionType === "UPDATE") {
        for await (const iterator of descriptions) {
          await updateDescriptionMutation({
            variables: {
              where: { id: iterator.descriptionId },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                slug: { set: createSlug(iterator.title ?? "") },
              },
            },
          });
        }
        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            code: { set: code },
            amount: { set: parseFloat(amount).toFixed(2) },
            validUntil: { set: validUntil },
            isPercentage: { set: isPercentage ? true : false },
            status: { set: status ? true : false },
            ...(customerId
              ? { customer: { connect: { id: customerId } } }
              : { customer: { disconnect: true } }),
          },
        });
      }
    } catch (error) {}
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Discount Coupon
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to="/discountcoupon"
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <Tabs>
                <TabList>
                  <Tab>EN</Tab>
                  <Tab>DE</Tab>
                  <Tab>ES</Tab>
                  <Tab>FR</Tab>
                  <Tab>IT</Tab>
                  <Tab>TR</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.EN)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.EN
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.EN)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.EN
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.DE)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.DE
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.DE)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.DE
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.ES)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.ES
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.ES)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.ES
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.FR)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.FR
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.FR)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.FR
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.IT)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.IT
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.IT)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.IT
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.TR)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.TR
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.TR)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.TR
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Code</FormLabel>
              <Input value={code} onChange={(e) => setCode(e.target.value)} />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Discount Rate</FormLabel>
              <Input value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Validity Date</FormLabel>
              <Input
                type="date"
                value={validUntil}
                onChange={(e) => setValidUntil(e.target.value)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Member Special Discount</FormLabel>
              {usersData && (
                <ReactSelect
                  placeholder="Customer"
                  options={usersData!.customers.items.map((user) => ({
                    value: user.id,
                    label: `${user.name} ${user.surname} - ${user.email}`,
                  }))}
                  {...(actionType === "UPDATE" && {
                    ...{
                      value: usersData
                        ?.customers!.items.map((user) => ({
                          label: `${user.name} ${user.surname} - ${user.email}`,
                          value: user.id,
                        }))
                        .filter((c) => c.value === customerId),
                    },
                  })}
                  isClearable
                  onChange={(e, p) => {
                    if (p.action === "clear") {
                      setCustomerId(undefined);
                    }
                    setCustomerId(e && e.value ? e.value : undefined);
                  }}
                />
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Percentage Discount</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={isPercentage ? "green" : "gray"}
                    onClick={() => setIsPercentage(true)}
                  >
                    Yes
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!isPercentage ? "red" : "gray"}
                    onClick={() => setIsPercentage(false)}
                  >
                    No
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Status</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={status ? "green" : "gray"}
                    onClick={() => setStatus(true)}
                  >
                    Yes
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!status ? "red" : "gray"}
                    onClick={() => setStatus(false)}
                  >
                    No
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme="green"
            type="submit"
            mt={6}
            isDisabled={updateDescriptionLoading}
            isLoading={updateDescriptionLoading}
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
