import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import { SortOrder, useCreateOneStaffLogMutation, useDeletePageMutation, usePagesQuery } from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcApproval, FcCancel } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import Description from "../../components/description/Description";
import { IoMdReorder } from "react-icons/io";
import { useStaffStore } from "../../store/staff";

const All = () => {
  const [deletePage, { loading: deleteLoading }] = useDeletePageMutation();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const { loading, error, data, refetch } = usePagesQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deletePage({
        variables: {
          where: {
            id,
          },
        },
      });

      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };
  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Pages
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to="/page/order"
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetch();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "REFETCH",
                        message: `Refetched Pages by ${staff?.name}`,
                      },
                      tableName: "Page",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton to={`/page/create`} as={Link} icon={<FcPlus />} aria-label="Add Item" onClick={() => refetch()} />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Title</Th>
            <Th textAlign={"center"}>Type</Th>
            {/* <Th textAlign={"center"}>İçerikler</Th> */}
            <Th>Header</Th>
            <Th>Footer</Th>
            <Th>Status</Th>
            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.pages.map((page, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td minWidth={"300px"}>
                  <Description field="page" id={page.id} descriptions={page.descriptions} refetch={refetch} />
                </Td>
                <Td textAlign={"center"}>{page.pageType}</Td>
                {/* <Td textAlign={"center"}>
                  <IconButton
                    aria-label="Contents"
                    as={Link}
                    to={`/content/page/${page.id}`}
                    icon={<FcDatabase />}
                  />
                </Td> */}
                <Td>
                  {page.showOnHeader ? (
                    <IconButton aria-label="Change Status" icon={<FcApproval />} />
                  ) : (
                    <IconButton aria-label="Change Status" icon={<FcCancel />} />
                  )}
                </Td>
                <Td>
                  {page.showOnFooter ? (
                    <IconButton aria-label="Change Status" icon={<FcApproval />} />
                  ) : (
                    <IconButton aria-label="Change Status" icon={<FcCancel />} />
                  )}
                </Td>
                <Td>
                  {page.status ? (
                    <IconButton aria-label="Change Status" icon={<FcApproval />} />
                  ) : (
                    <IconButton aria-label="Change Status" icon={<FcCancel />} />
                  )}
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton aria-label="Edit" icon={<FcEditImage />} as={Link} to={`/page/update/${page.id}`} />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={page.id}
                        loading={deleteLoading}
                        title={`${page.descriptions[0].title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
