import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  Flex,
  IconButton,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { CountryQuery, UpdateOneCountryMutationVariables } from "../../generated/graphql";
import { FcList } from "react-icons/fc";

interface Props {
  handleUpdate?: (data: UpdateOneCountryMutationVariables) => Promise<any>;
  id?: string;
  data?: CountryQuery["country"];
}

const Form: React.FC<Props> = ({ handleUpdate, id, data }) => {
  const [shippingPrice, setShippingPrice] = useState(data?.shippingPrice || "0");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await handleUpdate!({
        where: {
          id: id,
        },
        data: {
          shippingPrice: { set: parseFloat(shippingPrice).toFixed(2) },
        },
      });
    } catch (error) {}
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Update Country
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/tax" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {data?.tax && (
              <GridItem colSpan={2}>
                <Alert status="info">
                  <AlertIcon />
                  This country has taxation. ({data.tax.shortcode} {data.tax.value}%)
                </Alert>
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <FormLabel>Country</FormLabel>
              <Input value={data?.label} readOnly disabled />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Short Code</FormLabel>
              <Input value={data?.value} readOnly disabled />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Shipping Price</FormLabel>
              <Input
                value={shippingPrice}
                onChange={(e) => setShippingPrice(Number(e.target.value))}
              />
            </GridItem>
          </SimpleGrid>
          <Button colorScheme="green" type="submit" mt={6}>
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
