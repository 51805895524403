import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  PaymentType,
  QueryMode,
  SortOrder,
  useCreateOneStaffLogMutation,
  useOrdersQuery,
} from "../../generated/graphql";
import { FcRefresh, FcAbout } from "react-icons/fc";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useStaffStore } from "../../store/staff";

const All = () => {
  const [searchWord, setSearchWord] = useState("");
  const [searchValue] = useDebounce(searchWord, 1000);
  const { loading, error, data, refetch } = useOrdersQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      where: {
        ...(searchValue && {
          OR: [
            {
              shippingFirstName: {
                contains: searchValue,
                mode: QueryMode.insensitive,
              },
            },
            {
              shippingLastName: {
                contains: searchValue,
                mode: QueryMode.insensitive,
              },
            },
            {
              shippingEmail: {
                contains: searchValue,
                mode: QueryMode.insensitive,
              },
            },
            {
              shippingAddress: {
                contains: searchValue,
                mode: QueryMode.insensitive,
              },
            },
          ],
        }),
      },
    },
    fetchPolicy: "network-only",
  });
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  
  useEffect(() => {
    if (!logLoading && searchValue !== "") {
      createLog({
        variables: {
          data: {
            staff: {
              connect: {
                id: staff?.id as string,
              },
            },
            json: {
              message: `Orders filtered by search value: ${searchValue}`,
            },
            tableName: "Order",
          },
        },
      })
    }
  }, [searchValue, logLoading]);


  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Orders
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetch();
              if (!logLoading) {
                const returned = await createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        message: "Orders refetched",
                      },
                      tableName: "Order",
                    },
                  },
                });
              }
            }}
          />
        </Flex>
      </Flex>
      <FormControl mb={2}>
        <FormLabel>Search</FormLabel>
        <Input type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} placeholder="Search..." />
      </FormControl>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Order Date</Th>
            <Th>Customer</Th>
            <Th>Price</Th>
            <Th>Paid Price</Th>
            <Th>Payment Gateway</Th>
            <Th>Status</Th>
            <Th textAlign={"center"}>İnfo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.orders.map((order, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{dayjs(order.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Td>
                <Td>{`${order.customer.name} ${order.customer.surname}`}</Td>
                <Td>
                  {order.payment
                    ? order.paymentType === PaymentType.IYZICO
                      ? order.payment?.price
                      : (order.payment?.price / 100).toFixed(2)
                    : 0}
                </Td>
                <Td>
                  {" "}
                  {order.payment
                    ? order.paymentType === PaymentType.IYZICO
                      ? order.payment?.paidPrice
                      : (order.payment?.paidPrice / 100).toFixed(2)
                    : 0}
                </Td>
                <Td>{order.paymentType}</Td>
                <Td>{order.orderStatus}</Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton aria-label="Edit" icon={<FcAbout />} as={Link} to={`/orders/detail/${order.id}`} />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
