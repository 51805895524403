import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateProductCustomMutationVariables,
  useCreateProductCustomMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createMutation, { loading }] = useCreateProductCustomMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreate = async (data: CreateProductCustomMutationVariables) => {
    try {
      await createMutation({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Successful",
        description: "Product created",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/product");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };
  return <Form handleCreate={handleCreate} actionType="CREATE" loading={loading} />;
};

export default Create;
