import {
  UpdateCustomerMutationVariables,
  useCustomerQuery,
  useUpdateCustomerMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateUser] = useUpdateCustomerMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useCustomerQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/user"} replace />;
  }

  const handleUpdateUser = async (data: UpdateCustomerMutationVariables) => {
    try {
      await updateUser({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Successful",
        description: "Customer updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/customer");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      actionType="UPDATE"
      data={data?.customer}
      id={id}
      handleUpdateUser={handleUpdateUser}
    />
  );
};

export default Update;
