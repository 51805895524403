import {
  UpdateOneCountryMutationVariables,
  useCountryQuery,
  useCreateOneStaffLogMutation,
  useUpdateOneCountryMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";
import { useStaffStore } from "../../store/staff";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateMutation] = useUpdateOneCountryMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const { loading, error, data } = useCountryQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to="/tax" replace />;
  }

  const handleUpdate = async (data: UpdateOneCountryMutationVariables) => {
    try {
      await updateMutation({
        variables: {
          ...data,
        },
      });
      console.log('DATA ', data);

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Shipping Price Country: ${data?.data.shippingPrice?.set} by ${staff?.name}`,
              },
              tableName: "Country",
            },
          },
        }),
      ]);

      return toast({
        status: "success",
        title: "Successful",
        description: "Country updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/country");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return <Form data={data?.country} id={id} handleUpdate={handleUpdate} />;
};

export default Update;
