import { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  FormHelperText,
  FormControl,
  Tooltip,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";

import { FcList } from "react-icons/fc";
import {
  ProductPriceConstantQuery,
  useCreateOneProductPriceConstantMutation,
  useCreateOneStaffLogMutation,
  useUpdateOneProductPriceConstantMutation,
} from "../../generated/graphql";
import { useStaffStore } from "../../store/staff";

interface Props {
  id?: string;
  data?: ProductPriceConstantQuery["productPriceConstant"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ data, actionType }) => {
  const [name, setName] = useState(() => {
    return data?.key || "";
  });
  const [value, setValue] = useState(() => {
    return data?.value || "";
  });
  const [createConstant, { loading: loadingConstantCreate }] = useCreateOneProductPriceConstantMutation();
  const [updateConstant, { loading: loadingConstantUpdate }] = useUpdateOneProductPriceConstantMutation();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();
  const toast = useToast();

  const navigation = useNavigate();

  const onSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loadingConstantCreate || loadingConstantUpdate) return;
    if (name.trim().length === 0 || value.trim().length === 0) {
      return toast({
        title: "Error",
        description: "Please fill all the fields!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    // check value is decimal
    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return toast({
        title: "Error",
        description: "Value should be decimal!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
   

    try {
      if (actionType === "CREATE") {
        await createConstant({
          variables: {
            data: {
              key: name,
              value,
            },
          },
        });
        toast({
          title: "Success",
          description: "Constant created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Constant ${name} by ${staff?.name}`,
                },
                tableName: "ProductPriceConstant",
              },
            },
          }),
        ]);

        navigation("/constant");
      }

      if (actionType === "UPDATE") {
        await updateConstant({
          variables: {
            where: {
              key: data?.key as string,
            },
            data: {
              value: {
                set: value,
              },
            },
          },
        });
        toast({
          title: "Success",
          description: "Constant updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "UPDATE",
                  message: `Updated Constant ${name} by ${staff?.name}`,
                },
                tableName: "ProductPriceConstant",
              },
            },
          }),
        ]);
        navigation("/constant");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setName(data.key);
      setValue(data.value);
    }
  }, [data]);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Constant
        </Box>
        <Flex experimental_spaceX={4}>
          <Tooltip label="Go to Constants">
            <IconButton to="/constant" as={Link} icon={<FcList />} aria-label="All Items" />
          </Tooltip>
        </Flex>
      </Flex>
      <form onSubmit={onSubmitHandler}>
        <SimpleGrid columns={2} gap={10}>
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Constant Name)"
                  type="text"
                  disabled={actionType === "UPDATE"}
                />
                <FormHelperText mt={1}>(For example EU, US)</FormHelperText>
                <FormHelperText>Name should be unique!</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2} mt={6}>
              <FormControl>
                <FormLabel>Value</FormLabel>
                <Input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Constant Value)"
                  type="text"
                />
                <FormHelperText mt={1}>(For example 15.00, 12.17)</FormHelperText>
                <FormHelperText>Value</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={loadingConstantCreate || loadingConstantUpdate}
                isLoading={loadingConstantCreate || loadingConstantUpdate}
              >
                Save
              </Button>
            </GridItem>
          </SimpleGrid>
        </SimpleGrid>
      </form>
    </PageLayout>
  );
};

export default Form;
