import { Flex, IconButton, Link, Table, Thead, Tbody, Tr, Th, Td, Box, Badge } from "@chakra-ui/react";
import { FC } from "react";
import { FcEditImage, FcPlus, FcRefresh } from "react-icons/fc";
import { IoMdReorder } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DeleteItem from "../../components/delete/DeleteItem";
import PageLayout from "../../components/ui/PageLayout";
import {
  LanguageOptions,
  useCreateOneStaffLogMutation,
  useDeleteOneBlogCategoryMutation,
  useGetBlogCategoriesQuery,
} from "../../generated/graphql";
import { useStaffStore } from "../../store/staff";

const All: FC = () => {
  const { data, refetch } = useGetBlogCategoriesQuery();
  const [deleteCategory] = useDeleteOneBlogCategoryMutation();
  const navigate = useNavigate();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  return (
    <PageLayout>
      <PageLayout>
        <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
          <Box fontSize={"18px"} fontWeight="bold">
            Blog Categories
          </Box>
          <Flex experimental_spaceX={4}>
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={async () => {
                await refetch();
                await Promise.allSettled([
                  createLog({
                    variables: {
                      data: {
                        staff: {
                          connect: {
                            id: staff?.id as string,
                          },
                        },
                        json: {
                          action: "GET",
                          message: `Refetched Blog Categories by ${staff?.name}`,
                        },
                        tableName: "BlogCategories",
                      },
                    },
                  }),
                ]);
              }}
            />
            <IconButton icon={<FcPlus />} aria-label="Add Item" onClick={() => navigate("/blog-categories/create")} />
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Title</Th>
              <Th textAlign="center">Blog Count</Th>
              <Th textAlign="center">Created At</Th>
              <Th textAlign="center">Created At</Th>
              <Th textAlign="center">Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.blogCategories?.map((cat, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{cat.descriptions.find((d) => d?.language === LanguageOptions.EN)?.title || ""}</Td>
                {/* @ts-ignore */}
                <Td textAlign="center">{cat?._count?.Blog}</Td>
                <Td textAlign="center">
                  <Badge colorScheme={cat?.status ? "green" : "red"} children={cat?.status ? "Active" : "Inactive"} />
                </Td>
                <Td textAlign="center">{new Date(cat.createdAt).toLocaleDateString("de-DE")}</Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        onClick={() => navigate(`/blog-categories/update/${cat.id}`)}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={cat.id}
                        loading={false}
                        title={`${cat.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}`}
                        handleDelete={async () => {
                          await deleteCategory({
                            variables: {
                              where: {
                                id: cat.id,
                              },
                            },
                          });
                          await Promise.allSettled([
                            createLog({
                              variables: {
                                data: {
                                  staff: {
                                    connect: {
                                      id: staff?.id as string,
                                    },
                                  },
                                  json: {
                                    action: "DELETE",
                                    message: `Deleted Blog Category ${
                                      cat.descriptions.find((d) => d.language === LanguageOptions.EN)?.title
                                    } by ${staff?.name}`,
                                  },
                                  tableName: "BlogCategory",
                                },
                              },
                            }),
                          ]);
                          refetch();
                        }}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageLayout>
    </PageLayout>
  );
};

export default All;
