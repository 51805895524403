import {
  Box,
  Button,
  Flex,
  FormLabel,
  GridItem,
  IconButton,
  Input,
  Link,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, FormEvent, useEffect, useState } from "react";
import { FcList } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import getSlug, { createSlug } from "speakingurl";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import {
  LanguageOptions,
  useGetBlogCategoriesQuery,
  useCreateOneBlogMutation,
  GetBlogQuery,
  useUpdateDescriptionMutation,
  useUpdateOneBlogMutation,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { useStaffStore } from "../../store/staff";
import DescriptionTabs from "../../components/description/DescriptionForm";
import { updatedDiff } from "deep-object-diff";

const editorSettings = {
  skin: "snow",
  icons: "thin",
  placeholder: "Description...",
  height: 300,
  menubar: false,
  min_height: 200,
  max_height: 500,

  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code wordcount",
  ],
  toolbar:
    "undo redo | formatselect | " +
    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
    "removeformat | code",
};

type Props = {
  actionType: "CREATE" | "UPDATE";
  blog?: GetBlogQuery["getBlog"];
};

const Form: FC<Props> = ({ actionType, blog }) => {
  const [photo, setPhoto] = useState(() => {
    return blog?.photo || "";
  });
  const [descriptions, setDescriptions] = useState<any[]>(() => {
    return (
      blog?.descriptions || [
        {
          title: "",
          description: "",
          language: LanguageOptions.EN,
          slug: "",
        },
        {
          title: "",
          description: "",
          language: LanguageOptions.DE,
          slug: "",
        },
        {
          title: "",
          description: "",
          language: LanguageOptions.ES,
          slug: "",
        },
        {
          title: "",
          description: "",
          language: LanguageOptions.FR,
          slug: "",
        },
        {
          title: "",
          description: "",
          language: LanguageOptions.IT,
          slug: "",
        },
        {
          title: "",
          description: "",
          language: LanguageOptions.TR,
          slug: "",
        },
      ]
    );
  });
  //
  const { data: blogCategories } = useGetBlogCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(() => {
    return blog?.category?.id || "";
  });
  const [status, setStatus] = useState<boolean>(() => {
    return blog?.status || false;
  });
  const [createOneBlog, { loading: createLoading }] = useCreateOneBlogMutation();
  const navigate = useNavigate();
  const [updateDescriptionMutation] = useUpdateDescriptionMutation();
  const [updateBlog] = useUpdateOneBlogMutation();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const langs = ["EN", "DE", "ES", "FR", "IT", "TR"];
      const isAllTitleFilled = langs.every((lang) => {
        return descriptions.find((desc) => desc?.language === lang)?.title;
      });

      if (!selectedCategory || !isAllTitleFilled) {
        return toast({
          title: "Error",
          description: "Please fill all fields & select category & upload photo & fill all title fields",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      if (actionType === "CREATE") {
        if (!photo) {
          return toast({
            title: "Error",
            description: "Please fill all fields & select category & upload photo & fill all title fields",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        const result = await createOneBlog({
          variables: {
            data: {
              photo,
              status,
              category: {
                connectOrCreate: {
                  where: {
                    id: selectedCategory,
                  },
                  create: {
                    author: staff?.name,
                  },
                },
              },
              descriptions: {
                create: descriptions.map((desc) => ({
                  ...desc,
                  slug: getSlug(desc.title),
                })),
              },
            },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: actionType,
                  message: `${actionType} Blog ${
                    descriptions.find((d) => d.language === LanguageOptions.EN)?.title
                  } by ${staff?.name}`,
                },
                tableName: "Blog",
              },
            },
          }),
        ]);
      }

      if (actionType === "UPDATE") {
        for (const iterator of descriptions) {
          await updateDescriptionMutation({
            variables: {
              where: { id: iterator.id },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                slug: { set: getSlug(iterator.title || "") },
              },
            },
          });
          // await
          const returned = await updateBlog({
            variables: {
              where: {
                id: blog?.id || "",
              },
              data: {
                status: {
                  set: status,
                },
                category: {
                  connectOrCreate: {
                    where: {
                      id: selectedCategory,
                    },
                    create: {
                      author: staff?.name,
                    },
                  },
                },
                photo: {
                  set: photo || "",
                },
              },
            },
          });

          await Promise.allSettled([
            createLog({
              variables: {
                data: {
                  staff: {
                    connect: {
                      id: staff?.id as string,
                    },
                  },
                  json: {
                    action: "UPDATE",
                    message: `Updated Blog ${descriptions.find((d) => d.language === LanguageOptions.EN)?.title} by ${
                      staff?.name
                    }`,
                    diffs: {
                      old: blog,
                      diff: updatedDiff(blog as any, returned.data?.updateOneBlog || ({} as any)),
                    },
                  },
                  tableName: "Blog - Description",
                },
              },
            }),
          ]);
        }
      }

      toast({
        title: "Success",
        description: "Process completed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/blog");
    } catch (error: any) {
      toast({
        title: "Error",
        description: error?.message || "error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (blog) {
      setPhoto(blog.photo);
      setDescriptions(
        Array.from(
          blog.descriptions.map((desc) => ({
            description: desc.description,
            title: desc.title,
            language: desc.language,
            slug: getSlug(desc.title),
            // @ts-ignore
            id: desc?.id as string,
          }))
        )
      );
      setSelectedCategory(blog.category?.id || null);
      setStatus(blog.status);
    }
  }, [blog]);

  return (
    <Box>
      <form onSubmit={onSubmitHandler}>
        <SimpleGrid columns={12} gap={12}>
          <GridItem
            colSpan={{
              base: 12,
              lg: 6,
            }}
          >
            <Box>
              <UploadDropzone isSingle images={[blog?.photo || photo]} setImages={setPhoto} />
            </Box>
            <SimpleGrid
              mt={4}
              columns={2}
              gap={{
                base: 6,
                md: 6,
              }}
            >
              <GridItem colSpan={2}>
                <DescriptionTabs descriptions={descriptions} setDescriptions={setDescriptions} />
              </GridItem>
            </SimpleGrid>
          </GridItem>
          <GridItem
            colSpan={{
              base: 12,
              lg: 6,
            }}
          >
            <Box mt={3}>
              <FormLabel>Select Blog Category</FormLabel>
              <Select
                value={selectedCategory ? selectedCategory : ""}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value={blog?.category?.id || ""} disabled selected>
                  {blog?.category?.descriptions?.find((d) => d.language === LanguageOptions.EN)?.title ||
                    "Select Category"}
                </option>

                {blogCategories?.blogCategories?.map((category) => (
                  <option
                    key={category?.id}
                    placeholder={
                      category?.descriptions?.find((description) => description?.language === LanguageOptions.EN)
                        ?.title || ""
                    }
                    value={category?.id}
                  >
                    {category?.descriptions?.find((description) => description?.language === LanguageOptions.EN)?.title}
                  </option>
                ))}
              </Select>

              <Box mt={4}>
                <FormLabel>Status</FormLabel>
                <Flex gap={2} mt={3}>
                  <Button flex={1} colorScheme={status ? "green" : "gray"} onClick={() => setStatus(true)}>
                    Active
                  </Button>
                  <Button flex={1} colorScheme={!status ? "red" : "gray"} onClick={() => setStatus(false)}>
                    De-Active
                  </Button>
                </Flex>
              </Box>
              <Box my={4}>
                <Button type="submit" colorScheme="green" disabled={createLoading}>
                  Save
                </Button>
              </Box>
            </Box>
          </GridItem>
        </SimpleGrid>
      </form>
    </Box>
  );
};

export default Form;
