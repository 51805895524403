import { Avatar, AvatarBadge, Badge, Box, Flex, Heading } from "@chakra-ui/react";
import { useStaffStore } from "../../store/staff";
import Sidebar from "./Sidebar/Sidebar";
import { useCheckStaffLazyQuery, useGetCurrenyQuery } from "../../generated/graphql";
import { useEffect } from "react";
import { FaClock } from "react-icons/fa";
import { MdCurrencyExchange } from "react-icons/md";
import { CiClock2 } from "react-icons/ci";

type Props = {
  children: React.ReactNode;
};

function Layout({ children }: Props) {
  const { staff, logoutStaff } = useStaffStore();
  const [checkStaff] = useCheckStaffLazyQuery({
    fetchPolicy: "network-only",
  });
  const { data: currency } = useGetCurrenyQuery();
  useEffect(() => {
    checkStaff().then((res) => {
      if (!res.data?.checkStaff) {
        logoutStaff();
        window.location.reload();
      }
    });
  }, [checkStaff, logoutStaff]);

  return (
    <Flex
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
    >
      {/* LEFT SIDEBAR */}
      <Sidebar />
      <Flex
        width={{ base: "full", lg: "85%" }}
        marginLeft={"auto"}
        transition={"0.3s ease all"}
        flexDir="column"
        bgColor="gray.100"
        minH="100vh"
        py={4}
        px={5}
        overflow="auto"
      >
        <Box p={4} mb={6} bgColor="white" shadow="md" rounded="base">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display={"flex"} alignItems="center" gap={4}>
              <Box display="flex" alignItems="center" gap={2}>
                <CiClock2 fontSize={18} />
                <Heading fontSize="lg">
                  {new Date().toLocaleDateString("tr-TR")} -{" "}
                  {new Date().toLocaleTimeString("tr-TR", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                  })}
                </Heading>
              </Box>

              <Box display="flex" alignItems="center" gap={3}>
                <MdCurrencyExchange />
                <Heading fontSize="lg">{Number(currency?.getCurrency).toFixed(2)}</Heading>
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <Heading textAlign="right" fontSize="lg" mr={1}>
                {staff?.name}
              </Heading>
              <Badge mr={3} colorScheme="red">
                {staff?.role}
              </Badge>
              <Avatar size="sm" name={staff?.name || undefined}>
                <AvatarBadge borderColor="papayawhip" bg="green.500" boxSize="1.25em" />
              </Avatar>
            </Box>
          </Box>
        </Box>
        {children}
      </Flex>
    </Flex>
  );
}

export default Layout;
