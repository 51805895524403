import {
  UpdateOneCreatableMutationVariables,
  useCreatableQuery,
  useCreateOneStaffLogMutation,
  useUpdateOneCreatableMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";
import { useStaffStore } from "../../store/staff";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateMutation] = useUpdateOneCreatableMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const { loading, error, data } = useCreatableQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to="/creatable" replace />;
  }

  const handleUpdate = async (data: UpdateOneCreatableMutationVariables, previousParam: string) => {
    try {
      await updateMutation({
        variables: {
          ...data,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Material id: ${id} by ${staff?.name}`,
              },
              tableName: "ProducibleMaterial",
            },
          },
        }),
      ]);

      return toast({
        status: "success",
        title: "Successful",
        description: "Material updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/creatable?material=${previousParam}`);
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return <Form actionType="UPDATE" data={data?.creatable} id={id} handleUpdate={handleUpdate} />;
};

export default Update;
