import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import All from "./All";
import Detail from "./Detail";
import { useStaffStore } from "../../store/staff";
import { StaffRoleType } from "../../generated/graphql";

const Order = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();
  const { staff } = useStaffStore();
  const navigate = useNavigate();
  
  useEffect(() => {
    const isAdminOrSuperAdmin = staff?.role === StaffRoleType.ADMIN || staff?.role === StaffRoleType.SUPERADMIN;
    if (!isAdminOrSuperAdmin) {
      navigate("/");
    }
  }, [staff]);

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "detail":
        setComponent(<Detail id={pathname[3]} />);
        break;
      default:
        setComponent(<All />);
    }
  }, [location]);

  return component;
};

export default Order;
