import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FC } from "react";
import { FcList } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../components/ui/PageLayout";
import Form from "./Form";

const Create: FC = () => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Create Blog
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcList />}
            aria-label="All Items"
            onClick={() => navigate("/blog")}
          />
        </Flex>
      </Flex>
      <Form actionType="CREATE" />
    </PageLayout>
  );
};

export default Create;
