import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import {
  useCreateOneStaffLogMutation,
  useDeleteOneRingColorMutation,
  useDeleteOneRingStoneShapeMutation,
  useGetRingStoneShapesQuery,
  useRingColorsQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const {
    data: shapes,
    loading,
    error,
    refetch: refetchShapes,
  } = useGetRingStoneShapesQuery({
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();
  const [deleteShape, { loading: loadingDeleteShape }] = useDeleteOneRingStoneShapeMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Ring Shapes
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetchShapes();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "REFETCH",
                        message: `Refetched Ring Shapes by ${staff?.name}`,
                      },
                      tableName: "RingColors",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton
            to={`/shape/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetchShapes()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th textAlign="center">Name</Th>
            <Th textAlign="center">In Stone Count</Th>
            <Th textAlign="center">Created At</Th>
            <Th textAlign="center">Updated At</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {shapes &&
            shapes.ringStoneShapes.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
             
                <Td textAlign="center">{item.shape}</Td>
                <Td textAlign="center">{item._count?.productRingStone}</Td>
                <Td textAlign="center">{new Date(item.createdAt).toLocaleString()}</Td>
                <Td textAlign="center">{new Date(item.updatedAt).toLocaleString()}</Td>
                <Td textAlign="right">
                  <Flex justifyContent={"end"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        onClick={() => {
                          navigate("/shape/update/" + item.id);
                        }}
                      />
                    </Box>

                    <Box mx={2}>
                      <DeleteItem
                        id={item.id}
                        loading={false}
                        title={item.shape}
                        handleDelete={async () => {
                          if (loadingDeleteShape) {
                            return;
                          }
                          try {
                            await deleteShape({
                              variables: {
                                where: {
                                  id: item.id,
                                },
                              },
                            });

                            await Promise.allSettled([
                              createLog({
                                variables: {
                                  data: {
                                    staff: {
                                      connect: {
                                        id: staff?.id as string,
                                      },
                                    },
                                    json: {
                                      action: "DELETE",
                                      message: `Deleted Ring Shape id: ${item.id} by ${staff?.name}`,
                                    },
                                    tableName: "RingColor",
                                  },
                                },
                              }),
                            ]);

                            toast({
                              status: "success",
                              title: "Success",
                              description: "Ring Shape was deleted",
                              duration: 1000,
                            });
                            refetchShapes();
                          } catch (error: any) {
                            toast({
                              status: "error",
                              title: "Error",
                              description: error?.message || "Something went wrong",
                              duration: 1000,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
