import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Select, useToast } from "@chakra-ui/react";
import PageLayout from "../../components/ui/PageLayout";
import axios from "axios";
import { useStaffStore } from "../../store/staff";
import { useCreateOneStaffLogMutation } from "../../generated/graphql";

const Create = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const name = formData.get("name");
    const value = formData.get("value");
    const type = formData.get("type");

    try {
      await axios.post("https://capi.cr.jwlbg.de/api/configurator", {
        name: name,
        value: value,
        type: type,
      });

      toast({
        status: "success",
        title: "Successful",
        description: "Configurator added",
        duration: 3000,
        onCloseComplete: () => {
          navigate("/configurator");
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "CREATE",
                message: `Created Configurator Item ${name} by ${staff?.name}`,
              },
              tableName: "Configurator",
            },
          },
        }),
      ]);
    } catch (error) {
      toast({
        status: "error",
        title: "Error",
        description: "Something went wrong",
        duration: 3000,
      });
    }
  };

  return (
    <PageLayout>
      <form onSubmit={handleSubmit}>
        <Input name="name" placeholder="name" mb={5} />
        <Input name="value" placeholder="value" mb={5} />
        <Select name="type" placeholder="Type">
          <option value="cost">cost</option>
          <option value="vat">vat</option>
          <option value="profit">profit</option>
          <option value="price">price</option>
          <option value="profil">profil</option>
        </Select>
        <Button type="submit" colorScheme="green" mt={6}>
          Save
        </Button>
      </form>
    </PageLayout>
  );
};

export default Create;
