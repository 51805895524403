import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Heading,
  FormHelperText,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  ModalHeader,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  ModalCloseButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreateOneCreatableMutationVariables,
  RingStoneCaratInfo,
  RingStoneCaratQuery,
  UpdateOneCreatableMutationVariables,
  useCreateOneRingStoneCaratMutation,
  useUpdateOneRingStoneCaratMutation,
  useUpdateOneRingStoneCaratInfoMutation,
  useCreateOneRingStoneCaratInfoMutation,
  CreateManyRingStoneCaratInfoMutationVariables,
  useCreateManyRingStoneCaratInfoMutation,
  useDeleteOneRingStoneCaratInfoMutation,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { FcEditImage, FcList, FcPlus, FcRefresh } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import { RiFileExcel2Line } from "react-icons/ri";
import * as xlsx from "xlsx";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreate?: (data: CreateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  handleUpdate?: (data: UpdateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  id?: string;
  data?: RingStoneCaratQuery["ringStoneCarat"];
  actionType: "CREATE" | "UPDATE";
  refetchUpdatedCarat?: any;
}


const Form: React.FC<Props> = ({ id, data, actionType, refetchUpdatedCarat }) => {
  const toast = useToast();
  const [value, setValue] = useState<string>(() => {
    return data?.carat || ("" as string);
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();
  const [createCarat, { loading: createCaratLoading }] = useCreateOneRingStoneCaratMutation();
  const [updateCarat, { loading: updateCaratLoading }] = useUpdateOneRingStoneCaratMutation();
  const [selected, setSelected] = useState<Partial<RingStoneCaratInfo> | null>(null);
  const [updateCaratInfo, { loading: loadingUpdateCaratInfo }] = useUpdateOneRingStoneCaratInfoMutation();
  const [createCaratInfo, { loading: loadingCreateCaratInfo }] = useCreateOneRingStoneCaratInfoMutation();
  const [createManyCaratInfo, { loading: loadingCreateManyCaratInfo }] = useCreateManyRingStoneCaratInfoMutation();
  const [deleteCaratInfo, { loading: loadingDeleteCaratInfo }] = useDeleteOneRingStoneCaratInfoMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (createCaratLoading || updateCaratLoading) return;
    if (value.trim().length === 0) {
      return toast({
        status: "error",
        title: "Error",
        description: "Carat is required",
        duration: 1000,
      });
    }


    try {
      if (actionType === "CREATE") {
        const createdCarat = await createCarat({
          variables: {
            data: {
              carat: value,
            },
          },
        });
        toast({
          status: "success",
          title: "Successful",
          description: "Carat created successfully!",
          duration: 1000,
          onCloseComplete: () => {
            if (typeof refetchUpdatedCarat === "function") {
              refetchUpdatedCarat();
            }
          },
        });
        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Carat ${value} by ${staff?.name}`,
                },
                tableName: "Carat",
              },
            },
          }),
        ]);
        navigation(`/carat/update/${createdCarat.data?.createOneRingStoneCarat.id}`);
      }

      if (actionType === "UPDATE") {
        try {
          await updateCarat({
            variables: {
              where: {
                id: (data?.id! || "") as string,
              },
              data: {
                carat: {
                  set: value,
                },
              },
            },
          });
          toast({
            status: "success",
            title: "Success",
            description: "Ring size standart updated",
            duration: 1000,
            onCloseComplete: () => {
              //   return refetch();
            },
          });
          if (typeof refetchUpdatedCarat === "function") {
            refetchUpdatedCarat();
          }
          await Promise.allSettled([
            createLog({
              variables: {
                data: {
                  staff: {
                    connect: {
                      id: staff?.id as string,
                    },
                  },
                  json: {
                    action: "UPDATE",
                    message: `Updated Carat ${value} by ${staff?.name}`,
                  },
                  tableName: "Carat",
                },
              },
            }),
          ]);
        } catch (error: any) {
          toast({
            status: "error",
            title: "Error",
            description: error?.message || "Something went wrong",
            duration: 1000,
          });
        }
      }
    } catch (error: any) {
      toast({
        status: "error",
        title: "Error",
        description: error?.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  // const importExcelData = useCallback(() => {
  //   if (!data || !excelFile || actionType !== "UPDATE" || loadingCreateManyCaratInfo) {
  //     return;
  //   }

  //   const handleSuccess = () => {
  //     toast({
  //       status: "success",
  //       title: "Success",
  //       description: "Carat info imported successfully!",
  //       duration: 1000,
  //     });
  //     if (typeof refetchUpdatedCarat === "function") {
  //       refetchUpdatedCarat();
  //     }
  //   };

  //   try {
  //     const reader = new FileReader();
  //     reader.readAsArrayBuffer(excelFile);

  //     reader.onload = (e) => {
  //       toast({
  //         status: "info",
  //         title: "Info",
  //         description: "Importing excel data",
  //         duration: 1000,
  //       });
  //       const resultData = e.target?.result;
  //       const workbook = xlsx.read(resultData, { type: "buffer" });
  //       const wsname = workbook.SheetNames[0];
  //       const ws = workbook.Sheets[wsname];
  //       const jsonData = xlsx.utils.sheet_to_json(ws);

  //       if (jsonData.length === 0) {
  //         toast({
  //           status: "error",
  //           title: "Error",
  //           description: "Excel file is empty",
  //           duration: 1000,
  //         });
  //         return;
  //       }

  //       const mappedJson = jsonData.map((item: any) => ({
  //         color: item.COLOR,
  //         IF: item.IF,
  //         VSS1: item.VSS1,
  //         VSS2: item.VSS2,
  //         VS1: item.VS1,
  //         VS2: item.VS2,
  //         SI1: item.SI1,
  //         SI2: item.SI2,
  //         SI3: item.SI3,
  //         I1: item.I1,
  //         I2: item.I2,
  //         I3: item.I3,
  //         ringStoneCaratId: data.id,
  //       }));

  //       if (mappedJson.length === 0) {
  //         return;
  //       }

  //       console.log("mappedJson ", mappedJson);

  //       const isColorsValid = mappedJson.every((item) => item.color && item.color.length > 0);
  //       if (!isColorsValid) {
  //         toast({
  //           status: "error",
  //           title: "Error",
  //           description: "Color's are not valid",
  //           duration: 1000,
  //         });
  //         return;
  //       }


  //       const hasColorDuplicates = data?.caratInfos.some((caratInfo) => {
  //         return mappedJson.some((item) => item.color === caratInfo.color);
  //       });

  //       if (hasColorDuplicates) {
  //         toast({
  //           status: "error",
  //           title: "Error",
  //           description: "Color's should be unique",
  //           duration: 1000,
  //         });
  //         return;
  //       }

  //       createManyCaratInfo({
  //         variables: {
  //           data: mappedJson.map((item) => ({
  //             color: item.color as string,
  //             IF: item.IF as string,
  //             VSS1: item.VSS1 as string,
  //             VSS2: item.VSS2 as string,
  //             VS1: item.VS1 as string,
  //             VS2: item.VS2 as string,
  //             SI1: item.SI1 as string,
  //             SI2: item.SI2 as string,
  //             SI3: item.SI3 as string,
  //             I1: item.I1 as string,
  //             I2: item.I2 as string,
  //             I3: item.I3 as string,
  //             ringStoneCaratId: item.ringStoneCaratId,
  //           })),
  //         },
  //       });

  //       handleSuccess();
  //     };

  //     reader.onerror = () => {
  //       toast({
  //         status: "error",
  //         title: "Error",
  //         description: "Failed to read the file",
  //         duration: 1000,
  //       });
  //     };
  //   } catch (error) {
  //     toast({
  //       status: "error",
  //       title: "Error",
  //       description: "Something went wrong",
  //       duration: 1000,
  //     });
  //   }
  //   setExcelFile(null);
  // }, [data, excelFile, loadingCreateManyCaratInfo, actionType, createManyCaratInfo, refetchUpdatedCarat]);

  useEffect(() => {
    if (data) {
      setValue(data.carat);
    }
  }, [data]);

  // useEffect(() => {
  //   if (excelFile) {
  //     importExcelData();
  //   }
  // }, [excelFile]);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Carat
        </Box>
        <Flex experimental_spaceX={4}>
          <Tooltip label="Go to All Carat">
            <IconButton to="/carat" as={Link} icon={<FcList />} aria-label="All Items" />
          </Tooltip>
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={2} gap={10}>
          <Box
            mt={4}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Box>
              <FormControl>
                <FormLabel>Carat Value</FormLabel>
                <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder="Carat" type="number" />
                <FormHelperText>Carat should be unique!</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <Button colorScheme="green" type="submit" mt={6}>
                Save
              </Button>
            </Box>
          </Box>

          <Box>
            <Flex
              mb={6}
              justifyContent={"space-between"}
              alignItems="center"
              bgColor={"gray.100"}
              padding={2}
              rounded="md"
            >
              <Box fontSize={"18px"} fontWeight="bold">
                Carat Info
              </Box>
              {/**<Flex experimental_spaceX={4}>
                <IconButton to="/creatable" as={Link} icon={<FcList />} aria-label="All Items" />
              </Flex> */}
              <Flex gap={1}>
                <Tooltip label="Add Carat Info" aria-label="Add Carat Info">
                  <IconButton
                    icon={<FcPlus />}
                    aria-label="Add Item"
                    onClick={() => {
                      onOpen();
                    }}
                    isDisabled={!data}
                  />
                </Tooltip>
                <Tooltip label="Refetch Carat Info" aria-label="Refetch Carat Info">
                  <IconButton
                    icon={<FcRefresh />}
                    aria-label="Refetch Query"
                    onClick={() => {
                      if (typeof refetchUpdatedCarat === "function") {
                        refetchUpdatedCarat();
                      }
                    }}
                    isDisabled={!data}
                  />
                </Tooltip>
                <Tooltip label="Export Excel Data">
                  <IconButton
                    icon={<RiFileExcel2Line />}
                    aria-label="Export Excel Data"
                    color="purple.500"
                    isDisabled={!data}
                    onClick={() => {
                      if (!data?.caratInfos) return;
                      if (!Array.isArray(data.caratInfos)) return;
                      if (data.caratInfos.length === 0) return;
                      const mappedData = data.caratInfos.map((item) => ({
                        COLOR: item.color,
                        IF: item.IF,
                        VSS1: item.VSS1,
                        VSS2: item.VSS2,
                        VS1: item.VS1,
                        VS2: item.VS2,
                        SI1: item.SI1,
                        SI2: item.SI2,
                        SI3: item.SI3,
                        I1: item.I1,
                        I2: item.I2,
                        I3: item.I3,
                      }));

                      const ws = xlsx.utils.json_to_sheet(mappedData);
                      const wb = xlsx.utils.book_new();
                      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
                      xlsx.writeFileXLSX(wb, "carat-info.xlsx");
                    }}
                  />
                </Tooltip>
                {/* <Tooltip label="Import Excel Data">
                  <IconButton
                    icon={<RiFileExcel2Line />}
                    color="green.500"
                    aria-label="Import Excel Data"
                    isDisabled={!data}
                    onClick={(e) => {
                      if (loadingCreateManyCaratInfo) return;
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                  />
                </Tooltip> */}
                <Input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setExcelFile(file);
                    }
                  }}
                  opacity={0}
                  width={0}
                  height={0}
                  position="absolute"
                  zIndex={-1}
                  disabled={loadingCreateManyCaratInfo}
                />
              </Flex>
            </Flex>
            {actionType === "UPDATE" && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Color</Th>
                    <Th>IF</Th>
                    <Th>VVS1</Th>
                    <Th>VVS2</Th>
                    <Th>VS1</Th>
                    <Th>VS2</Th>
                    <Th>SI1</Th>
                    <Th>SI2</Th>
                    <Th>SI3</Th>
                    <Th>I1</Th>
                    <Th>I2</Th>
                    <Th>I3</Th>
                    <Th textAlign="center">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.caratInfos?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.color}</Td>
                      <Td>{item.IF}</Td>
                      <Td>{item.VSS1}</Td>
                      <Td>{item.VSS2}</Td>
                      <Td>{item.VS1}</Td>
                      <Td>{item.VS2}</Td>
                      <Td>{item.SI1}</Td>
                      <Td>{item.SI2}</Td>
                      <Td>{item.SI3}</Td>
                      <Td>{item.I1}</Td>
                      <Td>{item.I2}</Td>
                      <Td>{item.I3}</Td>
                      <Td>
                        <Flex justifyContent={"center"}>
                          <Box mx={2}>
                            <IconButton
                              aria-label="Edit"
                              icon={<FcEditImage />}
                              onClick={() => {
                                const copyOfItem = { ...item };
                                delete copyOfItem?.__typename;
                                delete copyOfItem?.createdAt;
                                delete copyOfItem?.updatedAt;
                                setSelected(copyOfItem);
                                onOpen();
                              }}
                            />
                          </Box>

                          <Box mx={2}>
                            <DeleteItem
                              id={item.id as string}
                              loading={false}
                              title={`${item.color}`}
                              handleDelete={async () => {
                                if (loadingDeleteCaratInfo) return;
                                try {
                                  await deleteCaratInfo({
                                    variables: {
                                      where: {
                                        id: item.id,
                                      },
                                    },
                                  });
                                  if (typeof refetchUpdatedCarat === "function") {
                                    refetchUpdatedCarat();
                                  }
                                  toast({
                                    status: "success",
                                    title: "Success",
                                    description: "Carat info deleted successfully!",
                                    duration: 1000,
                                  });
                                } catch (error: any) {
                                  toast({
                                    status: "error",
                                    title: "Error",
                                    description: "Something went wrong",
                                    duration: 1000,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </SimpleGrid>
      </form>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelected(null);
          onClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size={"md"}>{!selected?.id ? "Create" : "Update"} Carat Info</Heading>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody pb={5}>
            <Stack as="form" gap={5} pb={8}>
              <SimpleGrid columns={2} gap={5}>
                <FormControl isRequired>
                  <FormLabel>Color</FormLabel>
                  <Input
                    value={selected?.color || ""}
                    placeholder="Color"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        color: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>IF</FormLabel>
                  <Input
                    value={selected?.IF || ""}
                    placeholder="IF"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        IF: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>VVS1</FormLabel>
                  <Input
                    value={selected?.VSS1 || ""}
                    placeholder="VVS1"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        VSS1: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>VVS2</FormLabel>
                  <Input
                    value={selected?.VSS2 || ""}
                    placeholder="VVS2"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        VSS2: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>VS1</FormLabel>
                  <Input
                    value={selected?.VS1 || ""}
                    placeholder="VS1"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        VS1: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>VS2</FormLabel>
                  <Input
                    value={selected?.VS2 || ""}
                    placeholder="VS2"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        VS2: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>SI1</FormLabel>
                  <Input
                    value={selected?.SI1 || ""}
                    placeholder="SI1"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        SI1: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>SI2</FormLabel>
                  <Input
                    value={selected?.SI2 || ""}
                    placeholder="SI2"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        SI2: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>SI3</FormLabel>
                  <Input
                    value={selected?.SI3 || ""}
                    placeholder="SI3"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        SI3: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>I1</FormLabel>
                  <Input
                    value={selected?.I1 || ""}
                    placeholder="I1"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        I1: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>I2</FormLabel>
                  <Input
                    value={selected?.I2 || ""}
                    placeholder="I2"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        I2: e.target.value,
                      });
                    }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>I3</FormLabel>
                  <Input
                    value={selected?.I3 || ""}
                    placeholder="I3"
                    type="text"
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        I3: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </SimpleGrid>
            </Stack>

            <Button
              mt={4}
              colorScheme={"green"}
              onClick={async () => {
                if (loadingUpdateCaratInfo || loadingCreateCaratInfo) return;
                if (!selected) return;
                if (!selected?.color) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description: "Color is required",
                    duration: 1000,
                  });
                }
                const selectedProps = {
                  color: selected?.color,
                  IF: selected?.IF,
                  VSS1: selected?.VSS1,
                  VSS2: selected?.VSS2,
                  VS1: selected?.VS1,
                  VS2: selected?.VS2,
                  SI1: selected?.SI1,
                  SI2: selected?.SI2,
                  SI3: selected?.SI3,
                  I1: selected?.I1,
                  I2: selected?.I2,
                  I3: selected?.I3,
                };
                const values = Object.values(selectedProps!);
                if (values.length < 12) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description: "All fields are required",
                    duration: 1000,
                  });
                }

                const removedColor = values.filter((value) => value !== selected?.color);


                if (selected?.id) {
                  try {
                    await updateCaratInfo({
                      variables: {
                        where: {
                          id: selected?.id,
                        },
                        data: {
                          color: {
                            set: selected?.color!,
                          },
                          IF: {
                            set: selected?.IF!,
                          },
                          VSS1: {
                            set: selected?.VSS1!,
                          },
                          VSS2: {
                            set: selected?.VSS2!,
                          },
                          VS1: {
                            set: selected?.VS1!,
                          },
                          VS2: {
                            set: selected?.VS2!,
                          },
                          SI1: {
                            set: selected?.SI1!,
                          },
                          SI2: {
                            set: selected?.SI2!,
                          },
                          SI3: {
                            set: selected?.SI3!,
                          },
                          I1: {
                            set: selected?.I1!,
                          },
                          I2: {
                            set: selected?.I2!,
                          },
                          I3: {
                            set: selected?.I3!,
                          },
                        },
                      },
                    });
                    if (typeof refetchUpdatedCarat === "function") {
                      refetchUpdatedCarat();
                    }

                    toast({
                      status: "success",
                      title: "Success",
                      description: "Carat info updated successfully!",
                      duration: 1000,
                    });
                    onClose();
                  } catch (error: any) {
                    toast({
                      status: "error",
                      title: "Error",
                      description: "Something went wrong",
                      duration: 1000,
                    });
                  }
                } else {
                  try {
                    await createCaratInfo({
                      variables: {
                        data: {
                          color: selected?.color,
                          IF: selected?.IF,
                          VSS1: selected?.VSS1,
                          VSS2: selected?.VSS2,
                          VS1: selected?.VS1,
                          VS2: selected?.VS2,
                          SI1: selected?.SI1,
                          SI2: selected?.SI2,
                          SI3: selected?.SI3,
                          I1: selected?.I1,
                          I2: selected?.I2,
                          I3: selected?.I3,
                          ringStoneCarat: {
                            connect: {
                              id: data?.id,
                            },
                          },
                        },
                      },
                    });
                    toast({
                      status: "success",
                      title: "Success",
                      description: "Carat info updated successfully!",
                      duration: 1000,
                    });
                    if (typeof refetchUpdatedCarat === "function") {
                      refetchUpdatedCarat();
                    }
                    onClose();
                  } catch (error: any) {
                    toast({
                      status: "error",
                      title: "Error",
                      description: "Something went wrong",
                      duration: 1000,
                    });
                  }
                }
              }}
            >
              {selected?.id ? "Update" : "Create"}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default Form;
