import {
  Box,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { FcFullTrash } from "react-icons/fc";

interface Props {
  title: string;
  id: string;
  size?: string;
  handleDelete: (id: string) => Promise<any>;
  loading: boolean;
  disabled?: boolean;
}
const DeleteItem: React.VFC<Props> = ({
  title,
  id,
  size = "md",
  handleDelete,
  loading,
  disabled
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteItem = async (id: string) => {
    await handleDelete(id);
    onClose();
  };
  return (
    <Box>
      <IconButton
        size={size}
        icon={<FcFullTrash />}
        aria-label="Delete Item"
        onClick={onOpen}
        disabled={disabled}
        opacity={disabled ? 0.5 : 1}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deletion Process</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You are deleting the item named {title}. This operation cannot be undone, are
            you sure?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={loading}
              colorScheme={"red"}
              onClick={() => deleteItem(id)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteItem;
