import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast, Tooltip, Input } from "@chakra-ui/react";
import {
  SortOrder,
  useCreateOneRingSizeMutation,
  useCreateOneRingSizeStandartMutation,
  useCreateOneStaffLogMutation,
  useDeleteOneRingSizeStandartMutation,
  useRingSizeStandartsQuery,
  useUpdateOneRingSizeMutation,
  useUpdateOneRingSizeStandartMutation,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { RiFileExcel2Line } from "react-icons/ri";
import { useCallback, useEffect, useRef, useState } from "react";
import * as xlsx from "xlsx";

type ImportData = {
  CATEGORY_NAME: string;
  NAME: string;
  VALUE: string;
  DIAMETER_MM: string;
  CIRCUMFERENCE_MM: string;
  IS_DEFAULT: string;
};

const All: React.VFC = () => {
  const { data, loading, error, refetch } = useRingSizeStandartsQuery({
    variables: {
      orderBy: {
        categoryName: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  const [deleteRingSizeStandart, { loading: deleteRingSizeStandartLoading }] = useDeleteOneRingSizeStandartMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [createSize] = useCreateOneRingSizeMutation();
  const [updateSize] = useUpdateOneRingSizeMutation();


  const importExcelData = () => {
    if (!excelFile) return;

    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(excelFile);

      reader.onload = async (e) => {
        toast({
          status: "info",
          title: "Info",
          description: "Importing excel data",
          duration: 1000,
        });
        const resultData = e.target?.result;
        const workbook = xlsx.read(resultData, { type: "buffer" });
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        const jsonData: Array<ImportData> = xlsx.utils.sheet_to_json(ws);
        jsonData.map(async (item) => {
          const ringSizeStandart = data?.ringSizeStandarts?.find(
            (standart) => standart.categoryName == item.CATEGORY_NAME && standart.name == item.NAME
          );
          const isExist = ringSizeStandart?.values.find((val) => val.value == item.VALUE);
          if (ringSizeStandart && isExist) {
            await updateSize({
              variables: {
                where: {
                  id: isExist.id,
                },
                data: {
                  circumferenceMM: {
                    set: String(item.CIRCUMFERENCE_MM),
                  },
                  diameterMM: {
                    set: String(item.DIAMETER_MM),
                  },
                  value: {
                    set: String(item.VALUE),
                  },
                },
              },
            });
          } else {
            await createSize({
              variables: {
                data: {
                  circumferenceMM: String(item.CIRCUMFERENCE_MM),
                  diameterMM: String(item.DIAMETER_MM),
                  value: String(item.VALUE),
                  ringSizeStandart: {
                    connectOrCreate: {
                      where: {
                        categoryName_name: {
                          categoryName: String(item.CATEGORY_NAME),
                          name: String(item.NAME),
                        },
                      },
                      create: {
                        categoryName: String(item.CATEGORY_NAME),
                        name: String(item.NAME),
                      },
                    },
                  },
                },
              },
            });
          }
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "IMPORT",
                  message: `Imported Product Sizes Standart by ${staff?.name}`,
                },
                tableName: "RingSizeStandart",
              },
            },
          }),
        ]);

        await refetch();
        toast({
          status: "success",
          title: "Success",
          description: "Size imported successfully!",
          duration: 1000,
        });
      };

      reader.onerror = () => {
        toast({
          status: "error",
          title: "Error",
          description: "Failed to read the file",
          duration: 1000,
        });
      };
    } catch (error) {
      toast({
        status: "error",
        title: "Error",
        description: "Something went wrong",
        duration: 1000,
      });
    }

    setExcelFile(null);
    navigate("/ring-size-standarts", {
      replace: true,
    });
  };

  const exportData = () => {
    /* iwant to data format at belove */
    /**
     * CATEGORY_NAME | NAME | VALUE | DIAMETER_MM | CIRCUMFERENCE_MM
     * RING | EU | 40 | 12.7 | 40.0
     * RING | EU | 41 | 13.1 | 41.0
     * 
     */
    const exportData = data?.ringSizeStandarts.map((standart) => {
      return standart.values.map((val) => {
        return {
          CATEGORY_NAME: standart.categoryName,
          NAME: standart.name,
          VALUE: val.value,
          DIAMETER_MM: val.diameterMM,
          CIRCUMFERENCE_MM: val.circumferenceMM,
        };
      });
    }).flatMap((val) => val);
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(exportData as any);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "RingSizeStandarts");
    xlsx.writeFile(wb, "RingSizeStandarts.xlsx");
  
  }

  useEffect(() => {
    if (excelFile) {
      importExcelData();
    }
  }, [excelFile]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Sizes Standarts
        </Box>
        <Flex experimental_spaceX={4}>
          <Tooltip label="Refetch Data">
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={async () => {
                await refetch();
                await Promise.allSettled([
                  createLog({
                    variables: {
                      data: {
                        staff: {
                          connect: {
                            id: staff?.id as string,
                          },
                        },
                        json: {
                          action: "REFETCH",
                          message: `Refetched Ring Sizes Standarts by ${staff?.name}`,
                        },
                        tableName: "RingSizeStandart",
                      },
                    },
                  }),
                ]);
              }}
            />
          </Tooltip>

          <Tooltip label="Create">
            <IconButton
              to={`/ring-size-standarts/create`}
              as={Link}
              icon={<FcPlus />}
              aria-label="Add Item"
              onClick={() => refetch()}
            />
          </Tooltip>

          <Tooltip label="Export Data">
            <IconButton
              aria-label="Export Data"
              icon={<RiFileExcel2Line />}
              color="purple.400"
              onClick={() => {
                exportData();
              }}
            />
          </Tooltip>

          <Tooltip label="Import Data">
            <IconButton
              aria-label="Import Data"
              icon={<RiFileExcel2Line />}
              color="green.400"
              onClick={() => {
                if (fileInputRef?.current) {
                  fileInputRef?.current.click();
                }
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th textAlign="center">Category</Th>
            <Th textAlign="center">Name</Th>
            <Th textAlign="center">Size Count</Th>
            <Th textAlign="center">In Products Count</Th>
            <Th textAlign="center">Created At</Th>
            <Th textAlign="center">Updated At</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.ringSizeStandarts.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td textAlign="center">{item.categoryName}</Td>
                <Td textAlign="center">{item.name}</Td>
                <Td textAlign="center">{item._count?.values}</Td>
                <Td textAlign="center">{item._count?.productRingSizeStandarts}</Td>
                <Td textAlign="center">{new Date(item.createdAt).toLocaleString()}</Td>
                <Td textAlign="center">{new Date(item.updatedAt).toLocaleString()}</Td>
                <Td textAlign="right">
                  <Flex justifyContent={"end"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        onClick={() => {
                          navigate("/ring-size-standarts/update/" + item.id);
                        }}
                      />
                    </Box>

                    <Box mx={2}>
                      <DeleteItem
                        id={item.id}
                        loading={false}
                        title={item.name}
                        handleDelete={async () => {
                          // handleDeleteRingSize
                          if (deleteRingSizeStandartLoading) {
                            return;
                          }
                          try {
                            await deleteRingSizeStandart({
                              variables: {
                                where: {
                                  id: item.id,
                                },
                              },
                            });

                            await Promise.allSettled([
                              createLog({
                                variables: {
                                  data: {
                                    staff: {
                                      connect: {
                                        id: staff?.id as string,
                                      },
                                    },
                                    json: {
                                      action: "DELETE",
                                      message: `Deleted Ring Size Standart id: ${item.id} by ${staff?.name}`,
                                    },
                                    tableName: "RingSizeStandart",
                                  },
                                },
                              }),
                            ]);

                            toast({
                              status: "success",
                              title: "Success",
                              description: "Ring Size Standart deleted",
                              duration: 1000,
                            });
                            refetch();
                          } catch (error: any) {
                            toast({
                              status: "error",
                              title: "Error",
                              description: error?.message || "Something went wrong",
                              duration: 1000,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      <Input
        ref={fileInputRef}
        type="file"
        opacity={0}
        position="absolute"
        top={0}
        left={0}
        width={0}
        height={0}
        overflow={"hidden"}
        accept=".xlsx, .xls"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            setExcelFile(file);
          }
        }}
      />
    </PageLayout>
  );
};

export default All;
