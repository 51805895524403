import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FC } from "react";
import { FcList } from "react-icons/fc";
import PageLayout from "../../components/ui/PageLayout";
import Form from "./Form";
import { useGetBlogCategoryQuery } from "../../generated/graphql";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
};

const Update: FC<Props> = ({ id }) => {
  const { data, loading, error } = useGetBlogCategoryQuery({
    variables: {
      where: {
        id,
      },
    },
  });
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Create Blog Category
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcList />}
            aria-label="All Items"
            onClick={() => navigate('/blog-categories')}
          />
        </Flex>
      </Flex>
      <Box mt={4}>
        <Form
          actionType="UPDATE"
          descriptionsFromUpdate={Array.from(
            data?.getBlogCategory?.descriptions?.map((description) => ({
              title: description?.title,
              description: description?.description,
              language: description?.language,
              id: description?.id,
            })) || []
          )}
          updateId={data?.getBlogCategory?.id}
          updateStatus={data?.getBlogCategory?.status}
        />
      </Box>
    </PageLayout>
  );
};

export default Update;
