import React, { useCallback, useState, useEffect } from "react";
import { Flex, IconButton, Text, SimpleGrid, GridItem, Spinner } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { BsCardImage } from "react-icons/bs";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { useUploadFileMutation } from "../../generated/graphql";
import CloudinaryImage from "../CloudinaryImage";
import { FaTimes } from "react-icons/fa";

interface SortablePhotoProps {
  deletePhoto: any;
  photo: any;
}
interface SortablePhotosProps {
  items: any[];
  deletePhoto: any;
}

const SortablePhoto = SortableElement(({ photo, deletePhoto }: SortablePhotoProps) => {
  return (
    <GridItem position="relative" onClick={() => deletePhoto(photo)}>
      <IconButton
        position="absolute"
        bottom="0"
        right="0"
        size="sm"
        fontSize={12}
        colorScheme="red"
        aria-label="delete image"
        icon={<FaTimes onClick={() => deletePhoto(photo)} />}
        onClick={() => deletePhoto(photo)}
      />
      <CloudinaryImage publicId={photo} width={160} />
    </GridItem>
  );
});

const SortablePhotos = SortableContainer(({ deletePhoto, items }: SortablePhotosProps) => {
  return (
    <SimpleGrid columns={8} gap={3} mb="4">
      {items
        .filter((item) => item.length !== 0)
        .map((photo, index) => {
          return <SortablePhoto deletePhoto={deletePhoto} photo={photo} key={index} index={index} />;
        })}
    </SimpleGrid>
  );
});

interface IUploadDropzone {
  images: string | string[];
  setImages: any;
  isSingle?: boolean;
}

const UploadDropzone: React.VFC<IUploadDropzone> = ({ images, setImages, isSingle = false }) => {
  const [addedPhotos, setAddedPhotos] = useState<string | string[]>(() => {
    return isSingle ? (images ? images : "") : images ? images : [];
  });
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const deletePhoto = (fileName: any) => {
    if (isSingle) {
      setAddedPhotos("");
    } else {
      const photos = (addedPhotos as string[]).filter((photo) => photo !== fileName);
      setAddedPhotos(() => [...photos]);
    }
  };

  useEffect(() => {
    setImages(addedPhotos);
  }, [addedPhotos]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      acceptedFiles.map(async (a: any) => {
        try {
          const result = await uploadPhoto({
            variables: {
              file: a,
            },
          });

          if (isSingle) {
            setAddedPhotos(result.data?.uploadFile.file);
          } else {
            setAddedPhotos((prevState) => [...(prevState as string[]), result.data?.uploadFile.file]);
          }
        } catch (error) {}
      });
    },
    [isSingle]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex === newIndex) return;
    if (!isSingle) {
      const newOrder = arrayMoveImmutable(addedPhotos as string[], oldIndex, newIndex);
      setAddedPhotos(() => [...newOrder]);
    }
  };

  return (
    <>
      {addedPhotos && (
        <SortablePhotos
          deletePhoto={deletePhoto}
          items={isSingle ? [addedPhotos as any] : (addedPhotos as string[])}
          axis="xy"
          onSortEnd={onSortEnd}
        />
      )}
      <Flex
        w="100%"
        h="150px"
        color="black"
        border="1px solid #ccc"
        justifyContent="center"
        alignItems="center"
        rounded="sm"
        _hover={{ cursor: "pointer" }}
        {...getRootProps()}
      >
        <Flex flexDirection="column" alignItems="center">
          {uploadLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          ) : isDragActive ? (
            <Text fontWeight="bold">Bırak</Text>
          ) : (
            <>
              <div>
                <BsCardImage size="36" />
              </div>
              <Text fontWeight="bold" fontSize="medium">
              Add images by clicking or drag-and-drop
              </Text>
            </>
          )}
        </Flex>

        <input {...getInputProps({ multiple: !isSingle })} />
      </Flex>
    </>
  );
};

export default UploadDropzone;
