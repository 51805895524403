import { useRef, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  CircularProgress,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import validator from "validator";

import {
  CreateOneSliderMutationVariables,
  LanguageOptions,
  SliderQuery,
  UpdateOneSliderMutationVariables,
  useCreateOneStaffLogMutation,
  useProductsQuery,
  useUploadFileMutation,
} from "../../generated/graphql";
import PageLayout from "../../components/ui/PageLayout";
import { useUpdateDescriptionMutation } from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";
import { DescriptionProperty } from "../../types/descriptionProperty";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";
import { descriptionToText } from "../../utils/descriptionToText";
import SkeletonGroup from "../../components/ui/SkeletonGroup";
import CloudinaryImage from "../../components/CloudinaryImage";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreate?: (data: CreateOneSliderMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateOneSliderMutationVariables) => Promise<any>;
  id?: string;
  data?: SliderQuery["slider"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [descriptions, setDescriptions] = useState<DescriptionProperty[]>(
    getLocalizedDescription({ descriptions: data?.descriptions })
  );
  const [updateDescriptionMutation, { loading: updateDescriptionLoading }] = useUpdateDescriptionMutation();
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [photo, setPhoto] = useState(data?.photo);
  const [productId, setProductId] = useState(data?.productId);

  const { data: productsData } = useProductsQuery();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handlePhotoInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      // setPhoto(file);
      const result = await uploadPhoto({
        variables: {
          file: file,
        },
      });
      setPhoto(result.data?.uploadFile.file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.EN)!.title)) {
      return toast({
        status: "error",
        description: "Title English is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.DE)!.title)) {
      return toast({
        status: "error",
        description: "Title German is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.ES)!.title)) {
      return toast({
        status: "error",
        description: "Title Spanish is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.FR)!.title)) {
      return toast({
        status: "error",
        description: "Title French is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.IT)!.title)) {
      return toast({
        status: "error",
        description: "Title Italian is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (validator.isEmpty(descriptions.find((d) => d.language === LanguageOptions.TR)!.title)) {
      return toast({
        status: "error",
        description: "Title Turkish is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (validator.isEmpty(photo ?? "") && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Image required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.EN)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.EN)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.EN)!.title),
                  language: LanguageOptions.EN,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.DE)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.DE)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.DE)!.title),
                  language: LanguageOptions.DE,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.ES)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.ES)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.ES)!.title),
                  language: LanguageOptions.ES,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.FR)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.FR)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.FR)!.title),
                  language: LanguageOptions.FR,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.IT)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.IT)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.IT)!.title),
                  language: LanguageOptions.IT,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.TR)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.TR)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.TR)!.title),
                  language: LanguageOptions.TR,
                },
              ],
            },
            photo: photo!,
            ...(productId && { product: { connect: { id: productId } } }),
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Slider by ${staff?.name}`,
                },
                tableName: "Slider",
              },
            },
          }),
        ]);
      }
      if (actionType === "UPDATE") {
        for await (const iterator of descriptions) {
          await updateDescriptionMutation({
            variables: {
              where: { id: iterator.descriptionId },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                slug: { set: createSlug(iterator.title ?? "") },
              },
            },
          });
        }

        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            photo: { set: photo },
            ...(productId ? { product: { connect: { id: productId } } } : { product: { disconnect: true } }),
          },
        });
        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "UPDATE",
                  message: `Updated Slider by ${staff?.name}`,
                },
                tableName: "Slider",
              },
            },
          }),
        ]);
      }
    } catch (error) {}
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Slider
        </Box>
        <Flex>
          <IconButton to="/slider" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <Input type="file" id="photo" display="none" ref={hiddenFileInput} onChange={handlePhotoInputChange} />
              {uploadLoading ? (
                <CircularProgress isIndeterminate display="block" />
              ) : (
                <>{!validator.isEmpty(photo ?? "") && <CloudinaryImage publicId={photo!} />}</>
              )}
              <Button colorScheme="blue" onClick={handleClick} mt={4}>
                Görsel seç
              </Button>
            </GridItem>
            <GridItem colSpan={2}>
              <Tabs>
                <TabList>
                  <Tab>EN</Tab>
                  <Tab>DE</Tab>
                  <Tab>ES</Tab>
                  <Tab>FR</Tab>
                  <Tab>IT</Tab>
                  <Tab>TR</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.EN)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.EN)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.EN)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.EN)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.DE)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.DE)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.DE)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.DE)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.ES)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.ES)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.ES)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.ES)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.FR)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.FR)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.FR)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.FR)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.IT)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.IT)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.IT)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.IT)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.TR)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.TR)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.TR)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.TR)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Select Product</FormLabel>
              {!productsData ? (
                <SkeletonGroup amount={1} />
              ) : (
                <ReactSelect
                  placeholder="Select Product"
                  options={productsData!.products.items.map((pp) => ({
                    value: pp.id,
                    label: descriptionToText(pp.descriptions, LanguageOptions.EN)?.title || "",
                  }))}
                  {...(actionType === "UPDATE" && {
                    ...{
                      value: productsData
                        ?.products!.items.map((p) => ({
                          label: descriptionToText(p.descriptions, LanguageOptions.EN)?.title || "",
                          value: p.id,
                        }))
                        .filter((c) => c.value === productId),
                    },
                  })}
                  isClearable
                  onChange={(e, p) => {
                    if (p.action === "clear") {
                      setProductId(undefined);
                    }
                    setProductId(e && e.value ? e.value : undefined);
                  }}
                />
              )}
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme="green"
            type="submit"
            mt={6}
            isDisabled={updateDescriptionLoading}
            isLoading={updateDescriptionLoading}
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
