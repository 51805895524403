import {
  UpdateProductMutationVariables,
  useProductQuery,
  useUpdateProductMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateMutation, { loading: updateLoading }] = useUpdateProductMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useProductQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to="/product" replace />;
  }

  const handleUpdate = async (data: UpdateProductMutationVariables) => {
    try {
      await updateMutation({
        variables: {
          ...data,
        },
      });
      navigation(`/product`);

      return toast({
        status: "success",
        title: "Successful",
        description: "Product updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/product`);
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      actionType="UPDATE"
      data={data?.product}
      id={id}
      handleUpdate={handleUpdate}
      loading={updateLoading}
    />
  );
};

export default Update;
