import { Fragment, useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  chakra,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Stack,
  Tooltip,
  FormControl,
} from "@chakra-ui/react";
import validator from "validator";
import { FcList, FcPlus } from "react-icons/fc";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreatableType,
  CreateProductCustomMutationVariables,
  LanguageOptions,
  ProductQuery,
  RingStoneCaratQuality,
  UpdateProductMutationVariables,
  useCalculatePriceMutation,
  useCategoriesQuery,
  useCreatablesQuery,
  useCreateOneStaffLogMutation,
  useGetSizeCategoriesQuery,
  useRingColorsQuery,
  useRingSizeStandartsQuery,
  useRingStoneCaratsQuery,
  useRingStoneShapesQuery,
  useStylesQuery,
  useUpdateDescriptionMutation,
} from "../../generated/graphql";
import { createSlug } from "../../utils/slug";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import SkeletonGroup from "../../components/ui/SkeletonGroup";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";
import { DescriptionProperty } from "../../types/descriptionProperty";
import { FaCalculator, FaTrash } from "react-icons/fa";
import { $enum } from "ts-enum-util";
import { useStaffStore } from "../../store/staff";
import { updatedDiff } from "deep-object-diff";

interface Props {
  handleCreate?: (data: CreateProductCustomMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateProductMutationVariables) => Promise<any>;
  id?: string;
  data?: ProductQuery["product"];
  actionType: "CREATE" | "UPDATE";
  loading: boolean;
}

const stoneNamer = (stone: {
  quantity: number;
  carat: { label: string; value: string };
  color: { label: string; value: string };
  quality: string;
  shape: { label: string; value: string };
  price?: number | string;
}) => {
  return `${stone.quantity} x ${stone.carat.label}ct ${stone.quality}/${stone.color.label}`;
};

const caratQualitiesEnum = Array.from($enum(RingStoneCaratQuality)).map((item) => item[0]);

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType, loading }) => {
  const { data: categoriesData, loading: categoriesLoading } = useCategoriesQuery({
    fetchPolicy: "network-only",
  });

  const { data: creatablesData, loading: creatablesLoading } = useCreatablesQuery({
    fetchPolicy: "network-only",
  });

  const { data: stylesData, loading: stylesLoading } = useStylesQuery({
    fetchPolicy: "network-only",
  });
  const { data: sizesStandarts, loading: sizesStandartsLoading } = useRingSizeStandartsQuery({
    fetchPolicy: "network-only",
  });
  const { data: carats } = useRingStoneCaratsQuery();
  const { data: shapes } = useRingStoneShapesQuery();

  const [updateDescriptionMutation, { loading: updateDescriptionLoading }] = useUpdateDescriptionMutation();
  const [calculatePrice] = useCalculatePriceMutation();
  // TITLE & DESCRIPTIONS

  const [descriptions, setDescriptions] = useState<DescriptionProperty[]>(
    getLocalizedDescription({ descriptions: data?.descriptions })
  );

  const [photos, setPhotos] = useState(data?.photos || []);
  const [status, setStatus] = useState(data?.status);
  const [sku, setSku] = useState(data?.sku || "");
  const [barcode, setBarcode] = useState(data?.barcode || "");
  const [styleId, setStyleId] = useState(data?.styleId || "");
  const [price, setPrice] = useState(data?.price || 0);

  const [weight, setWeight] = useState(data?.weight ?? 0);
  const [categoryId, setCategoryId] = useState(data?.categoryId);
  const [calculatedPrice, setCalculatedPrice] = useState<number | null>(null);

  const [materialId, setMaterialId] = useState<string>(() => {
    return data != null
      ? data?.creatables.filter((c) => c.creatable.type === CreatableType.MATERIAL).length > 0
        ? data.creatables.find((c) => c.creatable.type === CreatableType.MATERIAL)?.creatableId!
        : ""
      : "";
  });

  const [laborId, setLaborId] = useState(() => {
    return data != null
      ? data?.creatables.filter((c) => c.creatable.type === CreatableType.LABOR).length > 0
        ? data.creatables.find((c) => c.creatable.type === CreatableType.LABOR)?.creatableId!
        : ""
      : "";
  });

  const [colors, setColors] = useState<{ label: string; value: string }[]>(() => {
    return (
      data?.productRingColors.map((ringColor) => ({
        label: ringColor.color.descriptions.find((a) => a.language === "EN")?.title!,
        value: ringColor.color.id,
      })) || []
    );
  });
  const [sizes, setSizes] = useState<{ label: string; value: string }[]>(() => {
    return (
      Array.from(new Set(data?.productRingSizeStandarts.map((a) => a.ringSizeStandart.categoryName))).map(
        (standart) => ({
          label: standart,
          value: standart,
        })
      ) || []
    );
  });
  const [roundedPrice, setRoundedPrice] = useState<number | null>(null);
  const [priceMode, setPriceMode] = useState<"MANUAL" | "SYSTEM" | "SYSTEM-ROUNDED">(() => {
    if (data?.price === price) {
      return "MANUAL";
    } else if (data?.price === calculatedPrice) {
      return "SYSTEM";
    } else if (data?.price === roundedPrice) {
      return "SYSTEM-ROUNDED";
    }
    return "MANUAL";
  });

  const [stonesGroups, setStonesGroups] = useState<
    Array<{
      label: string;
      photos?: Array<string>;
      stones: Array<{
        quantity: number;
        carat: {
          label: string;
          value: string;
        };
        color: {
          label: string;
          value: string;
        };
        quality: string;
        shape: {
          label: string;
          value: string;
        };
        price?: number | string;
      }>;
      formStone: {
        name?: string;
        quantity: number;
        carat: {
          label: string;
          value: string;
        };
        color: {
          label: string;
          value: string;
        };
        quality: string;
        shape: {
          label: string;
          value: string;
        };
      };
    }>
  >([]);
  const { data: sizeCategories } = useGetSizeCategoriesQuery();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();
  const toast = useToast();
  const { data: ringColors } = useRingColorsQuery();

  const uniqueSizeCategories = Array.from(
    new Set(sizeCategories?.ringSizeStandarts.map((standart) => standart.categoryName))
  );
  const sizesIds =
    sizes && sizes?.length > 0
      ? sizeCategories?.ringSizeStandarts
          .filter((a) => {
            return sizes.some((b) => b.value === a.categoryName || b.label === a.categoryName);
          })
          .map((item) => item.id)
      : [];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const languages = [
      LanguageOptions.EN,
      LanguageOptions.DE,
      LanguageOptions.ES,
      LanguageOptions.FR,
      LanguageOptions.IT,
      LanguageOptions.TR,
    ];
    if (!descriptions.every((d) => !validator.isEmpty(d.title) && languages.includes(d.language as LanguageOptions))) {
      return toast({
        status: "error",
        description: "Please fill all title fields",
        title: "Error",
        duration: 1000,
      });
    }

    if (!categoryId) {
      return toast({
        status: "error",
        description: "Category is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!sku) {
      return toast({
        status: "error",
        description: "SKU is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (actionType === "CREATE") {
      try {
        if (stonesGroups && stonesGroups?.length > 0) {
          // check if any stone group empty or not
          if (stonesGroups.some((sg) => sg.stones.length === 0)) {
            return toast({
              status: "error",
              description: "Please add stones to stone groups",
              title: "Error",
              duration: 1000,
            });
          }

          if (
            stonesGroups &&
            stonesGroups.some((sg) =>
              sg.stones.some((s) => {
                return !s.carat.value || !s.color.value! || !s.quality || s.quantity <= 0 || !s.quantity;
              })
            )
          ) {
            return toast({
              status: "error",
              description: "Please fill all stone fields",
              title: "Error",
              duration: 1000,
            });
          }
        }

        const returned = await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.EN)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.EN)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.EN)!.title),
                  language: LanguageOptions.EN,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.DE)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.DE)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.DE)!.title),
                  language: LanguageOptions.DE,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.ES)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.ES)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.ES)!.title),
                  language: LanguageOptions.ES,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.FR)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.FR)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.FR)!.title),
                  language: LanguageOptions.FR,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.IT)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.IT)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.IT)!.title),
                  language: LanguageOptions.IT,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.TR)!.title,
                  description: descriptions.find((d) => d.language === LanguageOptions.TR)!.description,
                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.TR)!.title),
                  language: LanguageOptions.TR,
                },
              ],
            },
            sku,
            barcode,
            photos: {
              set: photos,
            },
            category: { connect: { id: categoryId } },
            creatableIds: [
              ...(materialId && materialId.length > 0 ? [materialId] : []),
              ...(laborId && laborId.length > 0 ? [laborId] : []),
            ],
            colorIds: colors && colors.length > 0 ? colors.map((c) => c.value) : [],
            ringSizeStandartIds: sizesIds || null,
            stoneGroups:
              stonesGroups?.map((stoneGroup) => ({
                photos: stoneGroup.photos,
                stones: stoneGroup.stones.map((stone) => ({
                  quantity: stone?.quantity,
                  carat: stone.carat.value,
                  color: stone.color.value,
                  quality: stone.quality as RingStoneCaratQuality,
                  shape: stone.shape.value,
                })),
              })) || undefined,
            weight: Number(weight),
            status,
            price:
              priceMode === "MANUAL"
                ? Number(price)
                : priceMode === "SYSTEM"
                ? calculatedPrice
                : Math.ceil(price! / 10) * 10 - 0.01,
            style: styleId
              ? {
                  connect: {
                    id: styleId,
                  },
                }
              : undefined,
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Product ${descriptions.find((a) => a.language === "EN")?.title} by ${staff?.name}`,
                  diffs: returned.data?.createProductCustom || {},
                },
                tableName: "Product",
              },
            },
          }),
        ]);
      } catch (error: any) {}
    }

    if (actionType === "UPDATE") {
      if (stonesGroups && Array.isArray(stonesGroups) && stonesGroups?.length > 0) {
        // check if any stone group empty or not
        if (stonesGroups.some((sg) => sg.stones.length === 0)) {
          return toast({
            status: "error",
            description: "Please add stones to stone groups",
            title: "Error",
            duration: 1000,
          });
        }

        if (
          stonesGroups &&
          stonesGroups.some((sg) =>
            sg.stones.some((s) => {
              return !s.carat.value || !s.color.value! || !s.quality || s.quantity <= 0 || !s.quantity;
            })
          )
        ) {
          return toast({
            status: "error",
            description: "Please fill all stone fields",
            title: "Error",
            duration: 1000,
          });
        }
      }

      for await (const iterator of descriptions) {
        const returnedDescriptions = await updateDescriptionMutation({
          variables: {
            where: { id: iterator.descriptionId },
            data: {
              title: { set: iterator.title ?? "" },
              description: { set: iterator.description ?? "" },
              slug: { set: createSlug(iterator.title ?? "") },
            },
          },
        });
      }

      await handleUpdate!({
        where: {
          id: id,
        },
        data: {
          photos: { set: photos },
          category: { connect: { id: categoryId } },
          creatableIds: [
            ...(materialId && materialId?.length > 0 ? [materialId] : []),
            ...(laborId && laborId?.length > 0 ? [laborId] : []),
          ],
          colorIds: colors && colors.length > 0 ? colors.map((c) => c.value) : [],
          ringSizeStandartIds: sizesIds || null,
          stoneGroups:
            (stonesGroups &&
              stonesGroups?.map((stoneGroup) => ({
                photos: stoneGroup.photos,
                stones: stoneGroup.stones.map((stone) => ({
                  quantity: stone?.quantity,
                  carat: stone.carat.value,
                  color: stone.color.value,
                  quality: stone.quality as RingStoneCaratQuality,
                  shape: stone.shape.value,
                })),
              }))) ||
            undefined,
          sku: {
            set: sku,
          },
          status: { set: status },
          weight: { set: parseFloat(weight).toFixed(2) },
          barcode: {
            set: barcode,
          },
          price:
            priceMode === "MANUAL"
              ? Number(price)
              : priceMode === "SYSTEM"
              ? calculatedPrice
              : Math.ceil(price! / 10) * 10 - 0.01,
          style: styleId
            ? {
                connect: {
                  id: styleId,
                },
              }
            : undefined,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Product ${descriptions.find((a) => a.language === "EN")?.title} by ${staff?.name}`,
                diffs: {
                  diff: updatedDiff(data as any, {
                    photos,
                    category: { connect: { id: categoryId } },
                    creatableIds: [
                      ...(materialId && materialId?.length > 0 ? [materialId] : []),
                      ...(laborId && laborId?.length > 0 ? [laborId] : []),
                    ],
                    colorIds: colors && colors.length > 0 ? colors.map((c) => c.value) : [],
                    ringSizeStandartIds: sizesIds || null,
                    stoneGroups:
                      (stonesGroups &&
                        stonesGroups?.map((stoneGroup) => ({
                          photos: stoneGroup.photos,
                          stones: stoneGroup.stones.map((stone) => ({
                            quantity: stone?.quantity,
                            carat: stone.carat.value,
                            color: stone.color.value,
                            quality: stone.quality as RingStoneCaratQuality,
                            shape: stone.shape.value,
                          })),
                        }))) ||
                      undefined,
                    sku: sku,
                    status: status,
                    weight: parseFloat(weight).toFixed(2),
                    barcode: barcode,
                    price: Number(price),
                    style: styleId
                      ? {
                          connect: {
                            id: styleId,
                          },
                        }
                      : undefined,
                  }),
                },
              },
              tableName: "Product",
            },
          },
        }),
      ]);
    }
  };

  const handleCalculatePrice = async () => {
    const res = await calculatePrice({
      variables: {
        data: {
          photos: { set: photos },
          category: { connect: { id: categoryId } },
          creatableIds: [...(materialId.length > 0 ? [materialId] : []), ...(laborId.length > 0 ? [laborId] : [])],
          colorIds: colors && colors.length > 0 ? colors.map((c) => c.value) : [],
          ringSizeStandartIds: sizesIds,
          stoneGroups:
            stonesGroups?.map((stoneGroup) => ({
              photos: stoneGroup.photos,
              stones: stoneGroup.stones.map((stone) => ({
                quantity: stone?.quantity,
                carat: stone.carat.value,
                color: stone.color.value,
                quality: stone.quality as RingStoneCaratQuality,
                shape: stone.shape.value,
              })),
            })) || undefined,
          weight: Number(weight),
          status,
        },
      },
    });
    if (res.data?.calculatePrice) {
      setCalculatedPrice(Number(res.data.calculatePrice.toFixed(2)));
    }
  };

  useEffect(() => {
    if (data) {
      if (data.stoneGroups) {
        setStonesGroups(() => {
          return data.stoneGroups
            .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
            .map((stoneGroup) => ({
              label: stoneGroup.id.slice(0, 12) || "",
              photos: stoneGroup?.photos || [],
              stones:
                stoneGroup.stones?.map((stone) => ({
                  quantity: stone.quantity,
                  carat: {
                    label: stone.carat?.carat || "",
                    value: stone.carat.id || "",
                  },
                  color: {
                    label: stone.caratInfo.color,
                    value: stone.caratInfo.id,
                  },
                  quality: stone.quality,
                  shape: {
                    label: stone.shape?.shape! || "",
                    value: stone.shape?.id || "",
                  },
                })) || [],
              formStone: {
                quantity: 0,
                carat: {
                  label: "",
                  value: "",
                },
                color: {
                  label: "",
                  value: "",
                },
                quality: caratQualitiesEnum[0],
                shape: {
                  label: "",
                  value: "",
                },
              },
            }));
        });
      }
      handleCalculatePrice();
    }
  }, [data]);

  useEffect(() => {
    if (actionType === 'UPDATE') {
      handleCalculatePrice();
    }
    if (calculatedPrice) {
      const rounded = Math.ceil((calculatedPrice) / 10) * 10;
      setRoundedPrice(rounded - 0.01 <= 0 ? 0 : rounded - 0.01);
    }
  }, [calculatedPrice, actionType]);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Product
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/product" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <chakra.form onSubmit={handleSubmit}>
        <SimpleGrid columns={2} gap={10}>
          <Box gap={6}>
            <Box>
              <UploadDropzone isSingle={false} images={photos} setImages={setPhotos} />
            </Box>

            <Box mt={5}>
              <Tabs>
                <TabList>
                  <Tab>EN</Tab>
                  <Tab>DE</Tab>
                  <Tab>ES</Tab>
                  <Tab>FR</Tab>
                  <Tab>IT</Tab>
                  <Tab>TR</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.EN)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.EN)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.EN)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.EN)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.DE)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.DE)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.DE)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.DE)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.ES)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.ES)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.ES)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.ES)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.FR)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.FR)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.FR)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.FR)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.IT)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.IT)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.IT)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.IT)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={descriptions.find((d) => d.language === LanguageOptions.TR)!.title}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.TR)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={descriptions.find((d) => d.language === LanguageOptions.TR)!.description ?? ""}
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === LanguageOptions.TR)!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            <Box>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={loading || updateDescriptionLoading}
                isLoading={loading || updateDescriptionLoading}
              >
                Save Product
              </Button>
            </Box>
          </Box>

          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
            alignContent="start"
          >
            <GridItem colSpan={2}>
              <Tabs>
                <TabList
                  display="flex"
                  flexWrap="wrap"
                  border="1px solid"
                  style={{ border: "1px solid #2b6cb0" }}
                  borderRadius="lg"
                  py={6}
                  px={2}
                >
                  <Tab>Sku / Barcode</Tab>
                  <Tab>Material</Tab>
                  <Tab>Profit</Tab>
                  <Tab>Colors</Tab>
                  <Tab>Stone</Tab>
                  <Tab>Weight</Tab>
                  <Tab>Sizes</Tab>
                </TabList>
                <TabPanels
                  mt={4}
                  border="1px solid"
                  style={{ border: "1px solid #2b6cb0" }}
                  borderRadius="lg"
                  py={6}
                  px={2}
                >
                  <TabPanel>
                    <Stack gap={4}>
                      <Box>
                        <FormLabel>SKU</FormLabel>
                        <Input value={sku} onChange={(e) => setSku(e.target.value)} placeholder="Product Sku" />
                      </Box>
                      <Box>
                        <FormLabel>Barcode</FormLabel>
                        <Input
                          value={barcode}
                          onChange={(e) => setBarcode(e.target.value)}
                          placeholder="Product Barcode"
                        />
                      </Box>
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <FormLabel>Material</FormLabel>
                    <ReactSelect
                      placeholder="Material"
                      options={creatablesData?.creatables
                        .filter((c) => c.type === CreatableType.MATERIAL)
                        .map((c) => ({
                          value: c.id,
                          label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                        }))}
                      {...(actionType === "UPDATE" && {
                        ...{
                          value: creatablesData?.creatables
                            .map((c) => ({
                              label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                              value: c.id,
                            }))
                            .filter((c) => c.value === materialId),
                        },
                      })}
                      onChange={(e) => {
                        setMaterialId(e ? e.value : "");
                      }}
                    />
                  </TabPanel>

                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Profit</FormLabel>
                      <ReactSelect
                        placeholder="Profit"
                        options={creatablesData?.creatables
                          .filter((c) => c.type === CreatableType.LABOR)
                          .map((c) => ({
                            value: c.id,
                            label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                          }))}
                        {...(actionType === "UPDATE" && {
                          ...{
                            value: creatablesData?.creatables
                              .map((c) => ({
                                label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                                value: c.id,
                              }))
                              .filter((c) => c.value === laborId),
                          },
                        })}
                        onChange={(e) => {
                          setLaborId(e ? e.value : "");
                        }}
                      />
                    </GridItem>
                  </TabPanel>

                  <TabPanel>
                    <FormLabel>Colors</FormLabel>
                    <ReactSelect
                      isClearable
                      {...(actionType === "UPDATE" &&
                        creatablesData && {
                          ...{
                            value: colors,
                          },
                        })}
                      isMulti
                      placeholder="Colors"
                      options={
                        ringColors?.ringColors.map((color) => ({
                          label: color.descriptions.find((d) => d.language === LanguageOptions.EN)?.title!,
                          value: color.id,
                        })) || []
                      }
                      onChange={(e: any) => {
                        setColors(e);
                      }}
                    />
                  </TabPanel>

                  <TabPanel>
                    <Stack gap={4}>
                      <Box>
                        <Box>
                          <FormLabel display="flex" alignItems="center" gap={3}>
                            Stone Groups
                            <Tooltip label="Add Stone Group">
                              <IconButton
                                icon={<FcPlus fontSize={12} />}
                                aria-label="Add Stone Group"
                                size="xs"
                                colorScheme="green"
                                onClick={() => {
                                  setStonesGroups((prev) => [
                                    ...prev,
                                    {
                                      label: `Stone Group ${prev.length + 1}`,
                                      photos: [],
                                      stones: [],
                                      formStone: {
                                        quantity: 1,
                                        carat: {
                                          value: "",
                                          label: "",
                                        },
                                        color: {
                                          value: "",
                                          label: "",
                                        },
                                        quality: caratQualitiesEnum[0],
                                        shape: {
                                          value: "",
                                          label: "",
                                        },
                                      },
                                    },
                                  ]);
                                }}
                              />
                            </Tooltip>
                          </FormLabel>
                          {stonesGroups && stonesGroups?.length > 0 && (
                            <Tabs mt={5}>
                              <TabList flexWrap="wrap">
                                {stonesGroups.map((stoneGroup, index) => (
                                  <Tab key={index}>
                                    <Flex gap={2} alignItems="center">
                                      <Box fontSize={15}>Stone Group {index + 1}</Box>
                                      <Tooltip label="Remove Stone Group">
                                        <IconButton
                                          aria-label="Remove Stone Group"
                                          icon={<FaTrash fontSize={12} />}
                                          colorScheme="red"
                                          size="xs"
                                          onClick={() => {
                                            const updatedStonesGroups = [...stonesGroups];
                                            updatedStonesGroups.splice(index, 1);
                                            setStonesGroups(updatedStonesGroups);
                                          }}
                                        />
                                      </Tooltip>
                                    </Flex>
                                  </Tab>
                                ))}
                              </TabList>
                              <TabPanels>
                                {stonesGroups.map((stoneGroup, index) => (
                                  <TabPanel key={index}>
                                    <Box mb={5}>
                                      <FormLabel>Add Stone Form</FormLabel>
                                      <SimpleGrid columns={5} gap={5} alignItems="end">
                                        <FormControl>
                                          <FormLabel fontSize="sm">Quantity</FormLabel>
                                          <Input
                                            placeholder="Quantity"
                                            borderColor="blue.300"
                                            colorScheme="blue"
                                            type="number"
                                            value={stoneGroup.formStone.quantity}
                                            onChange={(e) => {
                                              const copyOfStoneGroup = { ...stoneGroup };
                                              copyOfStoneGroup.formStone.quantity = Number(e.target.value);
                                              const indexOfStoneGroup = stonesGroups.findIndex(
                                                (c) => c.label === stoneGroup.label
                                              );
                                              if (indexOfStoneGroup !== -1) {
                                                setStonesGroups(
                                                  stonesGroups.map((group, i) =>
                                                    i === indexOfStoneGroup ? copyOfStoneGroup : group
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </FormControl>

                                        <FormControl>
                                          <FormLabel fontSize="sm">Carat</FormLabel>
                                          <ReactSelect
                                            isMulti={false}
                                            placeholder="Carat"
                                            options={
                                              carats?.ringStoneCarats.map((carat) => ({
                                                label: carat.carat,
                                                value: carat.id,
                                              })) || []
                                            }
                                            value={
                                              stoneGroup.formStone.carat.value
                                                ? {
                                                    label: stoneGroup.formStone.carat.label,
                                                    value: stoneGroup.formStone.carat.value,
                                                  }
                                                : null
                                            }
                                            onChange={(e) => {
                                              const copyOfStoneGroup = { ...stoneGroup };
                                              copyOfStoneGroup.formStone.carat = {
                                                label: e?.label || "",
                                                value: e?.value || "",
                                              };
                                              const indexOfStoneGroup = stonesGroups.findIndex(
                                                (c) => c.label === stoneGroup.label
                                              );
                                              if (indexOfStoneGroup !== -1) {
                                                setStonesGroups(
                                                  stonesGroups.map((group, i) =>
                                                    i === indexOfStoneGroup ? copyOfStoneGroup : group
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </FormControl>

                                        <FormControl>
                                          <FormLabel fontSize="sm">Color</FormLabel>
                                          <ReactSelect
                                            isMulti={false}
                                            placeholder="Color"
                                            options={
                                              carats?.ringStoneCarats
                                                .find((stoneCarat) => {
                                                  return stoneCarat.id === stoneGroup.formStone.carat.value;
                                                })
                                                ?.caratInfos.map((info) => ({
                                                  label: info.color,
                                                  value: info.id,
                                                })) || []
                                            }
                                            value={
                                              stoneGroup.formStone.color.value
                                                ? {
                                                    label: stoneGroup.formStone.color.label,
                                                    value: stoneGroup.formStone.color.value,
                                                  }
                                                : null
                                            }
                                            onChange={(e) => {
                                              const copyOfStoneGroup = { ...stoneGroup };
                                              copyOfStoneGroup.formStone.color = {
                                                label: e?.label || "",
                                                value: e?.value || "",
                                              };
                                              const indexOfStoneGroup = stonesGroups.findIndex(
                                                (c) => c.label === stoneGroup.label
                                              );
                                              if (indexOfStoneGroup !== -1) {
                                                setStonesGroups(
                                                  stonesGroups.map((group, i) =>
                                                    i === indexOfStoneGroup ? copyOfStoneGroup : group
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontSize="sm">Quality</FormLabel>
                                          <ReactSelect
                                            isMulti={false}
                                            options={caratQualitiesEnum.map((quality) => ({
                                              label: quality,
                                              value: quality,
                                            }))}
                                            placeholder="Quality"
                                            value={{
                                              label: stoneGroup.formStone.quality!,
                                              value: stoneGroup.formStone.quality,
                                            }}
                                            onChange={(e) => {
                                              const copyOfStoneGroup = { ...stoneGroup };
                                              copyOfStoneGroup.formStone.quality = e?.value!;
                                              const indexOfStoneGroup = stonesGroups.findIndex(
                                                (c) => c.label === stoneGroup.label
                                              );
                                              if (indexOfStoneGroup !== -1) {
                                                setStonesGroups(
                                                  stonesGroups.map((group, i) =>
                                                    i === indexOfStoneGroup ? copyOfStoneGroup : group
                                                  )
                                                );
                                              }
                                            }}
                                            defaultValue={{
                                              label: caratQualitiesEnum[0],
                                              value: caratQualitiesEnum[0],
                                            }}
                                          />
                                        </FormControl>
                                    
                                        <Tooltip label="Add Stone">
                                          <IconButton
                                            aria-label="Add Stone"
                                            icon={<FcPlus />}
                                            colorScheme="green"
                                            onClick={() => {
                                              const formStone = stoneGroup.formStone;
                                              if (
                                                formStone.quantity === 0 ||
                                                formStone.carat.value === "" ||
                                                formStone.color.value === "" ||
                                                formStone.quality === ""
                                              ) {
                                                return toast({
                                                  status: "error",
                                                  description: "Please fill the form",
                                                  title: "Error",
                                                  duration: 1000,
                                                });
                                              }

                                              const stoneGroupIndex = stonesGroups.findIndex(
                                                (c) => c.label === stoneGroup.label
                                              );
                                              if (stoneGroupIndex === -1) return;
                                              const copyOfStoneGroup = stonesGroups[stoneGroupIndex];
                                              copyOfStoneGroup.stones.push(formStone);
                                              copyOfStoneGroup.formStone = {
                                                quantity: 0,
                                                carat: {
                                                  value: "",
                                                  label: "",
                                                },
                                                color: {
                                                  value: "",
                                                  label: "",
                                                },
                                                quality: caratQualitiesEnum[0],
                                                shape: {
                                                  value: "",
                                                  label: "",
                                                },
                                              };
                                              setStonesGroups(
                                                stonesGroups.map((c, i) =>
                                                  i === stoneGroupIndex ? copyOfStoneGroup : c
                                                )
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      </SimpleGrid>
                                    </Box>

                                    <Box>
                                      {stoneGroup.stones?.length > 0 && (
                                        <FormLabel mt={3}>
                                          Added Stones - ( {stoneGroup.stones.length || "0"} )
                                        </FormLabel>
                                      )}
                                      {stoneGroup.stones?.map((stone, j) => (
                                        <Fragment key={j}>
                                          <FormControl>
                                            <FormLabel fontSize="sm">Name</FormLabel>
                                            <Text fontSize="sm">{stoneNamer(stone)}</Text>
                                          </FormControl>
                                          <Stack mt={2} gap={5} key={j}>
                                            <SimpleGrid
                                              key={index}
                                              pl={2}
                                              columns={5}
                                              gap={5}
                                              border="1px solid"
                                              borderColor="blue.300"
                                              p={2}
                                              rounded="md"
                                            >
                                              <FormControl>
                                                <FormLabel fontSize="sm">Qty</FormLabel>
                                                <Input
                                                  placeholder="Quantity"
                                                  borderColor="blue.300"
                                                  colorScheme="blue"
                                                  type="number"
                                                  value={stone.quantity.toString()}
                                                  readOnly
                                                  disabled
                                                />
                                              </FormControl>
                                              <FormControl>
                                                <FormLabel fontSize="sm">Carat</FormLabel>
                                                <Input
                                                  placeholder="Carat"
                                                  borderColor="blue.300"
                                                  colorScheme="blue"
                                                  value={stone.carat.label.toString()}
                                                  readOnly
                                                  disabled
                                                />
                                              </FormControl>
                                              <FormControl>
                                                <FormLabel fontSize="sm">Color</FormLabel>
                                                <Input
                                                  placeholder="Color"
                                                  borderColor="blue.300"
                                                  colorScheme="blue"
                                                  value={stone.color.label.toString()}
                                                  readOnly
                                                  disabled
                                                />
                                              </FormControl>
                                              <FormControl>
                                                <FormLabel fontSize="sm">Quality</FormLabel>
                                                <Input
                                                  placeholder="Quality"
                                                  borderColor="blue.300"
                                                  colorScheme="blue"
                                                  value={stone.quality.toString()}
                                                  readOnly
                                                  disabled
                                                />
                                              </FormControl>

                                              <Tooltip label="Remove Stone">
                                                <IconButton
                                                  alignSelf="center"
                                                  aria-label={`Remove Stone ${stoneNamer(stone)}`}
                                                  icon={<FaTrash />}
                                                  colorScheme="red"
                                                  onClick={() => {
                                                    const stoneGroupIndex = stonesGroups.findIndex(
                                                      (c) => c.label === stoneGroup.label
                                                    );
                                                    if (stoneGroupIndex === -1) return;
                                                    const copyOfStoneGroup = stonesGroups[stoneGroupIndex];
                                                    copyOfStoneGroup.stones.splice(j, 1);
                                                    setStonesGroups(
                                                      stonesGroups.map((c, i) =>
                                                        i === stoneGroupIndex ? copyOfStoneGroup : c
                                                      )
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </SimpleGrid>
                                          </Stack>
                                        </Fragment>
                                      ))}
                                    </Box>

                                    <FormControl mt={6}>
                                      <FormLabel fontSize={"sm"}>
                                        Photos ( {stoneGroup?.photos?.length || "0"} )
                                      </FormLabel>
                                      <UploadDropzone
                                        isSingle={false}
                                        images={stonesGroups.find((c) => c.label === stoneGroup.label)?.photos || []}
                                        setImages={(images: Array<string>) => {
                                          const index = stonesGroups.findIndex((c) => c.label === stoneGroup.label);
                                          if (index !== -1) {
                                            const updatedStonesGroups = [...stonesGroups];
                                            updatedStonesGroups[index].photos = images;
                                            setStonesGroups(updatedStonesGroups);
                                          }
                                        }}
                                      />
                                    </FormControl>
                                  </TabPanel>
                                ))}
                              </TabPanels>
                            </Tabs>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <Box>
                      <FormLabel>Weight</FormLabel>
                      <Input
                        value={weight}
                        type="number"
                        step={0.1}
                        min={0}
                        onChange={(e) => setWeight(e.target.value)}
                        placeholder="Fiyat"
                      />
                      <chakra.span fontSize="xs" color="gray.500">
                        Weight (gram)
                      </chakra.span>
                    </Box>
                  </TabPanel>

                  <TabPanel>
                    <FormLabel>Sizes Standarts</FormLabel>
                    <ReactSelect
                      isLoading={sizesStandartsLoading}
                      isDisabled={sizesStandartsLoading}
                      isClearable
                      {...(actionType === "UPDATE" &&
                        creatablesData && {
                          ...{
                            value: sizes,
                          },
                        })}
                      isMulti
                      placeholder="Sizes Standarts"
                      options={
                        Array.isArray(uniqueSizeCategories)
                          ? uniqueSizeCategories.map((standart) => ({
                              label: standart,
                              value: standart,
                            }))
                          : []
                      }
                      onChange={(e: any) => {
                        setSizes(e);
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>

            <GridItem colSpan={2}>
              {stylesLoading ? (
                <Box>
                  <SkeletonGroup amount={1} />
                </Box>
              ) : (
                <Box>
                  <FormLabel>Style</FormLabel>
                  <ReactSelect
                    menuPlacement="auto"
                    placeholder="Style"
                    options={stylesData?.styles.map((c) => ({
                      value: c.id,
                      label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                    }))}
                    {...(actionType === "UPDATE" && {
                      ...{
                        value: stylesData!.styles
                          .map((c) => ({
                            label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title}`,
                            value: c.id,
                          }))
                          .filter((c) => c.value === styleId),
                      },
                    })}
                    onChange={(e) => {
                      setStyleId(e ? e.value : "");
                    }}
                  />
                </Box>
              )}
            </GridItem>
            <GridItem colSpan={2}>
              {categoriesLoading ? (
                <Box>
                  <SkeletonGroup amount={1} />
                </Box>
              ) : (
                <Box>
                  <FormLabel>Category</FormLabel>
                  <ReactSelect
                    menuPlacement="auto"
                    placeholder="Category"
                    options={categoriesData?.categories.map((c) => ({
                      value: c.id,
                      label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title} ${
                        c.parentCategoryId
                          ? `- [${
                              c.parentCategory?.descriptions.find((l) => l.language === LanguageOptions.EN)?.title
                            }]`
                          : ""
                      }`,
                    }))}
                    {...(actionType === "UPDATE" && {
                      ...{
                        value: categoriesData!.categories
                          .map((c) => ({
                            label: `${c.descriptions.find((l) => l.language === LanguageOptions.EN)?.title} ${
                              c.parentCategoryId
                                ? `- [${
                                    c.parentCategory?.descriptions.find((l) => l.language === LanguageOptions.EN)?.title
                                  }]`
                                : ""
                            }`,
                            value: c.id,
                          }))
                          .filter((c) => c.value === categoryId),
                      },
                    })}
                    onChange={(e) => {
                      setCategoryId(e ? e.value : "");
                    }}
                  />
                </Box>
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Status</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button width={"full"} colorScheme={status ? "green" : "gray"} onClick={() => setStatus(true)}>
                    Active
                  </Button>
                </GridItem>
                <GridItem>
                  <Button width={"full"} colorScheme={!status ? "red" : "gray"} onClick={() => setStatus(false)}>
                    Passive
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>

            <GridItem mt={2} colSpan={3}>
              <SimpleGrid columns={3} gap={2}>
                <Box>
                  <Text>Automatic</Text>
                  <Flex>
                    <Input readOnly value={calculatedPrice?.toString() ?? "0"} disabled />
                    <Button onClick={handleCalculatePrice}>
                      <FaCalculator />
                    </Button>
                  </Flex>
                  <Button
                    mt={3}
                    width="100%"
                    colorScheme={priceMode === "SYSTEM" ? "green" : "gray"}
                    onClick={() => {
                      setPriceMode("SYSTEM");
                    }}
                  >
                    Choose
                  </Button>
                </Box>
                <Box>
                  <Text>Hand Price(Default)</Text>
                  <Flex>
                    <Input
                      disabled={priceMode !== "MANUAL"}
                      type="number"
                      value={price.toString()}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                  </Flex>
                  <Button
                    mt={3}
                    width="100%"
                    colorScheme={priceMode === "MANUAL" ? "green" : "gray"}
                    onClick={() => {
                      setPriceMode("MANUAL");
                    }}
                  >
                    Choose
                  </Button>
                </Box>

                <Box>
                  <Text>Rounded</Text>
                  <Flex>
                    <Input
                      readOnly
                      value={
                        Math.ceil(calculatedPrice! / 10) * 10 - 0.01 <= 0
                          ? 0
                          : Math.ceil(calculatedPrice! / 10) * 10 - 0.01
                      }
                      disabled
                    />
                  </Flex>
                  <Button
                    mt={3}
                    width="100%"
                    colorScheme={priceMode === "SYSTEM-ROUNDED" ? "green" : "gray"}
                    onClick={() => {
                      setPriceMode("SYSTEM-ROUNDED");
                    }}
                  >
                    Choose
                  </Button>
                </Box>
              </SimpleGrid>
              <Box display="flex" gap={4} mt={4}>
                {actionType === "UPDATE" && (
                  <Box>
                    <Text>Old Price</Text>
                    <Flex>
                      <Input
                        readOnly
                        value={data?.oldPrice?.toString() || "-"}
                        onChange={(e) => setPrice(Number(e.target.value))}
                        disabled
                      />
                    </Flex>
                  </Box>
                )}

                <Box>
                  <Text>Price</Text>
                  <Flex>
                    <Input
                      disabled
                      type="number"
                      value={price.toString()}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                  </Flex>
                </Box>
              </Box>
            </GridItem>
          </SimpleGrid>
        </SimpleGrid>
      </chakra.form>
    </PageLayout>
  );
};

export default Form;
