import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateDiscountCouponMutationVariables,
  useCreateDiscountCouponMutation,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";

const Create = () => {
  const [createMutation] = useCreateDiscountCouponMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleCreate = async (data: CreateDiscountCouponMutationVariables) => {
    try {
      await createMutation({
        variables: {
          data: data.data,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "CREATE",
                message: `Created DiscountCoupon id: ${data.data.id} by ${staff?.name}`,
              },
              tableName: "DiscountCoupon",
            },
          },
        }),
      ]);

      return toast({
        status: "success",
        title: "Successful",
        description: "Coupon added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/discountcoupon");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };
  return <Form handleCreate={handleCreate} actionType="CREATE" />;
};

export default Create;
