import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Badge,
  Button,
  useToast,
  Flex,
  IconButton,
  Heading,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  ModalHeader,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  ModalCloseButton,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreateOneCreatableMutationVariables,
  Description,
  GetRingColorQuery,
  LanguageOptions,
  RingColorPurity,
  UpdateOneCreatableMutationVariables,
  useCreateOneRingColorMutation,
  useUpdateOneRingColorMutation,
  useCreateOneRingColorPurityMutation,
  useCreateOneStaffLogMutation,
  useDeleteOneRingColorPurityMutation,
  useUpdateDescriptionMutation,
  useUpdateOneRingColorPurityMutation,
} from "../../generated/graphql";
import { FcEditImage, FcList, FcPlus, FcRefresh } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import { useStaffStore } from "../../store/staff";
import { $enum } from "ts-enum-util";
import { createSlug } from "../../utils/slug";

interface Props {
  handleCreate?: (data: CreateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  handleUpdate?: (data: UpdateOneCreatableMutationVariables, previousParam: string) => Promise<any>;
  id?: string;
  data?: GetRingColorQuery["getRingColor"];
  actionType: "CREATE" | "UPDATE";
  refetch?: () => void;
}

const Form: React.FC<Props> = ({ id, data, actionType, refetch }) => {
  const toast = useToast();
  const [colorPurity, setColorPurity] = useState<Partial<RingColorPurity | null>>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createRingColor, { loading: loadingCreateRingColor }] = useCreateOneRingColorMutation();
  const [updateRingColor, { loading: loadingUpdateRingColor }] = useUpdateOneRingColorMutation();
  const [createPurity, { loading: loadingCreatePurity }] = useCreateOneRingColorPurityMutation();
  const [updatePurity, { loading: loadingUpdatePurity }] = useUpdateOneRingColorPurityMutation();
  const [deletePurity, { loading: loadingDeletePurity }] = useDeleteOneRingColorPurityMutation();
  const navigate = useNavigate();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();

  const [updateDescription] = useUpdateDescriptionMutation();

  const [descriptions, setDescriptions] = useState<Array<Description>>(
    data?.descriptions ||
      ($enum(LanguageOptions).map((item) => ({
        title: "",
        description: "",
        language: item,
        slug: "",
      })) as any)
  );
  const [price, setPrice] = useState<string>(() => {
    return data?.price || "0";
  });

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Ring Color
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/ring-colors" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={(e) => e.preventDefault()}>
        <SimpleGrid columns={2} gap={10}>
          <Box>
            <GridItem colSpan={2}>
              {/* <FormLabel>Title</FormLabel>
              <Tabs>
                <TabList>
                  {$enum(LanguageOptions).map((item, index) => (
                    <Tab key={index}>{item}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {$enum(LanguageOptions).map((item, index) => (
                    <TabPanel key={index}>
                      <Box>
                        <Input
                          value={descriptions.find((d) => d?.language === item)!.title || ""}
                          onChange={(e) => {
                            const newDescriptions = [...descriptions];
                            newDescriptions.find((d) => d.language === item)!.title = e.target.value;
                            setDescriptions(newDescriptions);
                          }}
                          placeholder="Title"
                        />
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs> */}

              <SimpleGrid columns={1} gap={4}>
                {$enum(LanguageOptions).map((item, index) => (
                  <Box key={index}>
                    <FormLabel>{item}</FormLabel>
                    <Box mt={2}>
                      <Input
                        value={descriptions.find((d) => d?.language === item)!.title || ""}
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find((d) => d.language === item)!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder={`${item} Title`}
                      />
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Price:</FormLabel>
              <Input value={price} onChange={(e) => setPrice(String(e.target.value))} placeholder="Price" type="text" />
            </GridItem>

            <GridItem>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={loadingCreateRingColor || loadingUpdateRingColor}
                isLoading={loadingCreateRingColor || loadingUpdateRingColor}
                onClick={async () => {
                  if (actionType === "CREATE") {
                    if (loadingCreateRingColor) return;
                    const isAllDescriptionsFilled = descriptions.every((item) => item.title.trim().length !== 0);
                    if (!isAllDescriptionsFilled) {
                      return toast({
                        status: "error",
                        title: "Error",
                        description: "All title are required",
                        duration: 1000,
                      });
                    }
                    try {
                      const returned = await createRingColor({
                        variables: {
                          data: {
                            price: String(price),
                            descriptions: {
                              create: [
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.EN)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.EN)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.EN)!.title),
                                  language: LanguageOptions.EN,
                                },
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.DE)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.DE)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.DE)!.title),
                                  language: LanguageOptions.DE,
                                },
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.ES)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.ES)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.ES)!.title),
                                  language: LanguageOptions.ES,
                                },
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.FR)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.FR)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.FR)!.title),
                                  language: LanguageOptions.FR,
                                },
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.IT)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.IT)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.IT)!.title),
                                  language: LanguageOptions.IT,
                                },
                                {
                                  title: descriptions.find((d) => d.language === LanguageOptions.TR)!.title,
                                  description: descriptions.find((d) => d.language === LanguageOptions.TR)!.description,
                                  slug: createSlug(descriptions.find((d) => d.language === LanguageOptions.TR)!.title),
                                  language: LanguageOptions.TR,
                                },
                              ],
                            },
                          },
                        },
                      });

                      await Promise.allSettled([
                        createLog({
                          variables: {
                            data: {
                              staff: {
                                connect: {
                                  id: staff?.id as string,
                                },
                              },
                              json: {
                                action: "CREATE",
                                message: `Created Ring Color by ${staff?.name}`,
                              },
                              tableName: "RingColor",
                            },
                          },
                        }),
                      ]);

                      toast({
                        status: "success",
                        title: "Success",
                        description: "Ring Color created",
                        duration: 1000,
                        onCloseComplete: () => {
                          if (refetch) {
                            refetch();
                          }
                        },
                      });
                      if (refetch) {
                        refetch();
                      }
                      navigate(`/ring-colors/update/${returned.data?.createOneRingColor.id}`);
                    } catch (error: any) {
                      toast({
                        status: "error",
                        title: "Error",
                        description: error?.message || "Something went wrong",
                        duration: 1000,
                      });
                    }
                  }

                  if (actionType === "UPDATE") {
                    if (loadingUpdateRingColor) return;

                    await updateRingColor({
                      variables: {
                        data: {
                          price: {
                            set: String(price),
                          },
                        },
                        where: {
                          id,
                        },
                      },
                    });
                    await Promise.allSettled([
                      createLog({
                        variables: {
                          data: {
                            staff: {
                              connect: {
                                id: staff?.id as string,
                              },
                            },
                            json: {
                              action: "UPDATE",
                              message: `Updated Ring Color by ${staff?.name}`,
                            },
                            tableName: "RingColor",
                          },
                        },
                      }),
                    ]);
                    for await (const description of descriptions) {
                      updateDescription({
                        variables: {
                          where: {
                            id: description.id,
                          },
                          data: {
                            title: {
                              set: description.title,
                            },
                            description: {
                              set: description.description,
                            },
                            slug: {
                              set: createSlug(description.title),
                            },
                          },
                        },
                      });
                    }
                  }
                }}
              >
                Save
              </Button>
            </GridItem>
          </Box>

          <Box>
            <Flex
              mb={6}
              justifyContent={"space-between"}
              alignItems="center"
              bgColor={"gray.100"}
              padding={2}
              rounded="md"
            >
              <Box fontSize={"18px"} fontWeight="bold">
                Purities
              </Box>

              <Flex gap={1}>
                <IconButton icon={<FcPlus />} aria-label="Add Item" onClick={onOpen} isDisabled={!id} />
                <IconButton
                  icon={<FcRefresh />}
                  aria-label="Refetch Query"
                  // onClick={() => refetch()}
                  isDisabled={!id}
                />
              </Flex>
            </Flex>
            {actionType === "UPDATE" && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Purity</Th>
                    <Th>Price</Th>
                    <Th>Is Default</Th>
                    <Th textAlign="right">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.purities
                    .sort((a, b) => a.value - b.value)
                    .map((item, index) => (
                      <Tr key={item?.id}>
                        <Td>{index + 1}</Td>
                        <Td>{item?.value}</Td>
                        <Td>{item?.price}</Td>
                        <Td>
                          <Badge colorScheme={item?.isDefault ? "green" : "red"}>
                            {item?.isDefault ? "Yes" : "No"}
                          </Badge>
                        </Td>
                        <Td>
                          <Stack direction="row" spacing={4} align="center" justifyContent="flex-end">
                            <DeleteItem
                              handleDelete={async () => {
                                if (loadingDeletePurity) return;
                                try {
                                  await deletePurity({
                                    variables: {
                                      where: {
                                        id: item?.id as string,
                                      },
                                    },
                                  });

                                  toast({
                                    status: "success",
                                    title: "Success",
                                    description: "Purity deleted",
                                    duration: 1000,
                                    onCloseComplete: () => {
                                      if (refetch) {
                                        return refetch();
                                      }
                                    },
                                  });
                                  await Promise.allSettled([
                                    createLog({
                                      variables: {
                                        data: {
                                          staff: {
                                            connect: {
                                              id: staff?.id as string,
                                            },
                                          },
                                          json: {
                                            action: "DELETE",
                                            message: `Deleted Purity by ${staff?.name}`,
                                          },
                                          tableName: "RingColorPurity",
                                        },
                                      },
                                    }),
                                  ]);
                                  if (refetch) {
                                    refetch();
                                  }
                                } catch (error: any) {
                                  toast({
                                    status: "error",
                                    title: "Error",
                                    description: error?.message || "Something went wrong",
                                    duration: 1000,
                                  });
                                }
                              }}
                              id={item?.id as string}
                              loading={loadingDeletePurity}
                              title="Purity"
                            />
                            <IconButton
                              icon={<FcEditImage />}
                              aria-label="Edit Item"
                              onClick={() => {
                                setColorPurity(item);
                                onOpen();
                              }}
                            />
                          </Stack>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </SimpleGrid>
      </form>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setColorPurity(null);
          onClose();
        }}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size={"md"}>{actionType === "CREATE" ? "Create" : "Update"} Size</Heading>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <SimpleGrid
              columns={3}
              as="form"
              gap={5}
              pb={8}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <FormControl isRequired>
                <FormLabel>Purity</FormLabel>
                <Input
                  placeholder="Purity"
                  type="text"
                  value={colorPurity?.value || ""}
                  onChange={(e) => {
                    setColorPurity({
                      ...colorPurity,
                      value: Number(e.target.value),
                    });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Price</FormLabel>

                <Input
                  placeholder="Price"
                  type="text"
                  value={colorPurity?.price || ""}
                  onChange={(e) => {
                    setColorPurity({
                      ...colorPurity,
                      price: String(e.target.value),
                    });
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Is Default?</FormLabel>
                <Checkbox
                  isChecked={colorPurity?.isDefault}
                  onChange={(e) => {
                    setColorPurity({
                      ...colorPurity,
                      isDefault: e.target.checked,
                    });
                  }}
                />
              </FormControl>
              <Button
                colorScheme="green"
                type="submit"
                mt={6}
                isDisabled={loadingCreatePurity}
                isLoading={loadingCreatePurity}
                onClick={async () => {
                  if (loadingCreatePurity || loadingUpdatePurity) return;
                  if (!colorPurity?.id) {
                    try {
                      const findedDefault = data?.purities.find((item) => item.isDefault);
                      if (colorPurity?.isDefault && findedDefault) {
                        await updatePurity({
                          variables: {
                            data: {
                              isDefault: {
                                set: false,
                              },
                            },
                            where: {
                              id: findedDefault.id,
                            },
                          },
                        });
                      }
                      await createPurity({
                        variables: {
                          data: {
                            price: String(colorPurity?.price),
                            value: Number(colorPurity?.value),
                            isDefault: colorPurity?.isDefault,
                            color: {
                              connect: {
                                id: id!,
                              },
                            },
                          },
                        },
                      });
                      toast({
                        status: "success",
                        title: "Success",
                        description: "Purity created",
                        duration: 1000,
                        onCloseComplete: () => {
                          if (refetch) {
                            refetch();
                          }
                        },
                      });
                      await Promise.allSettled([
                        createLog({
                          variables: {
                            data: {
                              staff: {
                                connect: {
                                  id: staff?.id as string,
                                },
                              },
                              json: {
                                action: "CREATE",
                                message: `Created Purity by ${staff?.name}`,
                              },
                              tableName: "RingColorPurity",
                            },
                          },
                        }),
                      ]);
                    } catch (error: any) {
                      toast({
                        status: "error",
                        title: "Error",
                        description: error?.message || "Something went wrong",
                        duration: 1000,
                      });
                    }
                  } else {
                    try {
                      if (loadingUpdatePurity) return;
                      const findedDefault = data?.purities.find((item) => item.isDefault);
                      if (colorPurity?.isDefault && findedDefault && findedDefault.id !== colorPurity?.id) {
                        await updatePurity({
                          variables: {
                            data: {
                              isDefault: {
                                set: false,
                              },
                            },
                            where: {
                              id: findedDefault.id,
                            },
                          },
                        });
                      }
                      await updatePurity({
                        variables: {
                          data: {
                            price: {
                              set: String(colorPurity?.price),
                            },
                            value: {
                              set: Number(colorPurity?.value),
                            },
                            isDefault: {
                              set: colorPurity?.isDefault,
                            },
                          },
                          where: {
                            id: colorPurity?.id as string,
                          },
                        },
                      });
                      toast({
                        status: "success",
                        title: "Success",
                        description: "Purity updated",
                        duration: 1000,
                        onCloseComplete: () => {
                          if (refetch) {
                            refetch();
                          }
                        },
                      });
                      await Promise.allSettled([
                        createLog({
                          variables: {
                            data: {
                              staff: {
                                connect: {
                                  id: staff?.id as string,
                                },
                              },
                              json: {
                                action: "UPDATE",
                                message: `Updated Purity by ${staff?.name}`,
                              },
                              tableName: "RingColorPurity",
                            },
                          },
                        }),
                      ]);
                    } catch (error: any) {
                      toast({
                        status: "error",
                        title: "Error",
                        description: error?.message || "Something went wrong",
                        duration: 1000,
                      });
                    }
                  }

                  onClose();
                  setColorPurity(null);
                }}
              >
                Save
              </Button>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default Form;
