import { useEffect, useState } from "react";
import { Box, SimpleGrid, Input, FormLabel, Button, useToast, Flex, IconButton, FormControl } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  useCreateOneRingStoneShapeMutation,
  useCreateOneStaffLogMutation,
  useGetRingStoneShapeQuery,
  useUpdateOneRingStoneShapeMutation,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { useStaffStore } from "../../store/staff";

interface Props {
  id?: string;
  actionType: "CREATE" | "UPDATE";
  refetch?: () => void;
}

const Form: React.FC<Props> = ({ id,  actionType, refetch }) => {
  const { data: shape } = useGetRingStoneShapeQuery({
    variables: {
      where: {
        id,
      },
    },
  });
  const [name, setName] = useState<string>(() => {
    return shape?.getRingStoneShape?.shape || "";
  });
  const toast = useToast();

  const navigate = useNavigate();
  const [updateShape, { loading: loadingUpdateShape }] = useUpdateOneRingStoneShapeMutation();
  const [createShape, { loading: loadingCreateShape }] = useCreateOneRingStoneShapeMutation();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();

  useEffect(() => {
    if (shape) {
      setName(shape.getRingStoneShape?.shape || "");
    }
  }, [shape]);

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Shape
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/shape" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={(e) => e.preventDefault()}>
        <SimpleGrid columns={2} gap={10}>
          <Box>
            <FormControl>
              <FormLabel>Shape Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                width="80%"
              />
            </FormControl>
            <Button
              colorScheme="green"
              mt={4}
              onClick={async () => {
                if (!name) {
                  toast({
                    title: "Error",
                    description: "Name is required",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }
                if (actionType === "UPDATE") {
                  try {
                    if (loadingUpdateShape) {
                      return;
                    }
                    await updateShape({
                      variables: {
                        data: {
                          shape: {
                            set: name,
                          },
                        },
                        where: {
                          id,
                        },
                      },
                    });
                    toast({
                      title: "Success",
                      description: "Shape updated successfully",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                    await Promise.allSettled([
                      createLog({
                        variables: {
                          data: {
                            staff: {
                              connect: {
                                id: staff?.id as string,
                              },
                            },
                            json: {
                              action: "UPDATE",
                              message: `Updated Ring Shape id: ${id} by ${staff?.name}`,
                            },
                            tableName: "RingColor",
                          },
                        },
                      }),
                    ]);
                    await refetch?.();
                  } catch (error: any) {
                    toast({
                      title: "Error",
                      description: error?.message || "Something went wrong",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }

                if (actionType === "CREATE") {
                  try {
                    if (loadingCreateShape) {
                      return;
                    }
                    await createShape({
                      variables: {
                        data: {
                          shape: name,
                        },
                      },
                    });
                    toast({
                      title: "Success",
                      description: "Shape created successfully",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                    await Promise.allSettled([
                      createLog({
                        variables: {
                          data: {
                            staff: {
                              connect: {
                                id: staff?.id as string,
                              },
                            },
                            json: {
                              action: "CREATE",
                              message: `Created Ring Shape id: ${id} by ${staff?.name}`,
                            },
                            tableName: "RingColor",
                          },
                        },
                      }),
                    ]);
                    navigate("/shape");
                  } catch (error: any) {
                    toast({
                      title: "Error",
                      description: error?.message || "Something went wrong",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }
              }}
              isLoading={loadingUpdateShape}
            >
              Save
            </Button>
          </Box>
          <Box></Box>
        </SimpleGrid>
      </form>
    </PageLayout>
  );
};

export default Form;
