import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import validator from "validator";
import PageLayout from "../../components/ui/PageLayout";
import {
  CategoryQuery,
  CreateCategoryMutationVariables,
  CreateOneCreatableMutationVariables,
  CreateOneStyleMutation,
  CreateOneStyleMutationVariables,
  LanguageOptions,
  StyleQuery,
  UpdateCategoryMutationVariables,
  UpdateOneStyleMutationVariables,
  useCreateOneStaffLogMutation,
  useUpdateDescriptionMutation,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import { createSlug } from "../../utils/slug";
import { DescriptionProperty } from "../../types/descriptionProperty";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreate?: (data: CreateOneStyleMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateOneStyleMutationVariables) => Promise<any>;
  id?: string;
  data?: StyleQuery["style"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [descriptions, setDescriptions] = useState<DescriptionProperty[]>(
    getLocalizedDescription({ descriptions: data?.descriptions })
  );
  const [updateDescriptionMutation, { loading: updateDescriptionLoading }] =
    useUpdateDescriptionMutation();

  const [photos, setPhotos] = useState(data?.photos || []);

  
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.EN)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title English is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.DE)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title German is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.ES)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Spanish is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.FR)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title French is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.IT)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Italian is required",
        title: "Error",
        duration: 1000,
      });
    }
    if (
      validator.isEmpty(
        descriptions.find((d) => d.language === LanguageOptions.TR)!.title
      )
    ) {
      return toast({
        status: "error",
        description: "Title Turkish is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.EN)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.EN
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.EN)!.title
                  ),
                  language: LanguageOptions.EN,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.DE)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.DE
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.DE)!.title
                  ),
                  language: LanguageOptions.DE,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.ES)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.ES
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.ES)!.title
                  ),
                  language: LanguageOptions.ES,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.FR)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.FR
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.FR)!.title
                  ),
                  language: LanguageOptions.FR,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.IT)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.IT
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.IT)!.title
                  ),
                  language: LanguageOptions.IT,
                },
                {
                  title: descriptions.find((d) => d.language === LanguageOptions.TR)!
                    .title,
                  description: descriptions.find(
                    (d) => d.language === LanguageOptions.TR
                  )!.description,
                  slug: createSlug(
                    descriptions.find((d) => d.language === LanguageOptions.TR)!.title
                  ),
                  language: LanguageOptions.TR,
                },
              ],
            },
            photos: { set: photos },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "CREATE",
                  message: `Created Style id: ${id} by ${staff?.name}`,
                },
                tableName: "Style",
              },
            },
          }),
        ])
      }
      if (actionType === "UPDATE") {
        for await (const iterator of descriptions) {
          await updateDescriptionMutation({
            variables: {
              where: { id: iterator.descriptionId },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                slug: { set: createSlug(iterator.title ?? "") },
              },
            },
          });
        }
        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            photos: { set: photos },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "UPDATE",
                  message: `Updated Style id: ${id} by ${staff?.name}`,
                },
                tableName: "Style",
              },
            },
          }),
        ]);
      }
    } catch (error) {}
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Category
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/category`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <UploadDropzone isSingle={false} images={photos} setImages={setPhotos} />
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <Tabs>
                <TabList>
                  <Tab>EN</Tab>
                  <Tab>DE</Tab>
                  <Tab>ES</Tab>
                  <Tab>FR</Tab>
                  <Tab>IT</Tab>
                  <Tab>TR</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.EN)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.EN
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.EN)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.EN
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.DE)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.DE
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.DE)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.DE
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.ES)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.ES
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.ES)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.ES
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.FR)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.FR
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.FR)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.FR
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.IT)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.IT
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.IT)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.IT
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                  <TabPanel>
                    <GridItem colSpan={2}>
                      <FormLabel>Title</FormLabel>
                      <Input
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.TR)!
                            .title
                        }
                        onChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.TR
                          )!.title = e.target.value;
                          setDescriptions(newDescriptions);
                        }}
                        placeholder="Title"
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormLabel>Description</FormLabel>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                        value={
                          descriptions.find((d) => d.language === LanguageOptions.TR)!
                            .description ?? ""
                        }
                        outputFormat="html"
                        onEditorChange={(e) => {
                          const newDescriptions = [...descriptions];
                          newDescriptions.find(
                            (d) => d.language === LanguageOptions.TR
                          )!.description = e;
                          setDescriptions(newDescriptions);
                        }}
                        init={{
                          skin: "snow",
                          icons: "thin",
                          placeholder: "Description...",
                          height: 300,
                          menubar: false,
                          min_height: 200,
                          max_height: 500,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                            "removeformat | code",
                        }}
                      />
                    </GridItem>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme="green"
            type="submit"
            mt={6}
            isDisabled={updateDescriptionLoading}
            isLoading={updateDescriptionLoading}
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
