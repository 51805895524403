import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Text,
  chakra,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import validator from "validator";

import {
  CreateStaffMutationVariables,
  FindUniqueStaffQuery,
  StaffRoleType,
  UpdateStaffMutationVariables,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { $enum } from "ts-enum-util";
import { useStaffStore } from "../../store/staff";

interface Props {
  handleCreateUser?: (data: CreateStaffMutationVariables) => Promise<any>;
  handleUpdateUser?: (data: UpdateStaffMutationVariables) => Promise<any>;
  id?: string;
  data?: FindUniqueStaffQuery["findUniqueStaff"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreateUser, handleUpdateUser, id, data, actionType }) => {
  const toast = useToast();
  const [name, setName] = useState(data?.name || "");
  const [surname, setSurname] = useState(data?.surname || "");
  const [email, setEmail] = useState(data?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber || "");
  const [role, setRole] = useState<StaffRoleType>(data?.role ?? StaffRoleType.ADMIN);
  const [staff] = useStaffStore((store) => [store.staff]);

  const [password, setPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!name) {
      return toast({
        status: "error",
        description: "Name is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!surname) {
      return toast({
        status: "error",
        description: "Surname is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!phoneNumber) {
      return toast({
        status: "error",
        description: "Phone number is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!password && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Password is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (password.length < 6 && password) {
      return toast({
        status: "error",
        description: "Password must be greater then 6 characters",
        title: "Error",
        duration: 1000,
      });
    }

    if (!email) {
      return toast({
        status: "error",
        description: "Email is required",
        title: "Error",
        duration: 1000,
      });
    }

    if (!validator.isEmail(email)) {
      return toast({
        status: "error",
        description: "Enter a valid email address",
        title: "Error",
        duration: 1000,
      });
    }

    if (actionType === "CREATE") {
      await handleCreateUser!({
        data: {
          name,
          surname,
          email,
          phoneNumber,
          password,
          role,
        },
      });
    }
    if (actionType === "UPDATE") {
      await handleUpdateUser!({
        where: {
          id: id,
        },
        data: {
          name: {
            set: name,
          },
          surname: {
            set: surname,
          },
          email: {
            set: email,
          },
          phoneNumber: {
            set: phoneNumber,
          },
          role: {
            set: role,
          },
          password: password ? { set: password } : undefined,
        },
      });
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Staff
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/staff" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <chakra.form
        onSubmit={handleSubmit}
        display="flex"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        flexWrap="wrap"
      >
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Name</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
            </GridItem>
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Surname</FormLabel>
              <Input value={surname} onChange={(e) => setSurname(e.target.value)} placeholder="Surname" />
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>E-Mail</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-Mail" type={"email"} />
            </GridItem>
            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Phone</FormLabel>
              <PhoneInput
                country="tr"
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                enableSearch={true}
                inputStyle={{ width: "100%" }}
              />
            </GridItem>
            {staff?.role === StaffRoleType.SUPERADMIN && (
              <GridItem colSpan={2}>
                <FormLabel>Role</FormLabel>
                <SimpleGrid columns={3} gap={6}>
                  {$enum(StaffRoleType).map((r) => (
                    <Button
                      width={"full"}
                      onClick={() => {
                        
                        setRole(r);
                      }}
                      colorScheme={r === role ? "teal" : "gray"}
                      disabled={staff?.role === StaffRoleType.SUPERADMIN && data?.id === staff?.id}
                      _disabled={{
                        opacity: 0.5,
                      
                      }}
                    >
                      {r}
                    </Button>
                  ))}
                </SimpleGrid>
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <FormLabel>Password</FormLabel>
              <Input
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              {actionType === "UPDATE" && (
                <Text fontSize={"xs"} mt={2}>
                  Leave blank if you do not want to change the password
                </Text>
              )}
            </GridItem>
          </SimpleGrid>
        </Box>

        <Box width="100%">
          <Button
            colorScheme={"green"}
            type="submit"
            mt={6}
          >
            Save
          </Button>
        </Box>
      </chakra.form>
    </PageLayout>
  );
};

export default Form;
