import { CreatableType } from "../generated/graphql";

function renderCreatableTypeName(type: CreatableType): string {
  switch (type) {
    case CreatableType.LABOR:
      return "Labor";
    case CreatableType.MATERIAL:
      return "Material";
    default:
      return "";
  }
}

export default renderCreatableTypeName;
