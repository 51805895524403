import {
  StaffRoleType,
  UpdateStaffMutationVariables,
  useCreateOneStaffLogMutation,
  useFindUniqueStaffQuery,
  useUpdateStaffMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";
import { useStaffStore } from "../../store/staff";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateStaff] = useUpdateStaffMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const [staff] = useStaffStore((store) => [store.staff]);

  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const { loading, error, data } = useFindUniqueStaffQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/staff"} replace />;
  }

  if (!(staff?.role === StaffRoleType.ADMIN || staff?.role === StaffRoleType.SUPERADMIN)) {
    return <Navigate to={"/staff"} replace />;
  }

  if (
    (staff?.role === StaffRoleType.ADMIN && data?.findUniqueStaff?.role === StaffRoleType.SUPERADMIN) ||
    (staff?.role !== StaffRoleType.SUPERADMIN && data?.findUniqueStaff?.id !== staff?.id)
  ) {
    return <Navigate to={"/staff"} replace />;
  }

  const handleUpdateUser = async (data: UpdateStaffMutationVariables) => {
   /** const isUserSelf = staff?.id === data.where.id;
    if (!isUserSelf && staff?.role !== StaffRoleType.SUPERADMIN) {
      return toast({
        status: "error",
        title: "Error",
        description: "You are not authorized to update staff",
        duration: 1000,
      });
    } */
    try {
      await updateStaff({
        variables: {
          ...data,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Staff ${data.data.name?.set} by ${staff?.name}`,
              },
              tableName: "Staff",
            },
          },
        }),
      ]);

      return toast({
        status: "success",
        title: "Successful",
        description: "Official updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/staff");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return <Form actionType="UPDATE" data={data?.findUniqueStaff} id={id} handleUpdateUser={handleUpdateUser} />;
};

export default Update;
