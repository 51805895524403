import { Flex, Box, IconButton, Tooltip, useToast, Table, Thead, Tbody, Tr, Td, Th, Input } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FcEditImage, FcPlus, FcRefresh } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import {
  GetRingStoneCaratsQuery,
  SortOrder,
  useCreateOneRingStoneCaratInfoMutation,
  useCreateOneStaffLogMutation,
  useDeleteOneRingStoneCaratMutation,
  useGetRingStoneCaratsQuery,
  useUpdateOneRingStoneCaratInfoMutation,
  useUpdateOneRingStoneCaratMutation,
} from "../../generated/graphql";
import DeleteItem from "../../components/delete/DeleteItem";
import { useStaffStore } from "../../store/staff";
import { RiFileExcel2Line } from "react-icons/ri";
import * as xlsx from "xlsx";

type Props = {};

type ImportData = {
  CARAT: string;
  COLOR: string;
  I1: string;
  I2: string;
  I3: string;
  IF: string;
  SI1: string;
  SI2: string;
  SI3: string;
  VS1: string;
  VS2: string;
  VSS1: string;
  VSS2: string;
};

const All: FC<Props> = () => {
  //   const [searchWord, setSearchWord] = useState<string>("");
  //   const [debouncedSearchWord] = useDebounce(searchWord, 1000);
  const { data, loading, error, refetch } = useGetRingStoneCaratsQuery({
    fetchPolicy: "network-only",
    variables: {
      orderBy: {
        carat: SortOrder.desc,
      },
    },
  });
  const [deleteCarat, { loading: loadingDeleteCarat }] = useDeleteOneRingStoneCaratMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const [updateCaratInfo] = useUpdateOneRingStoneCaratInfoMutation();
  const [createCaratInfo] = useCreateOneRingStoneCaratInfoMutation();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);

  const exportData = (data: GetRingStoneCaratsQuery["ringStoneCarats"]) => {
    if (!data) return;
    const mappedData = data
      .map((item) => {
        return item.caratInfos
          .map((info) => ({
            CARAT: item.carat,
            COLOR: info.color,
            IF: info.IF,
            VSS1: info.VSS1,
            VSS2: info.VSS2,
            VS1: info.VS1,
            VS2: info.VS2,
            SI1: info.SI1,
            SI2: info.SI2,
            SI3: info.SI3,
            I1: info.I1,
            I2: info.I2,
            I3: info.I3,
          }))
          .flatMap((a) => a);
      })
      .flatMap((a) => a);

    const ws = xlsx.utils.json_to_sheet(mappedData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFileXLSX(wb, "carats.xlsx");
    createLog({
      variables: {
        data: {
          staff: {
            connect: {
              id: staff?.id as string,
            },
          },
          json: {
            action: "EXPORT",
            message: `Exported Carats by ${staff?.name}`,
          },
          tableName: "RingStoneCarat",
        },
      },
    });
  };

  const importExcelData = useCallback(() => {
    if (!data || !excelFile) {
      return;
    }

    const handleSuccess = async () => {
      toast({
        status: "success",
        title: "Success",
        description: "Carat info imported successfully!",
        duration: 1000,
      });
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "IMPORT",
                message: `Imported Carats by ${staff?.name}`,
              },
              tableName: "RingStoneCarat",
            },
          },
        }),
      ]);
      await refetch();
    };

    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(excelFile);

      reader.onload = (e) => {
        toast({
          status: "info",
          title: "Info",
          description: "Importing excel data",
          duration: 1000,
        });
        const resultData = e.target?.result;
        const workbook = xlsx.read(resultData, { type: "buffer" });
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        const jsonData: Array<ImportData> = xlsx.utils.sheet_to_json(ws);
        // const values = data.ringStoneCarats.map((item) => item.carat);
        const filteredJsonData = jsonData;

        filteredJsonData.map(async (json) => {
          const updateOrCreate = async (carat: string) => {
            const findedCarat = data?.ringStoneCarats.find((item) => item.carat === carat);

            const isFinded = findedCarat?.caratInfos.find((carat) => {
              return carat.color === json.COLOR;
            });
            if (isFinded) {
              if (
                isFinded.I1 == json.I1 &&
                isFinded.I2 == json.I2 &&
                isFinded.I3 == json.I3 &&
                isFinded.IF == json.IF &&
                isFinded.SI1 == json.SI1 &&
                isFinded.SI2 == json.SI2 &&
                isFinded.SI3 == json.SI3 &&
                isFinded.VS1 == json.VS1 &&
                isFinded.VS2 == json.VS2 &&
                isFinded.VSS1 == json.VSS1 &&
                isFinded.VSS2 == json.VSS2
              ) {
                return;
              }
              await updateCaratInfo({
                variables: {
                  where: {
                    id: isFinded.id,
                  },
                  data: {
                    color: {
                      set: json.COLOR,
                    },
                    IF: {
                      set: json.IF,
                    },
                    VSS1: {
                      set: json.VSS1,
                    },
                    VSS2: {
                      set: json.VSS2,
                    },
                    VS1: {
                      set: json.VS1,
                    },
                    VS2: {
                      set: json.VS2,
                    },
                    SI1: {
                      set: json.SI1,
                    },
                    SI2: {
                      set: json.SI2,
                    },
                    SI3: {
                      set: json.SI3,
                    },
                    I1: {
                      set: json.I1,
                    },
                    I2: {
                      set: json.I2,
                    },
                    I3: {
                      set: json.I3,
                    },
                    ringStoneCarat: {
                      connect: {
                        id: findedCarat?.id as string,
                      },
                    },
                  },
                },
              });
            } else {
              await createCaratInfo({
                variables: {
                  data: {
                    color: json.COLOR,
                    IF: json.IF,
                    VSS1: json.VSS1,
                    VSS2: json.VSS2,
                    VS1: json.VS1,
                    VS2: json.VS2,
                    SI1: json.SI1,
                    SI2: json.SI2,
                    SI3: json.SI3,
                    I1: json.I1,
                    I2: json.I2,
                    I3: json.I3,
                    ringStoneCarat: {
                      connectOrCreate: {
                        where: {
                          carat: carat,
                        },
                        create: {
                          carat: carat,
                        },
                      },
                    },
                  },
                },
              });
            }
          };

          const hasRange = json.CARAT.split("~");
          if (hasRange.length > 1) {
            const [start, end] = hasRange;
            const startNumber = parseFloat(start);
            const endNumber = parseFloat(end);

            for (let i = startNumber; i <= endNumber; i = parseFloat((i + 0.01).toFixed(2))) {
              await updateOrCreate(i.toFixed(2));
            }
          } else {
            await updateOrCreate(json.CARAT);
          }
        });

        handleSuccess();

        // createManyCaratInfo({
        //   variables: {
        //     data: mappedJson.map((item) => ({
        //       color: item.color as string,
        //       IF: item.IF as string,
        //       VSS1: item.VSS1 as string,
        //       VSS2: item.VSS2 as string,
        //       VS1: item.VS1 as string,
        //       VS2: item.VS2 as string,
        //       SI1: item.SI1 as string,
        //       SI2: item.SI2 as string,
        //       SI3: item.SI3 as string,
        //       I1: item.I1 as string,
        //       I2: item.I2 as string,
        //       I3: item.I3 as string,
        //     })),
        //   },
        // });

        handleSuccess();
      };

      reader.onerror = () => {
        toast({
          status: "error",
          title: "Error",
          description: "Failed to read the file",
          duration: 1000,
        });
      };
    } catch (error) {
      toast({
        status: "error",
        title: "Error",
        description: "Something went wrong",
        duration: 1000,
      });
    }
    setExcelFile(null);
  }, [data, excelFile, refetch]);

  useEffect(() => {
    if (excelFile) {
      importExcelData();
    }
  }, [excelFile]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Carats
        </Box>
        <Flex experimental_spaceX={4}>
          <Tooltip label="Refresh Data">
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={async () => {
                refetch();
                await Promise.allSettled([
                  createLog({
                    variables: {
                      data: {
                        staff: {
                          connect: {
                            id: staff?.id as string,
                          },
                        },
                        json: {
                          action: "REFETCH",
                          message: `Refetched Carats by ${staff?.name}`,
                        },
                        tableName: "RingStoneCarat",
                      },
                    },
                  }),
                ]);
              }}
            />
          </Tooltip>
          <Tooltip label="Create New Carat">
            <IconButton icon={<FcPlus />} aria-label="Create New Carat" onClick={() => navigate("/carat/create")} />
          </Tooltip>
          <Tooltip label="Excel Data">
            <IconButton
              aria-label="Excel Data"
              icon={<RiFileExcel2Line />}
              color="purple.400"
              onClick={() => {
                exportData(data?.ringStoneCarats!);
              }}
            />
          </Tooltip>
          <Tooltip label="Import Data">
            <IconButton
              aria-label="Import Data"
              icon={<RiFileExcel2Line />}
              color="green.400"
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th textAlign="center">Value</Th>
            <Th textAlign="center">Size Count</Th>
            <Th textAlign="center">In Products Count</Th>
            <Th textAlign="center">Created At</Th>
            <Th textAlign="center">Updated At</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.ringStoneCarats.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td textAlign="center">{item.carat}</Td>
                <Td textAlign="center">{item._count?.caratInfos}</Td>
                <Td textAlign="center">{item._count?.productRingStone}</Td>
                <Td textAlign="center">{new Date(item.createdAt).toLocaleString()}</Td>
                <Td textAlign="center">{new Date(item.updatedAt).toLocaleString()}</Td>
                <Td textAlign="right">
                  <Flex justifyContent={"end"}>
                    <Box mx={2}></Box>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        onClick={() => {
                          navigate("/carat/update/" + item.id);
                        }}
                      />
                    </Box>

                    <Box mx={2}>
                      <DeleteItem
                        id={item.id}
                        loading={false}
                        title={`value ${item.carat}`}
                        handleDelete={async () => {
                          if (loadingDeleteCarat) return;
                          try {
                            await deleteCarat({
                              variables: {
                                where: {
                                  id: item.id,
                                },
                              },
                            });

                            await createLog({
                              variables: {
                                data: {
                                  staff: {
                                    connect: {
                                      id: staff?.id as string,
                                    },
                                  },
                                  json: {
                                    action: "DELETE",
                                    message: `Deleted Carat ${item.carat} by ${staff?.name}`,
                                  },
                                  tableName: "RingStoneCarat",
                                },
                              },
                            });

                            toast({
                              status: "success",
                              title: "Success",
                              description: "Carat deleted successfully!",
                              duration: 1000,
                            });
                            refetch();
                          } catch (error: any) {
                            toast({
                              status: "error",
                              title: "Error",
                              description: error?.message || "Something went wrong",
                              duration: 1000,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Input
        ref={fileInputRef}
        type="file"
        accept=".xlsx, .xls"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            setExcelFile(file);
          }
        }}
        opacity={0}
        width={0}
        height={0}
        position="absolute"
        zIndex={-1}
      />
    </PageLayout>
  );
};

export default All;
