import create from 'zustand';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_CONFIGURATOR_API_URL || 'https://capi.cr.jwlbg.de/api/configurator';

interface ConfiguratorStore {
    data: any[];
    loading: boolean;
    error: string | null;
    fetchData: () => Promise<void>;
    deleteData: (id: number) => Promise<void>;
    updateData: (newData: any) => Promise<{ success: boolean; message: string }>;
    findDataById: (id: string) => any | null;
}

const useConfiguratorStore: any = create<ConfiguratorStore>((set) => ({
    data: [],
    loading: false,
    error: null,
    fetchData: async () => {
        set({ loading: true, error: null });
        try {
            const response = await axios.get(apiUrl);
            set({ data: response.data, loading: false });
        } catch (error) {
            set({ error: 'An error occurred while loading data.', loading: false });
        }
    },
    deleteData: async (id: number) => {
        set({ loading: true, error: null });
        try {
            await axios.delete(`${apiUrl}/${id}`);
            await set((state) => ({ ...state, data: state.data.filter(item => item.id !== id) })); // Silinen veriyi filtrele
            await useConfiguratorStore.getState().fetchData(); // fetchData fonksiyonunu kullanarak veriyi güncelle
            set({ loading: false });
        } catch (error) {
            set({ error: 'An error occurred during the deletion process.', loading: false });
        }
    },
    updateData: async (newData: any) => {
        set({ loading: true, error: null });
        try {
            await axios.put(apiUrl, newData);
            await useConfiguratorStore.getState().fetchData();
            set({ loading: false });
            return { success: true, message: 'Data updated.' };
        } catch (error) {
            set({ error: 'An error occurred during the update process.', loading: false });
            return { success: false, message: 'Update error.' };
        }
    },
    findDataById: (id: string) => {
        const obj = useConfiguratorStore.getState().data.find((item: any) => item["_id"] == id) || null;
        const status = obj != null ? true : false;
        return { data: obj, status }
    },
}));

export default useConfiguratorStore;
