import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  return <Form actionType="UPDATE" id={id} />;
};

export default Update;
