// @ts-nocheck
import {
  Flex,
  Stack,
  Button,
  Heading,
  Link as ChakraLink,
  Icon,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
} from "@chakra-ui/react";
import { BsBoxSeam, BsSpeedometer } from "react-icons/bs";
import { FiUsers, FiPercent, FiShoppingCart, FiFlag } from "react-icons/fi";
import { VscSymbolClass } from "react-icons/vsc";
import { GiPearlNecklace } from "react-icons/gi";
import { TbReceiptTax } from "react-icons/tb";
import {
  FaUserCog,
  FaParachuteBox,
  FaWrench,
  FaBloggerB,
  FaRing,
  FaDatabase,
  FaPalette,
  FaShapes,
} from "react-icons/fa";
import { RiFilePaper2Line, RiSlideshow3Line } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { Link, Link as ReactRouterLink } from "react-router-dom";

import { useStaffStore } from "../../../store/staff";
import SidebarMenuItem from "./SidebarMenuItem";
import { StaffRoleType, useStaffLogoutMutation } from "../../../generated/graphql";
import { IoDiamond } from "react-icons/io5";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { HiColorSwatch } from "react-icons/hi";
import { CgSize } from "react-icons/cg";

const routes = [
  {
    label: "Customer",
    icon: FiUsers,
    path: "/customer",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Orders",
    icon: FiShoppingCart,
    path: "/orders",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.SALES],
  },
  {
    label: "Categories",
    icon: VscSymbolClass,
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
    path: "/category",
    children: [
      {
        label: "Categories",
        path: "/category",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: VscSymbolClass,
      },
      {
        label: "Sub Categories",
        path: "/subcategory",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: VscSymbolClass,
      },
    ],
  },
  {
    label: "Products",
    icon: BsBoxSeam,
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
    children: [
      {
        label: "Products",
        path: "/product",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: BsBoxSeam,
      },
      {
        label: "Product Updater",
        path: "/productUpdater",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: FaParachuteBox,
      },
      {
        label: "Discount Coupons",
        icon: FiPercent,
        path: "/discountcoupon",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: FiPercent,
      },
      {
        label: "Producible Materials",
        path: "/creatable",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: GiPearlNecklace,
      },
      {
        label: "Product Style",
        path: "/style",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: VscSymbolClass,
      },
      {
        label: "Product Sizes",
        path: "/ring-size-standarts",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: CgSize,
      },
      {
        label: "Colors",
        path: "/ring-colors",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: HiColorSwatch,
      },
      {
        label: "Carats",
        path: "/carat",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: IoDiamond,
      },
      {
        label: "Shapes",
        path: "/shape",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN, StaffRoleType.PRODUCT],
        icon: FaShapes,
      },
      {
        label: "Constants",
        path: "/constant",
        allowedRoles: [StaffRoleType.SUPERADMIN],
        icon: RiMoneyEuroBoxFill,
      },
    ],
  },
  {
    label: "Taxes",
    icon: TbReceiptTax,
    path: "/tax",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Blog",
    icon: FaBloggerB,
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
    children: [
      {
        label: "Blog",
        path: "/blog",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
        icon: FaBloggerB,
      },
      {
        label: "Blog Categories",
        path: "/blog-categories",
        allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
        icon: BiCategory,
      },
    ],
  },
  {
    label: "Countries",
    icon: FiFlag,
    path: "/country",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Configurator",
    icon: FaWrench,
    path: "/configurator",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Staff",
    icon: FaUserCog,
    path: "/staff",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Pages",
    icon: RiFilePaper2Line,
    path: "/page",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Sliders",
    icon: RiSlideshow3Line,
    path: "/slider",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
  {
    label: "Logs",
    icon: FaDatabase,
    path: "/log",
    allowedRoles: [StaffRoleType.ADMIN, StaffRoleType.SUPERADMIN],
  },
];

function Sidebar() {
  const { logoutStaff, staff } = useStaffStore();
  const [logoutMutation, { loading: logoutLoading }] = useStaffLogoutMutation();

  return (
    <Flex
      flexDirection="column"
      bgColor="gray.800"
      width={{
        base: "full",
        lg: "15%",
      }}
      position={{
        base: "static",
        lg: "fixed",
      }}
      transition={"0.3s ease all"}
      height="100%"
      py={4}
      px={2}
      overflowY={"scroll"}
    >
      <Stack direction="column" spacing={2} color="white" alignContent="start" py={2}>
        <Heading px={2} fontSize="lg" mb={6}>
          <Link as={ReactRouterLink} to="/">
            Juwelberg
          </Link>
        </Heading>
        <SidebarMenuItem icon={BsSpeedometer} title="Menu" url="/" />
        {routes.map((route, i) =>
          !route.children ? (
            route.allowedRoles.includes(staff?.role as StaffRoleType) && (
              <ChakraLink
                key={i}
                as={ReactRouterLink}
                display="flex"
                to={route.allowedRoles.includes(staff?.role as StaffRoleType) ? route.path : "/dashboard"}
                direction="row"
                gap="4"
                alignItems="center"
                _hover={{ color: "white", bgColor: "#1b1b28", cursor: "pointer" }}
                widty="100%"
                py={2}
                px={2}
                fontSize="15px"
                fontWeight={"semibold"}
                _hover={{
                  textDecoration: "none",
                }}
                aria-disabled={route.allowedRoles.includes(staff?.role as StaffRoleType) ? false : true}
                _disabled={{
                  color: "gray.500",
                  cursor: "not-allowed",
                }}
              >
                <Icon as={route.icon} w="18px" h="18px" />
                <Text>{route.label}</Text>
              </ChakraLink>
            )
          ) : (
            <Box key={i}>
              {route.allowedRoles.includes(staff?.role as StaffRoleType) && (
                <Accordion allowToggle border="none" px={0} py={0}>
                  <AccordionItem border="none" px={0} py={0}>
                    <AccordionButton
                      border="none"
                      px={0}
                      py={0}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      _hover={{ color: "white", bgColor: "#1b1b28", cursor: "pointer" }}
                    >
                      <Box
                        display="flex"
                        to={route.path}
                        direction="row"
                        gap="4"
                        alignItems="center"
                        py={2}
                        px={2}
                        width="100%"
                        fontSize="15px"
                        fontWeight={"semibold"}
                      >
                        <Icon as={route.icon} w="18px" h="18px" />
                        <Text>{route.label}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel border="none" px={2} py={0.5}>
                      {route.children?.map((child, j) =>
                        child.allowedRoles.includes(staff?.role as StaffRoleType) ? (
                          <ChakraLink
                            key={j}
                            as={ReactRouterLink}
                            display="flex"
                            to={child.allowedRoles.includes(staff?.role as StaffRoleType) ? child.path : "/dashboard"}
                            direction="row"
                            gap="4"
                            alignItems="center"
                            _hover={{ color: "white", bgColor: "#1b1b28", cursor: "pointer" }}
                            py={2}
                            px={2}
                            fontSize="15px"
                            fontWeight={"semibold"}
                            width="100%"
                            _hover={{
                              textDecoration: "none",
                            }}
                            _disabled={{
                              color: "gray.500",
                              cursor: "not-allowed",
                            }}
                            aria-disabled={child.allowedRoles.includes(staff?.role as StaffRoleType) ? false : true}
                          >
                            <Icon as={child.icon} w="18px" h="18px" />
                            <Text>{child.label}</Text>
                          </ChakraLink>
                        ) : null
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </Box>
          )
        )}
      </Stack>

      <Box mt="auto" pt={5}>
        <Button
          width="100%"
          colorScheme="red"
          minHeight={"40px"}
          onClick={async () => {
            await logoutMutation({
              variables: {
                data: {
                  staffId: staff?.id as string,
                },
              },
            });
            logoutStaff();
            window.location.href = "/";
          }}
        >
          Log out
        </Button>
      </Box>
    </Flex>
  );
}

export default Sidebar;
