import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import { FcRefresh, FcPlus, FcEditImage, FcInfo } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import useConfiguratorStore from "../../store/configurator";
import { useStaffStore } from "../../store/staff";
import { useCreateOneStaffLogMutation } from "../../generated/graphql";

interface Props {
  parentId?: string;
}

const All: React.VFC<Props> = ({ parentId }) => {
  const toast = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const { data, loading, error, fetchData, deleteData, updateData, findDataById } = useConfiguratorStore();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const handleDelete = async (id: string) => {
    const item = await data.find((item: any) => item["_id"] === id);
    await deleteData(id);
    await Promise.allSettled([
      createLog({
        variables: {
          data: {
            staff: {
              connect: {
                id: staff?.id as string,
              },
            },
            json: {
              action: "DELETE",
              message: `Deleted Configurator Item ${item?.name} by ${staff?.name}`,
            },
            tableName: "Configurator",
          },
        },
      }),
    ]);

    // if (result.success) {
    //     toast({
    //         status: 'success',
    //         title: 'Successful',
    //         description: result.message,
    //         duration: 3000
    //     });
    // } else {
    //     toast({
    //         status: 'error',
    //         title: 'Error',
    //         description: result.message,
    //         duration: 3000,
    //     });
    // }
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Configurator
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await fetchData();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "REFETCH",
                        message: `Refetched Configurator by ${staff?.name}`,
                      },
                      tableName: "Configurator",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton
            to={`/configurator/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={fetchData}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Title</Th>
            <Th>Value</Th>
            <Th textAlign="center">Type</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((configuratorItem: any, i: number) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td w="10%">{configuratorItem.name}</Td>
                <Td>{configuratorItem.value}</Td>
                <Td textAlign="center" align="center">
                  {configuratorItem.type}
                </Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/configurator/update/${configuratorItem["_id"]}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={configuratorItem["_id"]}
                        title={configuratorItem.name}
                        handleDelete={handleDelete}
                        loading={false}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
