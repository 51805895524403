import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast, Stack, Button } from "@chakra-ui/react";
import {
  CreatableType,
  LanguageOptions,
  SortOrder,
  useCreatablesQuery,
  useCreateOneStaffLogMutation,
  useDeleteOneCreatableMutation,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link, useSearchParams } from "react-router-dom";
import Description from "../../components/description/Description";
import renderCreatableTypeName from "../../utils/renderCreatableTypeName";
import renderCreatableOperationTypeName from "../../utils/renderCreatableOperationTypeName";
import { $enum } from "ts-enum-util";
import { useState } from "react";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const [deleteMutation, { loading: deleteLoading }] = useDeleteOneCreatableMutation();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterTypeParam = searchParams.get("material") as CreatableType;

  const [filterType, setFilterType] = useState<CreatableType>(filterTypeParam ?? CreatableType.LABOR);
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const { loading, error, data, refetch } = useCreatablesQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      where: {
        type: {
          equals: filterType,
        },
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      const finded = data?.creatables.find((c) => c.id === id);
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      await createLog({
        variables: {
          data: {
            staff: {
              connect: {
                id: staff?.id,
              },
            },
            json: {
              action: "DELETE",
              message: `Deleted Creatable Item ${
                finded?.descriptions.find((d) => d.language === LanguageOptions.EN)?.title
              } by ${staff?.name}`,
            },
            tableName: "ProducibleMaterials",
          },
        },
      });

      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Producible Materials
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              refetch();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "GET",
                        message: `Refetched Producible Materials by ${staff?.name}`,
                      },
                      tableName: "ProducibleMaterials",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton
            to={`/creatable/create?material=${filterType}`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Stack direction="row" mb={4}>
        {$enum(CreatableType).map((ct) => (
          <Button
            colorScheme={filterType === ct ? "blue" : "gray"}
            onClick={() => {
              setFilterType(ct);
              setSearchParams({ material: ct });
            }}
          >
            {renderCreatableTypeName(ct)}
          </Button>
        ))}
      </Stack>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Title</Th>
            <Th>Type</Th>
            <Th>Price</Th>
            <Th>Price Impact</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.creatables.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>
                  <Description field="creatable" id={c.id} descriptions={c.descriptions} refetch={refetch} />
                </Td>
                <Td>{renderCreatableTypeName(c.type)}</Td>
                <Td>{c.value}</Td>
                <Td>{renderCreatableOperationTypeName(c.operation)}</Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/creatable/update/${c.id}?material=${filterType}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`${c.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
