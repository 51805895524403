import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./styles/global.css";
import ApolloProvider from "./apollo/ApolloProvider";
import AppRouter from "./router/AppRouter";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme } from "./styles/theme";
import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={chakraTheme}>
          <AppRouter />
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
