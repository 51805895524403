import { FC } from "react";
import PageLayout from "../../components/ui/PageLayout";
import { Box, Flex, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FcList } from "react-icons/fc";
import Form from "./Form";

const Create: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();

  // const handleCreate = async (data: any, previousParam: string) => {
  //   try {
  //     // await createMutation({
  //     //   variables: {
  //     //     data: data.data,
  //     //   },
  //     // });

  //     return toast({
  //       status: "success",
  //       title: "Successful",
  //       description: "Carat created successfully!",
  //       duration: 1000,
  //       onCloseComplete: () => {
  //         return navigate(`/carat`);
  //       },
  //     });
  //   } catch (error: any) {
  //     return toast({
  //       status: "error",
  //       title: "Error",
  //       description: error.message || "Something went wrong",
  //       duration: 1000,
  //     });
  //   }
  // };

  return <Form actionType="CREATE" />;
};

export default Create;
