import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  IconButton,
  useToast,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  chakra,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsPercent, BsDash } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import {
  DiscountType,
  useCreateOneStaffLogMutation,
  useDeleteManyBasketItemMutation,
  useMakeProductDiscountMutation,
} from "../generated/graphql";
import { useStaffStore } from "../store/staff";

type Props = {
  refetch: () => any;
  productId: string;
  discountAmount: number;
  discountType: DiscountType;
  name: string;
};

type Inputs = {
  discountAmount: number;
  discountType: DiscountType;
};

function DiscountModal(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const cancelRef = useRef(null);
  const toast = useToast();

  const [discountMutation, { loading }] = useMakeProductDiscountMutation();
  const [deleteManyBasketItem, { loading: deleteMBILoading }] = useDeleteManyBasketItemMutation();

  const { register, handleSubmit, setValue, watch } = useForm<Inputs>({
    defaultValues: {
      discountType: props.discountType,
      discountAmount: props.discountAmount,
    },
  });
  const onClose = () => {
    setIsOpen(false);
  };

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const onSubmit = async (formData: Inputs) => {
    try {
      await discountMutation({
        variables: {
          productId: props.productId,
          discountType: DiscountType.PERCENTAGE,
          discountAmount: Number(formData.discountAmount),
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "UPDATE",
                message: `Updated Discount Copun Product id: ${props.productId} by ${staff?.name}`,
              },
              tableName: "Product",
            },
          },
        }),
      ]);

      await deleteManyBasketItem({
        variables: {
          where: {
            productId: { equals: props.productId },
          },
        },
      });

      onClose();

      return toast({
        title: "Successful",
        description: "The transaction was completed successfully",
        status: "success",
        duration: 1000,
        isClosable: true,
        onCloseComplete: async () => {
          await props.refetch();
        },
      });
    } catch (err: any) {
      return toast({
        title: "Error",
        description: err.message || "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ButtonGroup isAttached>
        <IconButton aria-label="discount" icon={<BsPercent />} onClick={() => setIsOpen(true)} />
        {Number(props.discountAmount) !== 0 && (
          <Button
            variant="outline"
            onClick={() => {
              setValue("discountAmount", 0);
              handleSubmit(onSubmit)();
            }}
            leftIcon={<MdCancel color="#f42020" />}
          >
            {props.discountAmount}
          </Button>
        )}
      </ButtonGroup>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Apply discount to product "{props.name}"
            </AlertDialogHeader>

            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <AlertDialogBody>
                {/* <FormControl mb={3}>
                  <FormLabel>Tür</FormLabel>
                  <Select {...register("discountType")}>
                    {Object.values(DiscountType).map((type, i) => (
                      <option key={i}>{type}</option>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl>
                  <FormLabel>Amount</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        watch("discountType") === DiscountType.PERCENTAGE ? (
                          <BsPercent color="gray" />
                        ) : (
                          <BsDash color="gray" />
                        )
                      }
                    />
                    <Input type="number" {...register("discountAmount")} placeholder="İndirim miktarı" />
                  </InputGroup>
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="green" type="submit" ml={3} isDisabled={loading || deleteMBILoading}>
                  Save
                </Button>
              </AlertDialogFooter>
            </chakra.form>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default DiscountModal;
