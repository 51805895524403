import { CreatableOperationType } from "../generated/graphql";

function renderCreatableOperationTypeName(type: CreatableOperationType): string {
  switch (type) {
    case CreatableOperationType.ADDITION:
      return "ADDING";
    case CreatableOperationType.MULTIPLICATION:
      return "IMPACT";
    default:
      return "";
  }
}

export default renderCreatableOperationTypeName;
