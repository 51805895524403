import React from "react";
import {
  UpdateCategoryMutationVariables,
  UpdateOneStyleMutationVariables,
  useCategoryQuery,
  useStyleQuery,
  useUpdateCategoryMutation,
  useUpdateOneStyleMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateStyle] = useUpdateOneStyleMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useStyleQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/category"} replace />;
  }

  const handleUpdateStyle = async (data: UpdateOneStyleMutationVariables) => {
    try {
      await updateStyle({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Successful",
        description: "Style updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/style");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      actionType="UPDATE"
      data={data?.style}
      id={id}
      handleUpdate={handleUpdateStyle}
    />
  );
};

export default Update;
