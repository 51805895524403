import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateOneStyleMutationVariables,
  useCreateOneStyleMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createStyle] = useCreateOneStyleMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreate = async (data: CreateOneStyleMutationVariables) => {
    try {
      await createStyle({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "successful",
        description: "Style added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/style");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };
  return <Form handleCreate={handleCreate} actionType="CREATE" />;
};

export default Create;
