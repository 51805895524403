import {
    UpdateOneCreatableMutationVariables,
    useCreatableQuery,
    useRingSizeStandartQuery,
    useUpdateOneCreatableMutation,
  } from "../../generated/graphql";
  import { useToast } from "@chakra-ui/react";
  import Loading from "../../components/loading/Loading";
  import { Navigate, useNavigate } from "react-router-dom";
  import Form from "./Form";
  
  interface Props {
    id: string;
  }
  
  const Update: React.VFC<Props> = ({ id }) => {
    const [updateMutation] = useUpdateOneCreatableMutation();
    const navigation = useNavigate();
    const toast = useToast();
  
    const { loading, error, data } = useRingSizeStandartQuery({
      variables: {
        where: {
          id,
        },
      },
      fetchPolicy: "network-only",
    });
  
    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <Navigate to="/creatable" replace />;
    }
  
    const handleUpdate = async (
      data: UpdateOneCreatableMutationVariables,
      previousParam: string
    ) => {
      try {
        await updateMutation({
          variables: {
            ...data,
          },
        });
  
        return toast({
          status: "success",
          title: "Successful",
          description: "Material updated",
          duration: 1000,
          onCloseComplete: () => {
            return navigation(`/creatable?material=${previousParam}`);
          },
        });
      } catch (error: any) {
        return toast({
          status: "error",
          title: "Error",
          description: error.message || "Something went wrong",
          duration: 1000,
        });
      }
    };
  
    return (
      <Form
        actionType="UPDATE"
        data={data?.ringSizeStandart}
        id={id}
        handleUpdate={handleUpdate}
      />
    );
  };
  
  export default Update;
  