import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Image,
  useToast,
  Select,
  Text,
  Tooltip,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Icon,
  FormHelperText,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  SortOrder,
  QueryMode,
  useDeleteProductMutation,
  useProductsQuery,
  LanguageOptions,
  useCategoriesQuery,
  useStylesQuery,
  useCreatablesQuery,
  CreatableType,
  useRingSizeStandartsQuery,
  InputMaybe,
  ProductWhereInput,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcFilledFilter } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import Pagination from "../../components/pagination/Pagination";
import Description from "../../components/description/Description";
import DiscountModal from "../../components/DiscountModal";
import { IoMdReorder } from "react-icons/io";
import CloudinaryImage from "../../components/CloudinaryImage";
import ProductUpdateStatusBox from "../../components/statusbox/ProductUpdateStatusBox";
import { FiTrash2 } from "react-icons/fi";
import ReactSelect from "react-select";
import { descriptionToText } from "../../utils/descriptionToText";
import { useStaffStore } from "../../store/staff";
import dayjs from "dayjs";

type CustomProductFilters = {
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  status?: SortOrder | null;
  title?: string | null;
  sku?: string | null;
  weight?: SortOrder | null;
  discountAmount?: boolean | null;
  price?: SortOrder | null;
  oldPrice?: SortOrder | null;
  styleIds?: Array<string> | null;
  categoryIds?: Array<string> | null;
  materialIds?: Array<string> | null;
  laborIds?: Array<string> | null;
  colorIds?: Array<string> | null;
  stoneIds?: Array<string> | null;
  ringSizeStandartIds?: Array<string> | null;
};

type OrderByOrganizerProps = {
  exclude?: Array<keyof CustomProductFilters>;
};

const orderByOrganizer = (filters: CustomProductFilters, props?: OrderByOrganizerProps) => {
  const valids = Object.entries(filters).filter(([key, value]) => {
    if (value && key !== "status") {
      if (!props?.exclude?.includes(key as keyof CustomProductFilters)) {
        return {
          [key]: value,
        };
      }
    }
  });
  return valids.map(([key, value]) => ({
    [key]: value,
  }));
};

const someIdsLengthGreaterThanZero = (ids: Array<Array<string> | undefined | null>) => {
  return ids.filter(Boolean).some((id) => Array.isArray(id) && id?.length > 0);
};

const getWhereAndByFilters = (filters: CustomProductFilters) => {
  let andArray: InputMaybe<ProductWhereInput[]> = [];

  if (someIdsLengthGreaterThanZero([filters.materialIds, filters.laborIds, filters.colorIds, filters.stoneIds])) {
    let whereInput: InputMaybe<ProductWhereInput[]> = [];
    if (filters?.materialIds && filters.materialIds.length > 0) {
      whereInput.push({
        creatables: {
          some: {
            creatableId: {
              in: filters.materialIds,
            },
          },
        },
      });
    }
    if (filters?.laborIds && filters.laborIds.length > 0) {
      whereInput.push({
        creatables: {
          some: {
            creatableId: {
              in: filters.laborIds,
            },
          },
        },
      });
    }
    if (filters?.colorIds && filters.colorIds.length > 0) {
      whereInput.push({
        creatables: {
          some: {
            creatableId: {
              in: filters.colorIds,
            },
          },
        },
      });
    }
    if (filters?.stoneIds && filters.stoneIds.length > 0) {
      whereInput.push({
        creatables: {
          some: {
            creatableId: {
              in: filters.stoneIds,
            },
          },
        },
      });
    }

    if (whereInput.length > 0) {
      andArray.push({
        AND: whereInput,
      });
    }
  }

  if (filters.categoryIds && filters.categoryIds.length > 0) {
    andArray.push({
      categoryId: {
        in: filters.categoryIds,
      },
    });
  }

  if (filters.styleIds && filters.styleIds.length > 0) {
    andArray.push({
      styleId: {
        in: filters.styleIds,
      },
    });
  }

  if (filters.ringSizeStandartIds && filters.ringSizeStandartIds.length > 0) {
    andArray.push({
      productRingSizeStandarts: {
        some: {
          ringSizeStandartId: {
            in: filters.ringSizeStandartIds,
          },
        },
      },
    });
  }

  if (Boolean(filters.discountAmount)) {
    andArray.push({
      discountAmount: {
        gt: "0.0",
      },
    });
  }

  return andArray;
};

const defaultFilters: CustomProductFilters = {
  createdAt: undefined,
  updatedAt: SortOrder.desc,
  status: SortOrder.desc,
  title: undefined,
  sku: undefined,
  styleIds: undefined,
  categoryIds: undefined,
  materialIds: undefined,
  laborIds: undefined,
  stoneIds: undefined,
  colorIds: undefined,
  ringSizeStandartIds: undefined,
  discountAmount: undefined,
  weight: undefined,
  price: undefined,
  oldPrice: undefined,
};

const All = () => {
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onFilterModalOpen,
    onClose: onFilterModalClose,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [deleteMutation, { loading: deleteLoading }] = useDeleteProductMutation();
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const skip = (page - 1) * recordsPerPage;
  const [searchWord, setSearchWord] = useState("");
  const [skuValue, setSkuValue] = useState("");
  const [searchValue] = useDebounce(searchWord, 500);
  const [debouncedSkuValue] = useDebounce(skuValue, 500);
  const [filters, setFilters] = useState<CustomProductFilters>(defaultFilters);
  const { loading, error, data, refetch } = useProductsQuery({
    variables: {
      orderBy: orderByOrganizer(filters, {
        exclude: [
          ...(Object.keys(filters).filter((key) => key.endsWith("Ids")) as Array<keyof CustomProductFilters>),
          "discountAmount",
          "title",
          "sku",
        ],
      }),
      take: recordsPerPage,
      skip,
      where: {
        ...(searchValue && {
          OR: [
            {
              descriptions: {
                some: { title: { contains: filters?.title, mode: QueryMode.insensitive } },
              },
            },
            {
              sku: { contains: filters.sku, mode: QueryMode.insensitive },
            },
          ],
        }),
        status: filters?.status ? { equals: filters.status === "asc" ? false : true } : undefined,

        AND: getWhereAndByFilters(filters) || [],
      },
    },
    fetchPolicy: "network-only",
  });
  const { data: productCategories } = useCategoriesQuery();
  const { data: productStyles } = useStylesQuery();
  const { data: productCreatables } = useCreatablesQuery();
  const { data: ringSizeStandarts } = useRingSizeStandartsQuery();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  const totalPages = data ? Math.ceil(data?.products.count / recordsPerPage) : 0;

  const handleDelete = async (id: string) => {
    try {
      const prod = data?.products.items.find((p) => p.id === id);
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      await refetch();
      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "DELETE",
                message: `Deleted Product(title: ${prod?.descriptions.find((d) => d.language === "EN")?.title}) by ${
                  staff?.name
                }`,
              },
              tableName: "Product",
            },
          },
        }),
      ]);
      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    setFilters({
      ...filters,
      title: searchValue,
      sku: searchValue,
    });
  }, [searchValue]);

  useEffect(() => {
    setFilters({
      ...filters,
      sku: debouncedSkuValue,
    });
  }, [debouncedSkuValue]);

  useEffect(() => {
    (async () => {
      if (!logLoading) {
        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                staff: {
                  connect: {
                    id: staff?.id as string,
                  },
                },
                json: {
                  action: "FILTER",
                  message: `Filtered Products by ${staff?.name}`,
                },
                tableName: "Product",
              },
            },
          }),
        ]);
      }
    })();
  }, [filters]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Products
        </Box>
        <Flex gap={4}>
          <Tooltip label="Filters" aria-label="Filters">
            <IconButton
              variant="solid"
              icon={<FcFilledFilter />}
              aria-label="Toggle Filters"
              onClick={onFilterModalOpen}
              title={"Product Filters"}
            />
          </Tooltip>
          <IconButton
            to="/product/order"
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton icon={<FcRefresh />} aria-label="Refetch Query" onClick={() => refetch()} />
          <IconButton
            to="/product/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>

      <FormControl my={6} width="40%" ml="auto">
        <Input
          type="text"
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="Search..."
          borderColor="#ccc"
        />
        <FormHelperText>
          You can access filters by clicking the filter button on the top right-side of the page.
        </FormHelperText>
      </FormControl>

      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Photo</Th>
            <Th>Title</Th>
            <Th w={"100px"} textAlign={"center"}>
              SKU
            </Th>
            <Th>Barcode</Th>
            <Th>Category</Th>
            <Th>Updated At</Th>
            <Th>Old Price</Th>
            <Th>Price</Th>
            <Th width="50px" textAlign="center">
              Status
            </Th>
            <Th width="150px" textAlign="center">
              Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.products.items.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td w="10%">
                  {c.photos[0] ? (
                    <CloudinaryImage width={80} publicId={c.photos[0]} />
                  ) : (
                    <Image maxW="80px" src={"/noimg.png"} alt="Product" />
                  )}
                </Td>
                <Td>
                  <Description field="product" id={c.id} descriptions={c.descriptions} refetch={refetch} />
                </Td>
                <Td w={"100px"} textAlign={"center"}>
                  {c?.sku}
                </Td>
                <Td>{c?.barcode}</Td>
                <Td>{c.category.descriptions[0]?.title}</Td>
                <Td>{new Date(c.updatedAt).toLocaleString()}</Td>
                <Td>{c?.oldPrice || "-"}</Td>
                <Td>{c.price}</Td>
                <Td w="50px" textAlign="center">
                  <ProductUpdateStatusBox
                    status={c.status}
                    productId={c.id}
                    refetch={refetch}
                    progressProps={{ size: "10px" }}
                  />
                </Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <DiscountModal
                        refetch={refetch}
                        discountAmount={c.discountAmount}
                        discountType={c.discountType}
                        name={c.descriptions.find((l) => l.language === LanguageOptions.EN)!?.title || ""}
                        productId={c.id}
                      />
                    </Box>
                    <Box mx={2}>
                      <IconButton aria-label="Edit" icon={<FcEditImage />} as={Link} to={`/product/update/${c.id}`} />
                    </Box>
                    {/* <Box mx={2}>
                      <IconButton
                        aria-label="Size"
                        icon={<FcAddRow />}
                        as={Link}
                        to={`/product/size/${c.id}`}
                      />
                    </Box> */}
                    <Box mx={2}>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`${c.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        totalPage={totalPages}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
      />

      <Modal size="5xl" isOpen={isFilterModalOpen} onClose={onFilterModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              <Text fontWeight="bold" color="blue.300">
                Sort By:
              </Text>
              <Stack gap={4} px={6} pt={6} pb={8} border="1px solid" borderColor="blue.300" rounded="md">
                <SimpleGrid columns={3} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Status</FormLabel>
                    <Select
                      value={filters.status?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, status: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Actives first</option>
                      <option value="asc">Actives last</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="blue.300">Create Date</FormLabel>
                    <Select
                      value={filters.createdAt?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, createdAt: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Newest first</option>
                      <option value="asc">Oldest first</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel color="blue.300">Update Date</FormLabel>
                    <Select
                      value={filters.updatedAt?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, updatedAt: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Newest first</option>
                      <option value="asc">Oldest first</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={2} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Price</FormLabel>
                    <Select
                      value={filters.price?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, price: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Highest first</option>
                      <option value="asc">Lowest first</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel color="blue.300">Old Price</FormLabel>
                    <Select
                      value={filters.oldPrice?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, oldPrice: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Highest first</option>
                      <option value="asc">Lowest first</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel color="blue.300">Weight</FormLabel>
                    <Select
                      value={filters.weight?.toString() || "undefined"}
                      onChange={(e) => {
                        const parsed = e.target.value === "undefined" ? undefined : (e.target.value as SortOrder);
                        setFilters((s) => ({ ...s, weight: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="desc">Heaviest first</option>
                      <option value="asc">Lightest first</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </Stack>

              <Text fontWeight="bold" color="blue.300">
                Filter By:
              </Text>

              <Stack gap={4} px={6} pt={6} pb={8} border="1px solid" borderColor="blue.300" rounded="md">
                <SimpleGrid columns={2} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Search (Title)</FormLabel>
                    <Input
                      type="text"
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      placeholder="Search..."
                      borderColor="#ccc"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color="blue.300">Sku</FormLabel>
                    <Input
                      type="text"
                      value={skuValue}
                      onChange={(e) => setSkuValue(e.target.value)}
                      placeholder="sku"
                      borderColor="#ccc"
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={2} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Categories</FormLabel>
                    <ReactSelect
                      options={productCategories?.categories.map((category) => ({
                        label: descriptionToText(category.descriptions!, "EN")?.title!,
                        value: category.id,
                      }))}
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, categoryIds: e.map((c) => c.value) }));
                      }}
                      value={filters.categoryIds?.map((c) => ({
                        label: descriptionToText(
                          productCategories?.categories.find((cc) => cc.id === c)?.descriptions!,
                          "EN"
                        )?.title!,
                        value: c,
                      }))}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="blue.300">Styles</FormLabel>
                    <ReactSelect
                      options={
                        productStyles?.styles.map((style) => ({
                          label: style.descriptions[0]?.title,
                          value: style.id,
                        })) || []
                      }
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, styleIds: e.map((c) => c.value) }));
                      }}
                      value={
                        filters.styleIds?.map((c) => ({
                          label: productStyles?.styles.find((cc) => cc.id === c)?.descriptions[0]?.title,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={3} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Materials</FormLabel>
                    <ReactSelect
                      options={
                        productCreatables?.creatables
                          .filter((c) => c.type === CreatableType.MATERIAL)
                          .map((material) => ({
                            label: material.descriptions[0]?.title,
                            value: material.id,
                          })) || []
                      }
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, materialIds: e.map((m) => m.value) }));
                      }}
                      value={
                        filters.materialIds?.map((c) => ({
                          label: descriptionToText(
                            productCreatables?.creatables.find((cc) => cc.id === c)?.descriptions!,
                            "EN"
                          )?.title!,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="blue.300">Labors</FormLabel>
                    <ReactSelect
                      options={
                        productCreatables?.creatables
                          .filter((c) => c.type === CreatableType.LABOR)
                          .map((material) => ({
                            label: descriptionToText(material.descriptions, "EN")?.title!,
                            value: material.id,
                          })) || []
                      }
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, laborIds: e.map((m) => m.value) }));
                      }}
                      value={
                        filters.laborIds?.map((c) => ({
                          label: descriptionToText(
                            productCreatables?.creatables.find((cc) => cc.id === c)?.descriptions!,
                            "EN"
                          )?.title!,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl>

                  {/* <FormControl>
                    <FormLabel color="blue.300">Colors</FormLabel>
                    <ReactSelect
                      options={
                        productCreatables?.creatables
                          .filter((c) => c.type === CreatableType.COLOR)
                          .map((material) => ({
                            label: descriptionToText(material.descriptions, "EN")?.title!,
                            value: material.id,
                          })) || []
                      }
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, colorIds: e.map((m) => m.value) }));
                      }}
                      value={
                        filters.colorIds?.map((c) => ({
                          label: descriptionToText(
                            productCreatables?.creatables.find((cc) => cc.id === c)?.descriptions!,
                            "EN"
                          )?.title!,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl> */}
                </SimpleGrid>

                <SimpleGrid columns={2} gap={4}>
                  {/* <FormControl>
                    <FormLabel color="blue.300">Stones</FormLabel>
                    <ReactSelect
                      options={
                        productCreatables?.creatables
                          .filter((c) => c.type === CreatableType.ADDITIONAL_STONE)
                          .map((material) => ({
                            label: material.descriptions[0].title,
                            value: material.id,
                          })) || []
                      }
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, stoneIds: e.map((m) => m.value) }));
                      }}
                      value={
                        filters.stoneIds?.map((c) => ({
                          label: descriptionToText(
                            productCreatables?.creatables.find((cc) => cc.id === c)?.descriptions!,
                            "EN"
                          )?.title!,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl> */}

                  <FormControl>
                    <FormLabel color="blue.300">Ring Size Standarts</FormLabel>
                    <ReactSelect
                      options={ringSizeStandarts?.ringSizeStandarts.map((standart) => ({
                        label: standart.name,
                        value: standart.id,
                      }))}
                      isMulti
                      onChange={(e) => {
                        setFilters((s) => ({ ...s, ringSizeStandartIds: e.map((m) => m.value) }));
                      }}
                      value={
                        filters.ringSizeStandartIds?.map((c) => ({
                          label: ringSizeStandarts?.ringSizeStandarts.find((cc) => cc.id === c)?.name,
                          value: c,
                        })) || []
                      }
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={1} gap={4}>
                  <FormControl>
                    <FormLabel color="blue.300">Has Product Discount</FormLabel>
                    <Select
                      value={filters.discountAmount ? "yes" : "no" || "undefined"}
                      onChange={(e) => {
                        const parsed =
                          e.target.value === "undefined" ? undefined : e.target.value === "yes" ? true : false;
                        setFilters((s) => ({ ...s, discountAmount: parsed }));
                      }}
                      borderColor="#ccc"
                    >
                      <option value={"undefined"}>-</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter mt={4} justifyContent="flex-end">
            {/* <Button
              colorScheme="blue"
              mr={3}
              display="flex"
              alignItems="center"
              gap={3}
              color="#fff"
              onClick={() => refetch()}
            >
              <Text>Apply Filters</Text>
            </Button> */}
            <Button
              colorScheme="red"
              mr={3}
              display="flex"
              alignItems="center"
              gap={3}
              color="#fff"
              onClick={() => {
                setFilters(defaultFilters);
              }}
            >
              <Text>Reset Filters</Text>
              <Icon as={FiTrash2} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
};

export default All;
