import React from "react";
import {
  GridItem,
  Input,
  FormLabel,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Divider,
  Box,
  Heading,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { LanguageOptions } from "../../generated/graphql";
import { DescriptionProperty } from "../../types/descriptionProperty";
import { Editor } from "@tinymce/tinymce-react";

const editorSettings = {
  skin: "snow",
  icons: "thin",
  placeholder: "Description...",
  height: 300,
  menubar: false,
  min_height: 200,
  max_height: 500,

  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code wordcount",
  ],
  toolbar:
    "undo redo | formatselect | " +
    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
    "removeformat | code",
};

const DescriptionForms = ({
  descriptions,
  setDescriptions,
}: {
  descriptions: DescriptionProperty[];
  setDescriptions: React.Dispatch<React.SetStateAction<DescriptionProperty[]>>;
}) => {
  return (
    <Box>
      <Tabs>
        <TabList>
          {Object.values(LanguageOptions).map((lang, i) => (
            <Tab key={i}>
              <Text>
                {lang}
              </Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.values(LanguageOptions).map((lang, i) => (
            <TabPanel key={i}>
              <GridItem colSpan={5}>
                <FormLabel>Title</FormLabel>
                <Input
                  value={descriptions.find((d) => d.language === lang)?.title || ""}
                  onChange={(e) => {
                    const newDescriptions = [...descriptions];
                    newDescriptions.find((d) => d.language === lang)!.title = e.target.value;

                    setDescriptions(newDescriptions);
                  }}
                  placeholder="Title"
                />
              </GridItem>
              <GridItem my={4} colSpan={5}>
                <FormLabel>Description</FormLabel>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                  value={descriptions.find((description) => description.language === lang)?.description || ""}
                  onEditorChange={(e) => {
                    const newDescriptions = [...descriptions];
                    newDescriptions.find((d) => d.language === lang)!.description = e;
                    setDescriptions(newDescriptions);
                  }}
                  outputFormat="html"
                  init={editorSettings}
                />
              </GridItem>
              <GridItem my={4} colSpan={5}>
                <Divider />
              </GridItem>

              <Divider my={8} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DescriptionForms;
