import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import {
  Description,
  useCreateOneStaffLogMutation,
  useDeleteOneRingColorMutation,
  useDeleteOneRingSizeStandartMutation,
  useRingColorsQuery,
  useRingSizeStandartsQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import DescriptionComponent from "../../components/description/Description";

const All: React.VFC = () => {
  const [deleteRingColor, { loading: loadingDeleteRingColor }] = useDeleteOneRingColorMutation();

  const navigate = useNavigate();
  const toast = useToast();
  const { data: ringColors, refetch: refetchRingColors, loading, error } = useRingColorsQuery({
    fetchPolicy: "network-only",
  });

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Ring Colors
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetchRingColors();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "REFETCH",
                        message: `Refetched Ring Colors by ${staff?.name}`,
                      },
                      tableName: "RingColors",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton
            to={`/ring-colors/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetchRingColors()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th textAlign="center">Name</Th>
            <Th textAlign="center">Price</Th>
            <Th textAlign="center">Created At</Th>
            <Th textAlign="center">Updated At</Th>
            <Th textAlign="right">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ringColors &&
            ringColors.ringColors.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td textAlign="center">
                  <DescriptionComponent
                    field="ring-colors"
                    id={item.id}
                    descriptions={item.descriptions as Description[]}
                    refetch={refetchRingColors}
                  />
                </Td>
                {/* <Td textAlign="center">{item._count?.values}</Td> */}
                {/* <Td textAlign="center">{item._count?.productRingSizeStandarts}</Td> */}
                <Td textAlign="center">{item.price}</Td>
                <Td textAlign="center">{new Date(item.createdAt).toLocaleString()}</Td>
                <Td textAlign="center">{new Date(item.updatedAt).toLocaleString()}</Td>
                <Td textAlign="right">
                  <Flex justifyContent={"end"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        onClick={() => {
                          navigate("/ring-colors/update/" + item.id);
                        }}
                      />
                    </Box>

                    <Box mx={2}>
                      <DeleteItem
                        id={item.id}
                        loading={false}
                        title={item.descriptions[0].title}
                        handleDelete={async () => {
                          if (loadingDeleteRingColor) {
                            return;
                          }
                          try {
                            await deleteRingColor({
                              variables: {
                                where: {
                                  id: item.id,
                                },
                              },
                            });

                            await Promise.allSettled([
                              createLog({
                                variables: {
                                  data: {
                                    staff: {
                                      connect: {
                                        id: staff?.id as string,
                                      },
                                    },
                                    json: {
                                      action: "DELETE",
                                      message: `Deleted Ring Color id: ${item.id} by ${staff?.name}`,
                                    },
                                    tableName: "RingColor",
                                  },
                                },
                              }),
                            ]);

                            toast({
                              status: "success",
                              title: "Success",
                              description: "Ring Color Standart deleted",
                              duration: 1000,
                            });
                            refetchRingColors();
                          } catch (error: any) {
                            toast({
                              status: "error",
                              title: "Error",
                              description: error?.message || "Something went wrong",
                              duration: 1000,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
