import { FC } from "react";
import PageLayout from "../../components/ui/PageLayout";
import { Box, Flex, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FcList } from "react-icons/fc";
import Form from "./Form";
import { useRingStoneCaratQuery } from "../../generated/graphql";

type Props = {
  id: string;
};

const Create: FC<Props> = ({ id }) => {
  // const navigate = useNavigate();
  // const toast = useToast();
  const { data, refetch } = useRingStoneCaratQuery({
    variables: {
      where: {
        id: id,
      },
    },
    fetchPolicy: "network-only",
  });

  return <Form actionType="UPDATE" data={data?.ringStoneCarat} refetchUpdatedCarat={refetch} />;
};

export default Create;
