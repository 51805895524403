import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateOneCreatableMutationVariables,
  useCreateOneCreatableMutation,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { useStaffStore } from "../../store/staff";

const Create = () => {
  const [createMutation] = useCreateOneCreatableMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();
  const handleCreate = async (
    data: CreateOneCreatableMutationVariables,
    previousParam: string
  ) => {
    try {
      const returned = await createMutation({
        variables: {
          data: data.data,
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "CREATE",
                message: `Created Material id: ${returned.data?.createOneCreatable.id} by ${staff?.name}`,
              },
              tableName: "Material",
            },
          },
        }),
      ]);


      return toast({
        status: "success",
        title: "Successful",
        description: "Material added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/creatable?material=${previousParam}`);
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Error",
        description: error.message || "Something went wrong",
        duration: 1000,
      });
    }
  };
  return <Form handleCreate={handleCreate} actionType="CREATE" />;
};

export default Create;
