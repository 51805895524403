import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, useToast } from "@chakra-ui/react";
import {
  LanguageOptions,
  SortOrder,
  useCreateOneStaffLogMutation,
  useDeleteDiscountCouponMutation,
  useDiscountCouponsQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import Description from "../../components/description/Description";
import dayjs from "dayjs";
import StatusBox from "../../components/statusbox/StatusBox";
import { useStaffStore } from "../../store/staff";

const All: React.VFC = () => {
  const [deleteMutation, { loading: deleteLoading }] = useDeleteDiscountCouponMutation();
  const toast = useToast();

  const { loading, error, data, refetch } = useDiscountCouponsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    const finded = data?.discountCoupons.find((c) => c.id === id);
    try {
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              staff: {
                connect: {
                  id: staff?.id as string,
                },
              },
              json: {
                action: "DELETE",
                message: `Deleted Discount Coupon ${finded?.descriptions.find((a) => a.language === 'EN')?.title} by ${staff?.name}`,
              },
              tableName: "DiscountCoupon",
            },
          },
        }),
      ]);

      return toast({
        duration: 1500,
        title: "Successful",
        status: "success",
        description: "Deletion completed",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: error.message || "Something went wrong",
      });
    }
  };

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Discount Coupons
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton icon={<FcRefresh />} aria-label="Refetch Query" onClick={() => refetch()} />
          <IconButton
            to="/discountcoupon/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Title</Th>
            <Th>Code</Th>
            <Th>QTY</Th>
            <Th>Validity Date</Th>
            <Th>Status</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.discountCoupons.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>
                  <Description field="discountCoupon" id={c.id} descriptions={c.descriptions} refetch={refetch} />
                </Td>
                <Td>{c.code}</Td>
                <Td>{c.amount}</Td>
                <Td>{dayjs(c.validUntil).format("DD/MM/YYYY")}</Td>
                <Td>
                  <StatusBox status={c.status} />
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/discountcoupon/update/${c.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`${c.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
