import { Fragment, useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  useToast,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import {
  LanguageOptions,
  OrderStatus,
  useOrderQuery,
  useUpdateOrderMutation,
} from "../../generated/graphql";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { MdLocalShipping } from "react-icons/md";
import renderCreatableTypeName from "../../utils/renderCreatableTypeName";

interface Props {
  id: string;
}

interface ShippingNumberInputProps {
  shippingNumberData: string;
  shippingCompanyData: string;
  isShipped: boolean;
  orderId: string;
  refetch(): any;
}

function ShippingNumberInput({
  shippingNumberData,
  shippingCompanyData,
  orderId,
  refetch,
  isShipped,
}: ShippingNumberInputProps) {
  const [updateOrder, { loading: updateLoading }] = useUpdateOrderMutation();
  const toast = useToast();
  const [shippingNumber, setShippingNumber] = useState(shippingNumberData || "");
  const [shippingCompany, setShippingCompany] = useState(shippingCompanyData || "");
  const handleSendShippingNumber = async () => {
    if (!shippingNumber) {
      return toast({
        title: "Error",
        description: "Do not leave the tracking number blank.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (!shippingCompany) {
      return toast({
        title: "Error",
        description: "Do not leave the cargo company blank.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    try {
      await updateOrder({
        variables: {
          where: { id: orderId },
          data: {
            shippingNumber: { set: shippingNumber },
            shippingCompany: { set: shippingCompany },
            isShipped: { set: true },
            orderStatus: { set: OrderStatus.SHIPPED },
          },
        },
      });

      return toast({
        title: "Successful",
        description: "Saved",
        status: "success",
        duration: 750,
        isClosable: true,
        onCloseComplete: async () => {
          await refetch();
        },
      });
    } catch (error: any) {
      return toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleMarkAsDelivered = async () => {
    try {
      await updateOrder({
        variables: {
          where: { id: orderId },
          data: {
            orderStatus: { set: OrderStatus.COMPLETED },
          },
        },
      });

      return toast({
        title: "Successful",
        description: "Saved",
        status: "success",
        duration: 750,
        isClosable: true,
        onCloseComplete: async () => {
          await refetch();
        },
      });
    } catch (error: any) {
      return toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Input
        pr="12.5rem"
        type="text"
        placeholder="Shipping Company"
        mb={2}
        value={shippingCompany}
        onChange={(e) => setShippingCompany(e.target.value)}
      />
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<MdLocalShipping />} />
        <Input
          pr="12.5rem"
          type="text"
          placeholder="Tracking Number"
          value={shippingNumber}
          onChange={(e) => setShippingNumber(e.target.value)}
        />
        <InputRightElement width="12rem">
          <Button
            h="1.75rem"
            size="sm"
            colorScheme="green"
            mr="1rem"
            onClick={handleSendShippingNumber}
            isDisabled={updateLoading || (shippingNumberData ? true : false)}
          >
            Save
          </Button>
          {isShipped && (
            <Button
              h="1.75rem"
              size="sm"
              colorScheme="green"
              isDisabled={!isShipped}
              onClick={handleMarkAsDelivered}
            >
              Mark as delivered
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
    </>
  );
}

const Detail: React.VFC<Props> = ({ id }) => {
  const { loading, error, data, refetch } = useOrderQuery({
    variables: {
      where: {
        id,
      },
    },
    // fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Fragment>
      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Order Detail
          </Box>
        </Flex>
        <SimpleGrid columns={2} gap={6}>
          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Order Date" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${dayjs(data?.order!.createdAt).format("DD MMM YYYY HH:mm")}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Customer" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.customer.name} ${data?.order?.customer.surname}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="E-Mail" />
              <Input fontSize={"sm"} disabled value={`${data?.order?.customer.email}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Phone" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.customer.phoneNumber}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Order Status" />
              <Input fontSize={"sm"} disabled value={`${data?.order?.orderStatus}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Coupon Code" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.discountCoupon ? "Evet" : "Hayır"}`}
              />
            </InputGroup>
          </GridItem>
        </SimpleGrid>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Products
          </Box>
        </Flex>

        <Box overflow="auto">
          <Table>
            <Thead>
              <Tr>
                <Th>Product</Th>
                <Th>Variant</Th>
                <Th>Price</Th>
                <Th>QTY</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.order?.products.map((order) => {
                return (
                  <Tr key={order.id}>
                    <Td>{order.product.descriptions[0]?.title}</Td>
                    <Td>
                      {order.creatables.map((c: any) => (
                        <li>{`${renderCreatableTypeName(c.creatable.type)}: ${
                          c.creatable.descriptions[0]?.title
                        }`}</li>
                      ))}
                      <li>
                        Has Ring Color: {order.ringColor ? JSON.parse(order.ringColor).descriptions.find((d: any) => d.language === LanguageOptions.EN).title + `${JSON.parse(order.ringColor).price} EUR` : ''}
                      </li>
                      <li>
                        Has Ring Purity: {order?.ringPurity ? JSON.parse(order?.ringPurity).value + ` ${JSON.parse(order.ringPurity).price}` : ''}
                      </li>
                      <li>
                        Has Ring Size: {order?.ringSize || ''}
                      </li>
                      <li>
                        Has Ring Stone Group: {order?.stoneGroup || ''}
                      </li>
                      <li>
                        Has Ring Text: {order?.ringText || ''}
                      </li>
                    </Td>
                    <Td>{order.price}</Td>
                    <Td>{order.quantity}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Shipping & Billing
          </Box>
        </Flex>

        <SimpleGrid columns={2} spacing={8}>
          <Box>
            <Heading size="sm" mb={2}>
              Billing Information
            </Heading>
            <Box overflow="auto">
              <Table variant="unstyled">
                <Tbody>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Name Surname
                    </Th>
                    <Td>{`${data?.order?.paymentFirstName} ${data?.order?.paymentLastName}`}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      E-Mail
                    </Th>
                    <Td>{data?.order?.paymentEmail}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Phone
                    </Th>
                    <Td>{data?.order?.paymentPhoneNumber}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Address
                    </Th>
                    <Td>{`${data?.order?.paymentAddress} ${data?.order?.paymentPostalCode} ${data?.order?.paymentCity}/${data?.order?.paymentCountry}`}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>

          <Box>
            <Heading size="sm" mb={2}>
              Shipping Information
            </Heading>
            <Box overflow="auto">
              <Table variant="unstyled">
                <Tbody>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Name Surname
                    </Th>
                    <Td>{`${data?.order?.shippingFirstName} ${data?.order?.shippingLastName}`}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      E-Mail
                    </Th>
                    <Td>{data?.order?.shippingEmail}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Phone
                    </Th>
                    <Td>{data?.order?.shippingPhoneNumber}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Address
                    </Th>
                    <Td>{`${data?.order?.shippingAddress} ${data?.order?.shippingPostalCode} ${data?.order?.shippingCity}/${data?.order?.shippingCountry}`}</Td>
                  </Tr>

                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Cargo
                    </Th>
                    <Td>
                      <ShippingNumberInput
                        orderId={data?.order!.id!}
                        isShipped={data?.order!.isShipped!}
                        refetch={refetch}
                        shippingNumberData={data?.order?.shippingNumber || ""}
                        shippingCompanyData={data?.order?.shippingCompany ?? ""}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </SimpleGrid>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Billing Information
          </Box>
        </Flex>

        <Box overflow="auto">
          <Table variant="unstyled">
            <Tbody>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Date
                </Th>
                <Td>
                  {dayjs(data?.order?.payment?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Status
                </Th>
                <Td>{data?.order?.payment?.status}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Error Code
                </Th>
                <Td>{data?.order?.payment?.errorCode}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Error Message
                </Th>
                <Td>{data?.order?.payment?.errorMessage}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Total Price
                </Th>
                <Td>{data?.order?.payment?.price}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Paid Price
                </Th>
                <Td>{data?.order?.payment?.paidPrice}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Ödeme ID
                </Th>
                <Td>{data?.order?.payment?.id}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Installment
                </Th>
                <Td>{data?.order?.payment?.installment}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </PageLayout>
    </Fragment>
  );
};

export default Detail;
