import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton, Badge, useToast } from "@chakra-ui/react";
import {
  StaffRoleType,
  useCreateOneStaffLogMutation,
  useDeleteOneStaffMutation,
  useFindManyStaffQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";

import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import DeleteItem from "../../components/delete/DeleteItem";

const All = () => {
  const { loading, error, data, refetch } = useFindManyStaffQuery({
    fetchPolicy: "network-only",
  });
  const [deleteOneStaff, { loading: deleteOneStaffLoading }] = useDeleteOneStaffMutation();
  const toast = useToast();

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog, { loading: logLoading }] = useCreateOneStaffLogMutation();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <PageLayout>
      <Flex mb={6} justifyContent={"space-between"} alignItems="center" bgColor={"gray.100"} padding={2} rounded="md">
        <Box fontSize={"18px"} fontWeight="bold">
          Staffs
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={async () => {
              await refetch();
              await Promise.allSettled([
                createLog({
                  variables: {
                    data: {
                      staff: {
                        connect: {
                          id: staff?.id as string,
                        },
                      },
                      json: {
                        action: "REFETCH",
                        message: `Refetched Staffs by ${staff?.name}`,
                      },
                      tableName: "Staff",
                    },
                  },
                }),
              ]);
            }}
          />
          <IconButton
            to={staff?.role !== StaffRoleType.SUPERADMIN ? "/staff" : "/staff/create"}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
            disabled={staff?.role !== StaffRoleType.SUPERADMIN}
            opacity={staff?.role !== StaffRoleType.SUPERADMIN ? 0.5 : 1}
          />
        </Flex>
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Name</Th>
            <Th>E-Mail</Th>
            <Th>Role</Th>
            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.findManyStaff.map((user, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{`${user.name} ${user.surname}`}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Badge colorScheme="red">{user.role}</Badge>
                </Td>

                <Td>
                  <Flex justifyContent="center">
                    {user.id !== staff?.id && (
                      <Box mx={2}>
                        <DeleteItem
                          id={`delete ${staff?.id} ${staff?.name} ${staff?.email} staff`}
                          loading={deleteOneStaffLoading}
                          title={`Do you want to delete the user ${user?.name} ${user?.email}`}
                          handleDelete={async () => {
                            if (deleteOneStaffLoading || staff?.role !== StaffRoleType.SUPERADMIN) return;
                            try {
                              await deleteOneStaff({
                                variables: {
                                  where: {
                                    id: user.id,
                                  },
                                },
                              });
                              toast({
                                status: "success",
                                title: "Success",
                                description: "Staff deleted",
                                duration: 1000,
                              });
                              await Promise.allSettled([
                                createLog({
                                  variables: {
                                    data: {
                                      staff: {
                                        connect: {
                                          id: staff?.id as string,
                                        },
                                      },
                                      json: {
                                        action: "DELETE",
                                        message: `Deleted Staff ${user.name} by ${staff?.name}`,
                                      },
                                      tableName: "Staff",
                                    },
                                  },
                                }),
                              ]);
                            } catch (error: any) {
                              toast({
                                status: "error",
                                title: "Error",
                                description: error?.message || "An error occurred",
                                duration: 1000,
                              });
                            }
                            await refetch();
                          }}
                          disabled={staff?.role !== StaffRoleType.SUPERADMIN || deleteOneStaffLoading}
                        />
                      </Box>
                    )}
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={
                          (staff?.role === StaffRoleType.ADMIN && user.role === StaffRoleType.SUPERADMIN) ||
                          (staff?.role !== StaffRoleType.SUPERADMIN && user.id !== staff?.id)
                            ? "/staff"
                            : `/staff/update/${user.id}`
                        }
                        disabled={
                          (staff?.role === StaffRoleType.ADMIN && user.role === StaffRoleType.SUPERADMIN) ||
                          (staff?.role !== StaffRoleType.SUPERADMIN && user.id !== staff?.id)
                        }
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
