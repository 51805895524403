import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";

interface Props {
  error?: any;
}

const Error: React.VFC<Props> = ({ error }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Error!</AlertTitle>
      <AlertDescription>{error ? error.message : "Something went wrong"}</AlertDescription>
    </Alert>
  );
};

export default Error;
