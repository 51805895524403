import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import NotRequireAuth from "./NotRequireAuth";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Page from "../pages/Page/Page";
import Description from "../pages/Description/Form";
import Customer from "../pages/Customer/Customer";
import Category from "../pages/Category/Category";
import SubCategory from "../pages/SubCategory/SubCategory";
import Product from "../pages/Product/Product";
import DiscountCoupon from "../pages/DiscountCoupon/DiscountCoupon";
import Order from "../pages/Order/Order";
import Staff from "../pages/Staff/Staff";
import CreatableMaterial from "../pages/CreatableMaterial/CreatableMaterial";
import Tax from "../pages/Tax/Tax";
import Country from "../pages/Country/Country";
import Slider from "../pages/Slider/Slider";
import ProductUpdater from "../pages/Product/ProductUpdater";
import Configurator from "../pages/Configurator/Configurator";
import ProductStyle from "../pages/ProductStyle/ProductStyle";
import BlogPages from "../pages/Blog/Blog";
import BlogCategoriesPage from "../pages/BlogCategory/BlogCategory";
import RingSizeStandart from "../pages/RingSizeStandart/RingSizeStandart";
import LogPage from "../pages/Log/Log";
import CaratPage from "../pages/Carat/Carat";
import ConstantsPage from "../pages/Constants/Constants";
import RingColorsPage from '../pages/RingColors/RingColors';
import ShapesPage from '../pages/Shape/Shape';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotRequireAuth />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/customer/*" element={<Customer />} />
          <Route path="/staff/*" element={<Staff />} />
          <Route path="/category/*" element={<Category />} />
          <Route path="/subcategory/*" element={<SubCategory />} />
          <Route path="/creatable/*" element={<CreatableMaterial />} />
          <Route path="/ring-size-standarts/*" element={<RingSizeStandart />} />
          <Route path="/product/*" element={<Product />} />
          <Route path="/page/*" element={<Page />} />
          <Route path="/discountcoupon/*" element={<DiscountCoupon />} />
          <Route path="/tax/*" element={<Tax />} />
          <Route path="/slider/*" element={<Slider />} />
          <Route path="/country/*" element={<Country />} />
          <Route path="/orders/*" element={<Order />} />
          <Route path="/style/*" element={<ProductStyle />} />
          <Route path="/configurator/*" element={<Configurator />} />
          <Route path="/description/:action/:language/:field/:fieldId/*" element={<Description />} />
          <Route path="/productUpdater" element={<ProductUpdater />} />
          <Route path="/blog/*" element={<BlogPages />} />
          <Route path="/blog-categories/*" element={<BlogCategoriesPage />} />
          <Route path="/log/*" element={<LogPage />} />
          <Route path="/carat/*" element={<CaratPage />} />
          <Route path="/constant/*" element={<ConstantsPage />} />
          <Route path="/ring-colors/*" element={<RingColorsPage />} />
          <Route path="/shape/*" element={<ShapesPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
